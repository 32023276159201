@import 'src/style/variables';

.ImportsFileWizardOption {
  flex: 1;
  display: flex;
  background: $background-color-secondary;
  border-radius: $border-radius-large;
  margin-left: $spacing-large;
  min-height: $dropzone-height;
  height: 100%;

  &:first-child {
    margin-left: 0;
  }

  &__content {
    flex: 1;
    padding: ($spacing-page-vertical + $spacing-base) $spacing-page-vertical;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }

  &__description {
    margin: $spacing-medium-plus $spacing-xl 0 $spacing-xl;
    font-size: $font-size-light;
    color: $color-scorpion;
  }

  &__button {
    margin-top: $spacing-xl;
    bottom: $spacing-small;

    &:hover {
      color: $color-white;
    }
  }

  &__file-container {
    display: flex;
    justify-content: center;

    &__file {
      display: inline-flex;
      margin-left: $spacing-small;
      align-self: center;

      &__title {
        position: absolute;
        margin: 62px 0 0 21px;
        font-weight: $font-weight-bolder;
        color: $color-white;
      }

      &--csv {
        color: $color-primary;
      }

      &--xlsx {
        color: $color-success;
      }

      &--pdf {
        color: $color-danger;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
