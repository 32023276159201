@import 'src/style/variables';

.SettingsCurrentOrganizationContent {
  margin-bottom: $spacing-page-horizontal;

  &__empty {
    text-align: center;
    color: $color-grey;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-page-vertical;

    &__logo {
      flex: 0 0 auto;
      border: none;
      width: 180px;
      height: 128px;

      &__image {
        width: 128px;
      }

      &__initials {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $color-grey;
        color: $color-alabaster;
        font-size: 1.3rem;
        text-transform: uppercase;
        user-select: none;
      }

      &__text {
        display: none;
      }

      &__form {
        display: none;
      }

      &:hover {
        background: $background-color-base;
        border: none;

        .SettingsCurrentOrganizationContent__header__logo {
          &__text {
            display: block;
            position: absolute;
            padding: $spacing-small;
            background: $color-scorpion;
            color: $color-white;
            z-index: 1;
          }

          &__image {
            opacity: .5;
          }
        }
      }
    }

    &__name {
      flex: 1;
      margin-left: $spacing-page-vertical;
      font-size: $font-size-important;
      font-weight: $font-weight-bolder;
    }
  }

  &__in-production {
    display: flex;
    margin: 0 0 $spacing-large;

    &__label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 13rem;
      font-weight: bold;
      font-size: $font-size-medium;
      margin-right: $spacing-large;
    }

    &__content {
      flex: 1;
      margin: 0;
      font-size: $font-size-medium;
      color: $color-scorpion;
    }
  }
}
