@import 'src/style/variables';

.ButtonWithUserRights {
  &__tooltip {
    padding: $spacing-base;
    display: flex;
    max-width: 300px;
    font-size: $font-size-small;
  }

  .Button {
    width: 100%;
  }
}
