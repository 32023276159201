@import 'src/style/variables';

.CustomerHistoryDayList {
  &__title {
    margin-bottom: $spacing-medium-plus;
    font-size: $font-size-subtitle;
    color: $color-mine-shaft;
  }

  &__list {
    margin: 0;
    padding: 0;

    .HistoryStep + .HistoryStep {
      margin-top: $spacing-small;
    }
  }

  & + & {
    margin-top: $spacing-page-vertical;
  }
}
