@import 'src/style/variables';

.GraphSection {
  padding: $spacing-page-vertical $spacing-large $spacing-base $spacing-large;
  width: $chart-width-base;

  &__chart {
    margin-top: $spacing-xl;
  }
}
