@import 'src/style/variables';

.BillsInProgress {
  text-align: right;

  &__total {
    font-size: $font-size-large;
    margin: 0;
  }

  &__bills {
    color: $color-scorpion;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;

    &__count {
      color: $color-primary;
      margin-left: $spacing-small;
    }
  }
}
