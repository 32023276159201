@import 'src/style/variables';

.MenuToggle {
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  background: none;

  &:hover,
  &:focus {
    background: none;
  }

  &__hamburger {
    display: block;
    position: relative;
    width: 1rem;
    height: 2px;
    background-color: $color-white;
    border-radius: 3px;
    transition: all 200ms ease;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 1.2rem;
      height: 2px;
      background-color: $color-white;
      border-radius: 3px;
      transition: all 200ms ease;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }
}
