@import 'src/style/variables';

.FirstTime {
  $spacing-video-left: 302px;
  $spacing-video-top: 337px;
  $show-video-width: 393px;
  $show-video-height: 245px;

  display: flex;
  min-height: 100%;
  min-width: 100%;

  &__intro {
    width: $width-logged-out-layout;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $background-color-dark;
    background-image: url('../../style/images/BG-login.jpg');
    background-size: cover;
    background-position: center;

    &__video {
      border: 0;
      border-radius: $border-radius-base;
    }

    &__show-video {
      position: absolute;
      top: $spacing-video-top;
      left: $spacing-video-left;
      border: 0;
      border-radius: $border-radius-base;
      width: $show-video-width;
      height: $show-video-height;
      background-image: url("../../style/images/dashboard-snip.png");
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 0;

      &:hover {
        background-image: url("../../style/images/dashboard-snip.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: $color-mine-shaft;

        .FirstTime__intro__show-video__description__title {
          color: $color-mine-shaft;
        }

        .Icon {
          color: $color-primary;
        }
      }

      &__description {
        background-image: linear-gradient(to top, rgba($color-white, 1), rgba($color-white, 0));
        padding: $spacing-large $spacing-base $spacing-base $spacing-base;
        width: 100%;

        &__title {
          color: $color-scorpion;
          font-weight: $font-weight-bolder;
          font-size: $font-size-light;
          margin-bottom: 0;
        }
      }

      .Icon {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 40px;
        max-height: 50px;
        color: rgba($color-primary, 0.8);
      }
    }
  }
}
