@import 'src/style/variables';

.PaymentRequestSideInfos {
  font-size: $font-size-medium;
  color: $color-scorpion;
  border-top: 1px solid $color-silver;
  padding-top: $spacing-page-vertical;

  &__item {
    display: flex;
    margin: 0 0 $spacing-base $spacing-base;
  }

  &__label {
    flex: 0 0 110px;
    font-weight: $font-weight-bolder;
    color: $color-mine-shaft;
    margin-right: $spacing-xs;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__value {
    flex: 1;
    margin: 0;
    line-break: none;
  }
}
