@use "sass:math";
@import 'src/style/variables';

.Plan {
  border: 1px solid $color-silver;
  margin: $spacing-base 0;
  border-radius: $border-radius-base;

  &__header {
    padding: $spacing-base;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__titles {
      display: flex;
      flex-direction: column;

      &__name {
        margin: math.div($spacing-xs, 2);
      }

      &__subtitles {
        display: flex;

        &__identifier {
          margin: math.div($spacing-xs, 2);
          color: $color-scorpion;
          font-size: $font-size-small;
          text-transform: uppercase;
        }

        &__group {
          margin-left: $spacing-xs;
          color: $color-primary;
          background-color: rgba($color: $color-primary, $alpha: 0.1);
          padding: $spacing-mini $spacing-xs;
          font-size: $font-size-small;
          border-radius: $border-radius-small;
          text-transform: uppercase;
        }

        &__solo {
          margin-left: $spacing-xs;
          color: $color-success;
          background-color: rgba($color: $color-success, $alpha: 0.1);
          padding: $spacing-mini $spacing-xs;
          font-size: $font-size-small;
          border-radius: $border-radius-small;
          text-transform: uppercase;
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      &__button {
        margin: 0 $spacing-xs;
        display: flex;
        padding: 0;
        height: $row-action-btn-size;
        width: $input-height-small;
      }
    }
  }

  &__details {
    padding-bottom: $spacing-base;
  }
}
