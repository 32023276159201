@import 'src/style/variables';

.Icon {
  display: inline-block;
  vertical-align: baseline;
  max-height: 20px;
  max-width: 20px;

  &--spin {
    animation-name: spin;
    animation-duration: 1800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
