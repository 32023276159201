@import 'src/style/variables';

.PaymentRequestPreview {
  display: flex;
  flex-wrap: wrap;

  &__divider {
    margin: 0 0 $spacing-large 0;
    background-color: $color-mercury;
    height: 0.5px;
    border: none;
    width: 100%;
  }

  &__client {
    font-weight: $font-weight-bolder;
    color: $color-scorpion;

    &__reference {
      font-size: $font-size-small;
      font-weight: $font-weight-regular;
      color: $color-grey;
      margin-left: $spacing-small;
    }
  }

  &__contact {
    display: flex;
    font-weight: $font-weight-bolder;

    .Button {
      margin-left: $spacing-small;
    }
  }

  &__no-contacts {
    color: $color-grey;
    font-style: italic;
    margin-bottom: 0;
  }

  &__managers {
    color: $color-grey;
  }

  &__attributes {
    display: flex;

    &__label {
      color: $color-mine-shaft;
      margin-right: $spacing-small;
    }

    &__value {
      color: $color-grey;
    }
  }

  .PDFViewer {
    margin-left: -$spacing-base;
  }
}
