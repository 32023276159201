@import 'src/style/variables';

.ActionsItem {
  border: 1px solid $color-silver;
  padding: $spacing-base;
  border-radius: $border-radius-base;
  margin-bottom: $spacing-base;
  background-color: transparent;
  justify-items: flex-start;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
    border: 1px solid $color-grey;
  }

  &__icon {
    margin-right: $spacing-base;
  }

  &__description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;

    &__titles {
      display: flex;

      &__title {
        margin-bottom: 0;
        font-size: $font-size-light;
        display: flex;
      }

      &__solo-badge {
        display: flex;
        font-weight: $font-weight-bold;
        height: fit-content;
        margin-left: $spacing-small;
        white-space: nowrap;
        color: $color-success;
        border-radius: $border-radius-small;
        padding: 0 $spacing-xs;
        font-size: $font-size-small;
        background-color: rgba($color-success, .1);
      }
    }

    &__days {
      font-size: $font-size-small;
      margin-bottom: 0;
      color: $color-primary;

      &__way {
        color: $color-scorpion;
      }
    }
  }

  &__to-validate {
    border-radius: $border-radius-base;
    padding: $spacing-xs $spacing-base;
    background-color: $color-alabaster;
    justify-self: flex-end;
    flex-shrink: 0;
  }

  &:hover {
    background-color: rgba($color: $color-silver, $alpha: 0.1);
  }
}
