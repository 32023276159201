@import 'src/style/variables';

.ImportsReportLinesList {
  border-bottom: rgba($color: $color-mercury, $alpha: 0.5) 1px solid;

  &__not-found {
    margin: $spacing-medium 0;
    color: $color-grey;
    font-size: $font-size-subtitle;
    font-style: italic;
    text-align: center;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
}
