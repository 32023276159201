@import 'src/style/variables';

.CustomerHistory {
  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $spacing-page-vertical;
    padding-bottom: $spacing-page-vertical;
    border-bottom: 1px solid $color-mercury;
  }

  &__title {
    flex: 1;
    font-size: $font-size-title;
    color: $color-mine-shaft;
    margin-bottom: 0;
    line-height: 1.1;
  }

  &__download {
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;

    > .Icon {
      margin-right: 8px;
    }
  }

  &__recent-count {
    flex: 0 0 100%;
    display: flex;
    margin: $spacing-medium-plus 0 0;
    padding: $spacing-medium-plus;
    background-color: $color-pending;
    border-radius: $border-radius-large;
  }

  &__no-data {
    margin: $spacing-medium 0;
    color: $color-grey;
    font-size: $font-size-subtitle;
    font-style: italic;
    text-align: center;
  }

  .Pagination {
    margin-top: $spacing-medium-plus;
    padding-left: 0;
    padding-right: 0;
    background: none;
    border: none;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    color: $color-scorpion;
  }
}
