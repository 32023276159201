@import 'src/style/variables';

.ForgottenPassword {
  &__title {
    font-size: $font-size-title;
    margin-bottom: $spacing-medium-plus;
  }

  &__form {
    width: $width-login-form;
    margin-right: 5%;
  }

  &__description {
    padding: $spacing-medium $spacing-base;
    background-color: rgba($color: $color-primary, $alpha: 0.1);

    &__text {
      color: $color-scorpion;
      font-size: $font-size-medium;
    }
  }
}
