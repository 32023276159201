@import 'src/style/variables';

.ListContactsItem {
  display: flex;

  &__details {
    flex: 2;
  }

  &__name {
    font-size: $font-size-light;
    font-weight: $font-weight-bolder;
    line-height: 1.45;
    margin: 0;
  }

  &__role {
    margin: 0;
    color: $color-primary;
    text-transform: lowercase;
    display: block;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__icons {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    color: $color-grey;

    .Icon {
      width: 15px;
      height: 15px;
      margin-left: $spacing-small;
    }
  }
}
