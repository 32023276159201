@import 'src/style/variables';

.HistoryStepContactsSentTo {
  &__item {
    &--danger {
      color: $color-danger;
      font-weight: $font-weight-bolder;
    }
  }

  &__send-to {
    white-space: pre-wrap;
  }

  &__role {
    color: $color-primary;
  }

  &__error-message {
    color: $color-danger;
  }

  &__name,
  &__role,
  &__date-sent,
  &__date-received,
  &__tracking-code,
  &__delivery-message,
  &__error-message {
    margin-bottom: $spacing-mini;
  }
}
