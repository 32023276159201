@import 'src/style/variables';

.GroupedNamedInput {
  $width-is-group-field: 150px;

  .FormFieldset {
    align-items: center;
  }

  .FormCheck {
    margin-bottom: $spacing-large;

    &__label {
      font-size: $font-size-light;
    }
  }

  &__is-group {
    // Important obligatoire sinon écrasé par FormFieldset
    flex: initial !important;
    width: $width-is-group-field;

    .select-search__input {
      height: 51px;
      border-radius: $border-radius-base 0 0 $border-radius-base;
    }

    .FormSelect--has-value {
      .select-search__value::after {
        display: block;
      }
    }
  }

  &__name {
    // Important obligatoire sinon écrasé par FormFieldset
    margin-left: 0 !important;
    flex: 1;

    &__text {
      margin-top: $spacing-base + $spacing-small + 1px;
      flex: 1;
    }

    .FormControl {
      border-radius: 0 $border-radius-base $border-radius-base 0;
      border-left: none;
    }
  }
}
