@import 'src/style/variables';
@import 'src/style/mixins';

.Modal {
  $modal-width: 837px;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  transition: opacity 200ms ease;

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1001;
    background: rgba($color-mine-shaft, .4);
  }

  &__wrapper {
    z-index: 1002;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: calc(#{$header-height} + #{$spacing-medium-plus});
    padding-bottom: $spacing-page-vertical;
  }

  &__page {
    width: 100%;
    max-width: $modal-width;
    border-radius: $border-radius-small;
    box-shadow: $modal-box-shadow;
    background: $color-white;
    text-align: left;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 ;
    padding: $spacing-page-vertical $spacing-large $spacing-medium;
    border-bottom: 1px solid $color-mercury;

    &__titles {
      flex: 1;
      min-width: 0;
    }

    &__actions {
      flex: 0 0 auto;
    }

    .Loading {
      flex: 0 0 auto;
      margin-left: $spacing-base;
    }

    .ModalWarningIcon {
      flex: 0 0 auto;
      margin-right: $spacing-medium;
    }

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0 $spacing-xs;
      padding: $spacing-medium $spacing-medium-plus;
      font-size: $font-size-subtitle;
      font-weight: $font-weight-regular;
      line-height: 1;
      color: $color-grey;
      border: none;

      &:hover,
      &:focus {
        color: $color-scorpion;
      }
    }

    &__helper {
      flex: 1;
      margin-left: $spacing-base;
    }

    @include media-min(desktop) {
      padding: $spacing-page-vertical $spacing-page-horizontal $spacing-medium;
    }
  }

  &__title {
    width: 100%;
    margin-bottom: 0;
    font-size: $font-size-important;
    font-family: $font-family-headings;
    font-weight: $font-weight-bolder;
    line-height: 1.1;
    color: $color-mine-shaft;
    overflow-wrap: break-word;
  }

  &__subtitle {
    flex: 1;
    font-size: $font-size-subtitle;
    font-weight: $font-weight-light;
    font-family: $font-family-base;
  }

  &__content {
    padding: $spacing-medium $spacing-large $spacing-xl;

    @include media-min(desktop) {
      padding: $spacing-xl $spacing-page-horizontal $spacing-page-vertical;
    }
  }

  &--hidden {
    opacity: 0;
  }

  &__tooltip {
    padding: $spacing-base;
  }
}
