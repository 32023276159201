@use "sass:math";
@import 'src/style/variables';

.FormRadiosItem {
  position: relative;
  display: block;
  height: $radiobox-size;
  margin-bottom: $spacing-small;
  padding-left: 0;

  &__label {
    min-width: $radiobox-size;
    height: $radiobox-size;
    padding-left: calc(#{$radiobox-size} + #{$spacing-base});
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: '';
      border-radius: $radiobox-size;
      line-height: $radiobox-size;
    }

    &::before {
      top: 1px;
      left: 0;
      width: $radiobox-size;
      height: $radiobox-size;
      background-color: $color-white;
      border: 1px solid $color-silver;
    }

    &::after {
      display: none;
      top: math.div($radiobox-size, 4) + 1px;
      left: math.div($radiobox-size, 4);
      width: $radiobox-size - math.div($radiobox-size, 2);
      height: $radiobox-size - math.div($radiobox-size, 2);
      background-color: $color-primary;
    }

    &:hover {
      &::before {
        border-color: $color-grey;
      }
    }
  }

  &__input {
    width: 0;
    margin: 0;
    opacity: 0;

    &:checked {
      + .FormRadiosItem__label::before {
        border-color: $color-silver;
        background-color: $background-color-base;
      }

      + .FormRadiosItem__label::after {
        display: block;
      }
    }
  }

  &--disabled {
    .FormRadiosItem__label {
      cursor: not-allowed;
    }

    .FormRadiosItem__label,
    .FormRadiosItem__label::before {
      border-color: $color-silver;
      color: $color-grey;
    }

    .FormRadiosItem__label::after {
      opacity: .5;
    }
  }
}
