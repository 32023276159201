@import 'src/style/variables';

.ConfirmResendForm {
  &__error {
    color: $color-danger;
  }

  &__info {
    color: $color-primary;
    background-color: rgba($color: $color-primary, $alpha: 0.1);
    padding: $spacing-base $spacing-small;
    border-radius: $border-radius-base;
    margin-bottom: $spacing-base;
    font-size: $font-size-small;
  }
}
