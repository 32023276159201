@import 'src/style/variables';

.HistoryStepContacts {
  &__summary {
    border: none;
    padding: 0;

    &:active,
    &:focus {
      border: none;
      outline: 0;
    }
  }

  &__details {
    &__item {
      display: inline-block;

      &__role {
        text-transform: lowercase;
        color: $color-primary;
        font-weight: bold;
        display: inline-block;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .btn-link {
      padding: 0;
      font-size: $font-size-small;
    }
  }

  &__icon {
    margin-top: 2px;
    letter-spacing: 5px;
  }

  &__reminder-link {
    margin-left: $spacing-small;
    padding: 0;
    font-size: $font-size-small;
  }
}
