@import 'src/style/variables';

.ContactForm {
  &__civility {
    max-width: 70px;
  }

  &__postal-code {
    max-width: 100px;
  }
}
