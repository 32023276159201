@import 'src/style/variables';

.Collapse {
  background-color: $color-white;
  border-radius: $border-radius-base;
  overflow: hidden;

  &__header {
    width: 100%;
    color: $color-mine-shaft;
    display: flex;
    align-items: center;
    padding: $spacing-medium-plus $spacing-medium;

    &__content {
      flex: 1
    }

    &__icon {
      flex: 0 0 auto;

      .Icon {
        color: $color-scorpion;
      }
    }

    &:hover,
    &:focus {
      background-color: inherit;
      color: inherit;

      .Icon {
        color: $color-mine-shaft;
      }
    }
  }

  &__content {
    display: none;
    border-top: 2px solid $background-color-secondary;
  }

  &--open {
    .Collapse__content {
      display: block;
    }
  }
}
