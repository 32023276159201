@import 'src/style/variables';

.HomeHistory {
  padding: 0 $spacing-base;

  &__no-data {
    color: $color-grey;
    font-size: $font-size-medium;
  }

  &__list {
    margin: 0;
    padding: 0;
  }
}
