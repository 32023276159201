@import 'src/style/variables';
@import 'src/style/mixins';

.NoOrganizationSelected {
  display: flex;

  &__decoration {
    display: none;
    flex: 0 0 30%;
    height: calc(100vh - #{$header-height});
    justify-content: space-between;
    background-color: $background-color-dark;
    background-image: url('../../../style/images/BG-login.jpg');
    background-size: cover;
    background-position: center;
  }

  &__content {
    flex: 1;
    height: calc(100vh - #{$header-height});
    overflow-y: auto;
    padding: 80px 100px 80px 60px;
  }

  &__title {
    font-size: $font-size-title;
    margin-bottom: $spacing-base;
    line-height: 1.033;
  }

  &__help {
    margin-bottom: $spacing-page-vertical;
    font-size: $font-size-base-plus;
    font-weight: $font-weight-bold;
    color: $color-scorpion;
  }

  &__organizations {
    margin: 0;
    padding: 0;
  }

  @include media-min(desktop) {
    &__decoration {
      display: block;
    }
  }

  @include media-min(medium-desktop) {
    &__decoration {
      flex: 0 0 40%;
    }

    &__content {
      padding: 100px 191px 100px 111px;
    }
  }
}
