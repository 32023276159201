@import 'src/style/variables';

.CreateTaskForm {
  &__date {
    max-width: 50%;
  }

  &__group-check {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: $spacing-medium;

    .FormSwitch {
      margin-left: $spacing-large;
    }

    .FormGroup__header__label {
      margin-bottom: 0;
    }
  }
}
