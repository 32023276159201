@import 'src/style/variables';

.InputModal {
  &__help {
    margin-bottom: $spacing-base;
  }

  &__submit {
    display: flex;
    align-items: center;
    margin-left: $spacing-small;
  }

  .Loading {
    margin-right: $spacing-small;
  }
}
