@import 'src/style/variables';

.CustomerAnalysis {
  padding: $spacing-page-vertical $spacing-page-horizontal;

  &__title {
    font-size: $font-size-title;
    color: $color-mine-shaft;
    margin-bottom: $spacing-page-vertical;
  }

  &__content {
    max-width: 840px;
  }
}
