@import 'src/style/variables';

.HistoryStep {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85px;
  padding: $spacing-medium $spacing-base;
  background-color: $background-color-base;
  list-style-type: none;
  border: 1px solid $color-silver;
  border-radius: $border-radius-small;

  &__infos {
    flex: 1;
    display: flex;
    align-items: center;

    .HistoryStepInfosHeader {
      flex: 1;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__collapse-toggle {
    width: $row-action-btn-size;
    height: $row-action-btn-size;
    padding: 0;
  }

  .StepIcon {
    margin-top: $spacing-mini;
  }

  .HistoryStep__collapse-toggle {
    margin-left: $spacing-xs;
  }

  &--is-alert {
    border: none;
    background-color: $color-alabaster;
  }

  &--is-note {
    background: none;
  }

  &--is-waiting {
    border: none;
    background-color: $background-color-warning;

    .HistoryStepDate__info {
      color: $color-warning;
    }
  }

  &--is-error {
    border: none;
    background-color: $background-color-error;
  }
}
