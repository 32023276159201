@import 'src/style/variables';

.BackOfficeHeader {
  display: flex;
  align-items: center;
  padding: $spacing-page-vertical $spacing-page-horizontal $spacing-xl ;

  &__links {
    flex: 0 0 auto;
    align-self: flex-start;

    .Button {
      width: $link-back-btn-size;
      height: $link-back-btn-size;
      padding: 0;
    }
  }

  &__infos {
    flex: 1;
    margin-left: $spacing-medium-plus;

    .Loading__content {
      justify-content: flex-start;
    }

    &__name {
      margin-bottom: $spacing-xs;
      font-size: $font-size-title;
      line-height: 1;
      margin-top: .5rem;
    }

    &__subtitle {
      color: $color-scorpion;
    }
  }

}
