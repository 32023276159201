%dropdown-item {
  display: block;
  width: 100%;
  padding: $spacing-base $spacing-xl;
  line-height: 1.22;
  font-size: $font-size-light;
  color: $color-scorpion;

  &:hover {
    text-decoration: none;
    background: $color-alabaster;
    color: $color-mine-shaft;
  }
}

%modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: $spacing-large -#{$spacing-large} -#{$spacing-xl} -#{$spacing-large};
  padding: $spacing-large $spacing-large $spacing-xl;
  background-color: $color-mine-shaft;
  border-radius: 0 0 $border-radius-small $border-radius-small;
  font-size: $font-size-base;

  @include media-min(desktop) {
    margin: $spacing-page-vertical -#{$spacing-page-horizontal} -#{$spacing-page-vertical} -#{$spacing-page-horizontal};
    padding: $spacing-large $spacing-page-horizontal $spacing-xl;
  }

  .Button {
    // on utilise !important pour éviter que les parents puissent définir un autre flex
    flex: 0 0 auto !important;
    font-weight: $font-weight-bold;
  }

  .Button + .Button {
    margin-left: $spacing-base;
  }
}
