@import 'src/style/variables';

.TagsPlugin {
  min-width: 50px;
  max-width: 100px;
  min-height: 34px;
  background: $color-white;
  border: 1px solid $color-mercury;
  border-radius: 4px;

  .select-search__select {
    z-index: 2;
  }

  .select-search__input {
    height: 34px;
    padding: 0 $spacing-xs;
  }

  .select-search__value::after {
    right: $spacing-small;
  }
}
