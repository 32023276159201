@import 'src/style/variables';

.Notification {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  color: $color-white;
  border-radius: 50%;
  font-size: $font-size-mini;
  font-weight: $font-weight-bolder;

  &__count {
    line-height: $font-size-micro;
  }
}
