@import 'src/style/variables';

.Plans {
  .SettingsSection {
    .Button--disabled {
      background-color: $color-white;
      color: $color-scorpion;
    }
  }

  &__description {
    color: $color-scorpion;
    font-size: $font-size-light;
    margin-bottom: $spacing-xl;
  }
}
