@import 'src/style/variables';

.HistoryStepDetailsItem {
  display: flex;
  margin: 0;

  & + & {
    margin-top: $spacing-base;
  }

  &__label {
    flex: 0 0 180px;
    font-size: $font-size-medium;
    margin: 0;
    padding-left: $spacing-page-vertical;
    font-weight: bold;
  }

  &__value {
    flex: 0 0 auto;
    max-width: 500px;
    margin: 0;
    color: $color-scorpion;
    font-size: $font-size-medium;
    word-wrap: break-word;
    white-space: pre-line;
  }
}
