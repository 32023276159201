@import 'src/style/variables';

.HistoryStepContactRecipients {
  &__missing-contacts {
    margin-right: $spacing-small;
  }

  &__role {
    color: $color-primary;
    margin-left: $spacing-xs;
  }
}
