@import 'src/style/variables';

.TaskForm {
  &__daysShift {
    margin-top: -$spacing-medium-plus;
    margin-bottom: $spacing-medium-plus;
    color: $color-warning;
    font-size: $font-size-light;

    .Icon {
      margin-right: $spacing-small;
      vertical-align: middle;
    }
  }
}
