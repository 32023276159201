@import 'src/style/variables';

.PaymentEditForm {
  &__customer {
    padding-right: $spacing-large;
    flex: 1;
  }

  .FormAmountCurrency {
    flex: 1;
    padding-right: $spacing-large;
  }

  &__date-type {
    flex: 1;
    display: flex;
  }

  &__imputation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-mercury;
    padding-bottom: $spacing-base;
    margin-bottom: $spacing-xl;

    &__title {
      flex: 1;
      text-transform: uppercase;
      font-weight: $font-weight-bolder;
      font-size: $font-size-mini;
      color: $color-scorpion;
    }

    &__error {
      margin-right: $spacing-base;
      margin-bottom: 0;
      font-size: $font-size-small;
      font-weight: $font-weight-bolder;
      color: $color-danger;
    }

    .Button {
      color: $color-scorpion;

      &:hover {
        color: $color-mine-shaft;
      }
    }
  }

  &__pay-requests {
    &__suggestions {
      margin-bottom: $spacing-xl;

      &__title {
        color: $color-grey;
        font-size: .9rem;
        font-weight: 400;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;

        .Button {
          margin: 0 $spacing-base $spacing-small 0;
        }
      }
    }
  }

  &--no-customer {
    .PaymentEditForm__customer {
      display: none;
    }
  }

  &--no-pay-requests {
    .PaymentEditForm__pay-requests {
      display: none;
    }
  }
}
