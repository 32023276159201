@import 'src/style/variables';

.Customers {
  margin-bottom: $spacing-xxl;

  .ErrorMessage {
    margin-bottom: $spacing-base;
  }

  &__columns {
    &__name > a {
      font-size: $font-size-base;
      font-weight: $font-weight-bolder;
      color: $color-mine-shaft;
    }

    &__ref,
    &__count {
      margin-top: $spacing-xs;
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      color: $color-scorpion;
    }

    &__in-progress {
      font-weight: $font-weight-bolder;
      color: $color-mine-shaft;
    }
  }
}
