@import 'src/style/variables';

.ImportsReportDetails {
  margin-top: $spacing-large;

  &__container {
    margin-top: $spacing-large;
    border-top: $color-mercury solid 1px;
  }
}
