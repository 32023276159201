@import 'src/style/variables';

.HistoryStepDate {
  display: flex;
  flex-direction: column;
  color: $color-scorpion;
  text-align: right;
  font-size: $font-size-small;
  font-weight: $font-weight-medium;

  &__date {
    margin: 0 $spacing-base 0 0;
  }

  &__info {
    margin: $spacing-mini $spacing-base 0 0;
  }

  &__error-message {
    margin: $spacing-mini $spacing-base 0 0;
    font-weight: $font-weight-regular;

    &--warning {
      color: $color-warning;
    }

    &--danger {
      color: $color-danger;
    }
  }
}
