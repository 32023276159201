@import 'src/style/variables';

.ModelsAction {
  border: 1px solid $color-silver;
  padding: $spacing-base;
  border-radius: $border-radius-base;
  margin-bottom: $spacing-base;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__main {
    display: flex;

    &__icon {
      margin-right: $spacing-base;

      .StepIcon {
        height: $input-height-small;
        width: $input-height-small;
      }
    }

    &__description {
      &__title {
        margin-bottom: 0;
      }

      &__channel {
        margin-bottom: 0;
        font-size: $font-size-small;
        color: $color-scorpion;
      }
    }
  }

  .DropdownActions {
    align-self: center;
    justify-self: flex-end;
  }
}
