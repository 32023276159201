@import 'src/style/variables';

.Answer {
  &__title {
    font-size: $font-size-title;
    margin-bottom: $spacing-medium-plus;
  }

  .AnswerForm {
    width: $width-login-form;
  }

  &__errors {
    padding-top: $spacing-base;
  }
}
