@import 'src/style/variables';

.SortableList {
  width: 100%;
  border-collapse: collapse;
  color: $color-mine-shaft;

  &__header {
    &__cell {
      padding: $spacing-base 0;
      user-select: none;
      white-space: nowrap;
      vertical-align: middle;
      border-bottom: 1px solid $color-mercury;
      border-top-width: 0;
      font-size: $font-size-mini;
      font-weight: $font-weight-bolder;
      color: $color-grey;
      letter-spacing: $letter-spacing-base;
      text-transform: uppercase;

      &--first {
        width: 50px;
      }
    }
  }
}
