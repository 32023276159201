@import 'src/style/variables';

.BlockSection {
  margin-bottom: $spacing-page-vertical;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-medium-plus;

    &__title {
      flex: 0 0 auto;
      font-size: $font-size-subtitle;
      margin: 0;
      padding: 0 $spacing-base;
    }

    &__count {
      flex: 1;
      color: $color-primary;
      font-weight: $font-weight-bold;
    }

    &__link {
      flex: 0 0 auto;
      font-size: $font-size-medium;

      .Icon {
        width: .5rem;
        height: .5rem;
        margin-left: $spacing-xs;
      }
    }
  }
}
