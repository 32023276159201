@import 'src/style/variables';

.CustomerSummary {
  &__side-view {
    .CustomerAttributes,
    .CustomerLastNotes,
    .CustomerInformations {
      margin-bottom: $spacing-page-vertical;
    }

    .CustomerLastActivities {
      margin-bottom: $spacing-large;
    }
  }

  &__title {
    font-size: $font-size-title;
    color: $color-mine-shaft;
    margin-bottom: $spacing-medium-plus;
  }

  .CustomerStats {
    margin-bottom: $spacing-small;
  }

  .DataTableBody__cell:first-child::before,
  .DataTableBody__cell:last-child::after {
    display: none;
  }
}
