@import 'src/style/variables';

.PreviewListElement {
  display: flex;
  flex-basis: 50%;
  margin-bottom: $spacing-large;

  &--full-width {
    flex-basis: 100%;

    .PreviewListElement__content {
      flex: 17;
    }
  }

  &__label {
    display: flex;
    flex: 3;
    font-weight: bold;
    font-size: $font-size-medium;
    margin-right: $spacing-small;
    flex-direction: column;

    &__content {
      width: fit-content;
      flex-direction: column;
      display: flex;

      .Button {
        justify-content: flex-end;
      }
    }
  }

  &__content {
    flex: 7;
    margin: 0;
    font-size: $font-size-medium;
    color: $color-scorpion;
  }
}
