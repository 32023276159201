@import 'src/style/variables';
@import 'src/style/mixins';

.HeaderNav {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: $color-white;

  &__header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
    padding: 0;
    border-bottom: 1px solid rgba(28,39,60,.05);
  }

  .BrandLogo {
    margin-left: $spacing-base;
  }

  &__close {
    flex: 0 0 auto;
    padding: $spacing-base $spacing-medium;
    border: none;
    color: $color-silver;

    &:hover {
      color: $color-white;
    }
  }

  &__items {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0;
    padding: 0;

    &__separator {
      flex: 0 0 auto;
      align-self: center;
      width: 50%;
      height: 1px;
      margin: $spacing-base 0;
      background-color: rgba($color-white, .5);
    }
  }

  &__active-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: $header-active-item-indicator-width;
    height: 100%;
    background: linear-gradient(180deg, $color-secondary 0%, $color-primary 100%);
  }

  @include media-min(desktop) {
    flex-direction: row;

    &__header {
      display: none;
    }

    &__items {
      flex-direction: row;

      &__separator {
        width: 1px;
        height: 1.5rem;
        margin: 0 $spacing-small;
      }
    }

    &__active-indicator {
      width: 100%;
      height: $header-active-item-indicator-width;
      background: linear-gradient(90deg, $color-secondary 0%, $color-primary 100%);
    }
  }

  @include media-min(medium-desktop) {
    &__items__separator {
      margin: 0 $spacing-base;
    }
  }
}
