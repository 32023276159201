@use "sass:math";
@import 'src/style/variables';

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: $spacing-small $spacing-xl;
  border: none;
  border-radius: $btn-border-radius;
  background-color: transparent;
  color: $color-grey;
  line-height: 1.3;
  font-weight: $font-weight-bold;
  white-space: nowrap;
  transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    text-decoration: none;
  }

  &__arrow {
    color: $color-primary;
    margin-left: math.div($spacing-base, 2);
  }

  &--primary {
    background-color: $color-primary;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: $color-primary-hover;
    }
  }

  &--danger {
    background-color: $color-danger;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: darken($color-danger, 5%);
      color: $color-white;
    }
  }

  &--black {
    background-color: $color-mine-shaft;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: $color-black;
    }
  }

  &--outline,
  &--outline-white {
    background-color: transparent;
    border: 2px solid $color-silver;
    color: $color-scorpion;
    padding: calc(#{$spacing-small} - 2px) calc(#{$spacing-xl} - 2px);

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: $color-grey;
      color: $color-mine-shaft;
    }
  }

  &--outline-white {
    background-color: $color-white;

    &:hover,
    &:focus {
      background-color: $color-white;
    }
  }

  &--outline-black {
    background-color: transparent;
    border: 2px solid $color-grey;
    color: $color-alabaster;
    padding: calc(#{$spacing-small} - 2px) calc(#{$spacing-xl} - 2px);

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: $color-silver;
      color: $color-white;
    }
  }

  &--outline-light {
    padding: calc(#{$spacing-small} - 2px) calc(#{$spacing-xl} - 2px);
    background-color: transparent;
    border: 2px solid $color-white;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: $color-white;
      color: $color-white;
    }
  }

  &--outline-danger {
    padding: calc(#{$spacing-small} - 2px) calc(#{$spacing-xl} - 2px);
    color: $color-danger;
    background-color: rgba($color: $color-danger, $alpha: 0.1);
    border: 2px solid rgba($color: $color-danger, $alpha: 0.1);

    &:hover {
      background-color: rgba($color: $color-danger, $alpha: 0.2);
      border: 2px solid rgba($color: $color-danger, $alpha: 0.2);
    }

    &:focus {
      background-color: rgba($color: $color-danger, $alpha: 0.3);
    }
  }

  &--outline-primary {
    padding: calc(#{$spacing-small} - 2px) calc(#{$spacing-xl} - 2px);
    color: $color-primary;
      background-color: rgba($color: $color-primary, $alpha: 0.1);
      border: 2px solid rgba($color: $color-primary, $alpha: 0.1);

    &:hover {
      background-color: rgba($color: $color-primary, $alpha: 0.2);
      border: 2px solid rgba($color: $color-primary, $alpha: 0.2);
    }

    &:focus {
      background-color: rgba($color: $color-primary, $alpha: 0.3);
    }
  }

  &--outline-success {
    padding: calc(#{$spacing-small} - 2px) calc(#{$spacing-xl} - 2px);
    color: $color-success;
    background-color: rgba($color: $color-success, $alpha: 0.1);
    border: 2px solid rgba($color: $color-success, $alpha: 0.1);

    &:hover {
      background-color: rgba($color: $color-success, $alpha: 0.2);
      border: 2px solid rgba($color: $color-success, $alpha: 0.2);
    }

    &:focus {
      background-color: rgba($color: $color-success, $alpha: 0.3);
    }
  }

  &--light {
    background-color: $color-mercury;
    color: $color-scorpion;

    &:hover,
    &:focus {
      background-color: $color-silver;
      color: $color-mine-shaft;
    }
  }

  &--list {
    justify-content: stretch;
    padding: $spacing-base $spacing-xl;
    border-radius: 0;
    background: none;
    color: $color-scorpion;
    text-align: left;
    transition: none;

    .Icon {
      color: $color-silver;
    }

    &:hover,
    &:focus {
      background: $color-alabaster;
      color: $color-mine-shaft;
      text-decoration: none;

      .Icon {
        color: $color-grey;
      }
    }
  }

  &--list-primary {
    justify-content: stretch;
    padding: $spacing-base $spacing-xl;
    border-radius: 0;
    background: none;
    color: $color-primary;

    &:hover,
    &:focus {
      background: $color-alabaster;
      color: $color-primary-hover;
      text-decoration: none;
    }
  }

  &--list-danger {
    justify-content: stretch;
    padding: $spacing-base $spacing-xl;
    border-radius: 0;
    background: none;
    color: $color-danger;

    &:hover,
    &:focus {
      background: $color-alabaster;
      color: $color-danger-hover;
      text-decoration: none;
    }
  }

  &--link {
    display: flex;
    height: auto;
    padding: 0;
    color: $color-primary;
    letter-spacing: 0.02rem;

    &:hover,
    &:focus {
      color: $color-primary-hover;
      text-decoration: underline;
    }
  }

  &--link-danger {
    display: flex;
    height: auto;
    padding: 0;
    color: $color-danger;
    letter-spacing: 0.02rem;

    &:hover,
    &:focus {
      color: $color-danger-hover;
      text-decoration: underline;
    }
  }

  &--small {
    font-size: $font-size-medium;
    padding: $spacing-small $spacing-medium-plus;
  }

  &--disabled {
    background-color: $color-silver;
    color: $color-white;
    cursor: not-allowed;

    .Button__arrow {
      color: $color-silver;
    }

    &:hover,
    &:focus {
      background-color: $color-silver;
      color: $color-white;
    }
  }

  &--outline.Button--disabled {
    background-color: transparent;
    border-color: $color-mercury;
    color: $color-silver;

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: $color-mercury;
    }
  }

  &--link.Button--disabled {
    background-color: $color-white;
    color: $color-grey;
  }

  &--list.Button--disabled {
    background-color: $color-white;
    color: $color-grey;

    &:hover,
    &:focus {
      .Icon {
        color: $color-silver;
      }
    }
  }

  &--list-danger.Button--disabled {
    background-color: $color-white;
    color: $color-grey;
  }
}
