@import 'src/style/variables';

.PDFViewer {
  width: fit-content;

  &__modal {
    &__download-link {
      .Icon {
        margin-right: $spacing-small;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &:hover {
      .PDFViewer__content__pdf-nav__pdf {
        border-color: $color-silver;
      }
    }

    &__close {
      position: relative;
      right: $spacing-medium;
      top: $spacing-medium-plus;
      padding: $spacing-mini;
    }

    &__pdf-nav {
      display: flex;
      align-items: center;

      &__pdf {
        border: 1px solid $color-mercury;
        border-radius: $border-radius-base;
      }

      &__right {
        position: relative;
        right: $spacing-medium;
      }

      &__left {
        position: relative;
        left: $spacing-medium;
      }

      .Button {
        padding: $spacing-mini;
      }
    }

    &--without-nav {
      .PDFViewer__content__close {
        right: $spacing-xs;
      }
    }

    &--locked {
      cursor: default;

      &:hover {
        .PDFViewer__content__pdf-nav__pdf {
          border-color: $color-mercury;
        }
      }
    }
  }
}
