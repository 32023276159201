@import 'src/style/variables';

.DataTableFiltersItemNumberInput {
  position: relative;

  &__currency {
    position: absolute;
    display: flex;
    align-items: center;
    right: 1px;
    bottom: 1px;

    &__symbol {
      padding: 0 $spacing-small;
      font-size: $font-size-light;
      font-weight: $font-weight-bold;
    }

    &__code {
      display: flex;
      align-items: center;
      height: $input-height-base - 2px;
      padding: 0 $spacing-base;
      border-radius: 0 $border-radius-base $border-radius-base 0;
      background-color: $color-alabaster;
      border-left: 1px solid $color-silver;
      font-size: $font-size-light;
      font-weight: $font-weight-bold;
    }
  }
}
