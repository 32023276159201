@import 'src/style/variables';
@import 'src/style/mixins';

.ActionsGroup {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__others__collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  > .Button,
  &__others__collapse > .Button {
    flex: 1;
    justify-content: start;
    color: $color-scorpion;
    background-color: $color-white;
    margin-bottom: 2px;
    min-height: 55px;

    .Icon {
      margin-right: $spacing-base;
    }

    &--danger {
      color: $color-danger;
    }
  }

  .Button--list:hover, .Button--list:focus {
    background: $color-white;
    color: $color-mine-shaft;
    text-decoration: none;
  }

  &__others:first-child,
  .ActionButton:first-child {
    border-radius: $border-radius-large $border-radius-large 0 0;
  }

  &__others:last-child,
  .ActionButton:last-child {
    border-radius: 0 0 $border-radius-large $border-radius-large;
  }

  &__others {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
    font-size: $font-size-light;

    .Button {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      flex: 1;
    }
  }
}
