@import 'src/style/variables';

.HomeContent {
  display: flex;
  align-items: stretch;

  &__main {
    flex: 7;
    padding: 0 $spacing-page-vertical 0 $spacing-page-horizontal;
  }

  &__side {
    flex: 3;
    background-color: $background-color-secondary;
    padding: $spacing-page-vertical $spacing-page-horizontal $spacing-page-vertical $spacing-large;
    border-top-left-radius: $border-radius-large;
  }
}
