@import 'src/style/variables';

.FirstTimeOption {
  margin: $spacing-xs 0;
  border-radius: $border-radius-base;
  display: flex;
  padding: $spacing-medium $spacing-medium;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-alabaster;
  width: 100%;

  .Icon {
    color: $color-silver;
  }

  &:hover {
    background-color: $color-mercury;
    text-decoration: none;

    .Icon {
      color: $color-grey;
    }

    .FirstTimeOption__icon-box {
      .Icon {
        color: $color-white;
      }
    }
  }

  &__title {
    color: $color-mine-shaft;
    margin-bottom: 0;
    font-weight: $font-weight-bolder;
  }

  &__next {
    margin-left: auto;
  }

  &__icon-box {
    border-radius: $border-radius-base;
    display: flex;
    padding: $spacing-small;
    background-color: $color-primary;
    margin-right: $spacing-base;

    .Icon {
      color: $color-mercury;
    }
  }
}
