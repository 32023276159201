@import 'src/style/variables';

.ReportsCount {
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid $color-primary;
  color: $color-white;
  font-size: $font-size-medium;
  font-weight: $font-weight-medium;

  &__count {
    position: absolute;
  }

  &--in-progress {
    width: 20px;
    height: 20px;
    color: $color-grey;
    display: flex;
    border: none;

    .Icon {
      position: absolute;
      color: $color-primary;
      margin-right: 0;
      width: 30px;
      height: 30px;
      max-width: none;
      max-height: none;
    }
  }

  &--success {
    background-color: $color-primary;
  }

  &--error {
    background-color: $color-warning;
    border-color: $color-warning;
  }

  &--critical {
    background-color: $color-danger;
    border-color: $color-danger;
  }
}
