@import 'src/style/variables';
@import 'src/style/mixins';

.ListContactsView {
  &__header {
    display: flex;
    align-items: center;
    margin: 0 $spacing-large;
    padding: $spacing-page-vertical 0 ($spacing-medium + $spacing-xs);
    border-bottom: 1px solid $color-mercury;

    @include media-min(desktop) {
      margin: 0 $spacing-page-horizontal;
    }
  }

  &__title {
    flex: 1;
    margin: 0;
    line-height: 1;
  }

  &__link {
    flex: 0 0 auto;
  }

  &__infos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-medium;
    padding-bottom: $spacing-medium;
    border-bottom: 1px solid $color-mercury;
    font-size: $font-size-medium;
    line-height: 1.8;

    &:last-child {
      margin: 0;
      border: none;
    }

    &__group {
      flex: 1;
      min-width: 300px;
    }

    &__line {
      flex: 1;
      display: flex;
      margin-bottom: $spacing-small;
    }

    &__label {
      flex: 0 0 110px;
      font-weight: $font-weight-bolder;
      margin: 0;
      padding: 0;
    }

    &__value {
      color: $color-scorpion;
      padding: 0;
      margin: 0;

      &--contact-role {
        display: block;
        text-transform: lowercase;
        color: $color-primary;
        word-break: break-word;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    @include media-min(desktop) {
      margin-bottom: $spacing-xl;
      padding-bottom: $spacing-xl;
    }
  }
}
