@import 'src/style/variables';

.SignUp {
  .Alert,
  .ErrorMessage {
    width: $width-login-form;
    margin-bottom: $spacing-base;
  }

  &__title {
    font-size: $font-size-title;
    margin-bottom: $spacing-base;
  }

  &__form {
    width: $width-login-form;
    margin-right: 5%;
  }

  &__login {
    color: $color-scorpion;
    font-weight: $font-weight-bold;
    font-size: $font-size-base-plus;
    margin-bottom: $spacing-xxl;

    &__link {
      margin-left: $spacing-xs;
    }
  }
}
