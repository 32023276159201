@import 'src/style/variables';

.CreateReminderEditForm {
  &__date {
    max-width: 50%;
    flex: 0;
  }

  &__need-validation,
  &__group-check {
    flex: 1;
    flex-direction: row;
    align-items: center;

    .FormGroup__header__label {
      margin: 0 $spacing-base 0 0;
    }
  }

  &__separator {
    width: 100%;
    margin-top: 0;
    margin-bottom: $spacing-xl;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $color-mercury;
    border-left: 0;
  }

  &__content {
    .FormGroupWithActions__titles {
      .Button {
        margin-left: $spacing-xl;
      }
    }
  }
}
