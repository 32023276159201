@import 'src/style/variables';

.HomeMyActions {
  &__list {
    margin: 0;
    padding: 0;
  }

  &__no-data {
    color: $color-grey;
    margin-left: $spacing-base;
    font-size: $font-size-medium;

    &__drawing {
      justify-content: center;
      display: flex;
      margin: $spacing-page-vertical 0;

      &__icon {
        width: 220px;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;

      .ActionButton {
        padding: $spacing-medium $spacing-medium-plus;
        background-color: $color-white;
        border-bottom: 2px solid $color-alabaster;

        .Icon {
          margin-right: $spacing-base;
        }
      }
    }
  }
}
