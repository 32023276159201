@import 'src/style/variables';

.FormSelectUsersWithRights {
  &__unavailable {
    height: $input-height-base;
    display: flex;
    align-items: center;
    padding-left: $spacing-medium;
    border-radius: $border-radius-base;
    border: 1px solid $color-silver;
    background-color: rgba($color: $color-silver, $alpha: 0.1);
    color: $color-scorpion;
  }
}
