@import 'src/style/variables';

.SortableListRow {
  background-color: $background-color-base;

  &__cell {
    padding: ($spacing-medium + $spacing-xs) 0;
    vertical-align: middle;
    border-top-width: 0;
    border-bottom: 1px solid $color-mercury;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    line-height: 1.4;
    color: $color-scorpion;
    max-width: 250px;

    &--first {
      width: 50px;
      cursor: grab;
      color: $color-silver;
      padding-left: $spacing-base;
    }
  }
}
