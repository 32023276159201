@import 'src/style/variables';

.HeaderSearch {
  position: relative;
  padding: 0 $spacing-xs 0 $spacing-medium;

  .SearchInput {
    width: 162px;

    &.InputGroup {
      .InputGroup__helper {
        background-color: $color-black;
        border: none;

        .Loading {
          color: $color-silver;
        }
      }

      .FormControl {
        background-color: $color-black;
        border: none;
        color: $color-white;
        font-weight: $font-weight-medium;

        &::placeholder {
          color: $color-silver;
        }
      }
    }

    &:hover,
    &:focus-within {
      box-shadow: 0 0 1px 4px $color-mine-shaft;

      .FormControl {
        background-color: $color-black;
        color: $color-white;

        &::placeholder {
          color: $color-white;
        }
      }
    }
  }

  .HeaderSearchResults {
    position: absolute;
    left: -$spacing-xs;
    top: calc(#{$input-height-small} + #{$spacing-xs});
    width: calc(
      #{$spacing-xs} /* décalage left -5px (voir ci-dessus) */
      + #{$spacing-medium} /* padding avant input de recherche */
      + 162px /* Input de recherche */
      + #{$spacing-xs} /* padding après input de recherche */
      + #{$spacing-base} /* padding avant bouton "Nouveau" */
      + 120px /* bouton "Nouveau" */
    );
    box-shadow: $dropdown-box-shadow;
    z-index: 1000;
  }
}
