@import 'src/style/variables';

.ToastNotification {
  &__wrapper {
    position: fixed;
    left: -1px;
    bottom: -2px;
    right: -1px;
    z-index: 9999;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: $spacing-large;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-align: center;
    color: $color-white;
  }

  &__close-button {
    position: absolute;
    right: $spacing-large;
    min-width: 120px;
  }

  &--primary {
    .ToastNotification__content {
      background-color: $color-primary;
    }
  }

  &--info {
    .ToastNotification__content {
      background-color: $color-mine-shaft;
    }
  }

  &--success {
    .ToastNotification__content {
      background-color: $color-success;
    }
  }

  &--warning {
    .ToastNotification__content {
      background-color: $color-warning;
    }
  }

  &--error {
    .ToastNotification__content {
      background-color: $color-danger;
    }
  }
}
