@import 'src/style/variables';

.Avatar {
  &__container {
    position: relative;
    overflow: hidden;
    display: flex;
    border-radius: 50%;
    justify-content: center;

    &__initials {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $color-grey;
      color: $color-alabaster;
      font-size: 1.3rem;
      text-transform: uppercase;
      user-select: none;
    }

    &__image {
      display: inline;
      margin: 0 auto;
      height: 100%;
      width: auto;
    }
  }
}
