@import 'src/style/variables';
@import 'src/style/mixins';

.CreateMenu {
  margin: 0 $spacing-base;

  &__hidden {
    width: $spacing-page-horizontal;
  }

  .Dropdown__toggle {
    height: $input-height-small;
    min-width: 120px;
    padding: 0 $spacing-base;
    font-weight: $font-weight-medium;
  }

  &__link {
    @extend %dropdown-item;

    font-weight: $font-weight-bold;
  }

  .Button--disabled {
    background-color: $color-white;
    color: $color-grey;

    &:hover {
      color: $color-mine-shaft;
      background-color: $color-alabaster;
    }
  }

  .ButtonWithUserRights .Button {
    text-align: left;
  }
}
