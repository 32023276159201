@use "sass:math";

// - Polices de caractères

$font-family-base:
  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, 'Noto Sans',
  Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-family-monospace:
  'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;

$font-family-headings: 'Object Sans', $font-family-base;

// - Tailles de police

$font-size-root: 15px;

$font-size-micro: #{1 * math.div(2, 3)}rem; // 10px
$font-size-mini: #{1 - 0.2 - math.div(0.2, 3)}rem; // 11px
$font-size-small: .8rem; // 12px
$font-size-medium: #{1 - 0.1 - math.div(0.1, 3)}rem; // 13px
$font-size-light: #{1 - math.div(0.2, 3)}rem; // 14px
$font-size-base: 1rem; // 15px (=== $font-size-root)
$font-size-base-plus: #{1 + math.div(1, 15)}rem; // 16px
$font-size-subtitle: #{1 + math.div(1, 3)}rem; // 20px
$font-size-important: #{1 + math.div(2, 3)}rem; // 25px
$font-size-large: 2rem; // 30px
$font-size-title: #{2 + math.div(1, 3)}rem; // 35px

// - Graisses de police

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

// - Divers

$line-height-base: 1.5;

$letter-spacing-base: .02rem;

$link-decoration: none;
$link-hover-decoration: underline;
