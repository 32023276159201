@import 'src/style/variables';

.CustomerMuteButtonUnMute {
  $block: &;

  &__icon {
    color: $color-primary !important;
  }

  &--with-text {
    #{$block}__icon {
      margin-right: $spacing-small;
      color: $color-silver !important;
    }
  }
}
