@import 'src/style/variables';

.Contacts {
  margin-bottom: $spacing-xxl;

  .ErrorMessage {
    margin-bottom: $spacing-base;
  }

  &__columns {
    &__link {
      color: $color-mine-shaft;
    }
  }
}
