@import 'src/style/variables';

.ModalEditCollaboratorsForm {
  font-weight: $font-weight-bolder;

  &__perimeter {
    &__header {
      display: flex;
      margin-bottom: $spacing-medium-plus;
    }

    &__title {
      margin-right: auto;
    }

    &__association_error {
      background-color: $color-alabaster;

      &__content {
        color: $color-grey;
      }
    }
  }
}
