@use "sass:math";
@import 'src/style/variables';

.FormSwitch {
  $block: &;
  $switch-width: 50px;
  $switch-height: 29px;
  $switch-border: 2px;
  $switch-toggle-size: math.div($switch-width, 2);

  display: flex;
  align-items: center;
  gap: $spacing-base;
  height: $input-height-base;

  &__button {
    position: relative;
    width: $switch-width;
    height: $switch-height;
    padding: 0;
    border-radius: $border-radius-base;
    border: 2px solid lighten($color-grey, 25%);
    background-color: lighten($color-grey, 25%);
    transition: border-color 150ms, background-color 150ms;

    &:hover,
    &:focus,
    &:active {
      border: 2px solid lighten($color-scorpion, 25%);
      background-color: lighten($color-scorpion, 25%);
      outline: 0;
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: $switch-toggle-size;
    height: $switch-toggle-size;
    border-radius: $border-radius-small;
    background-color: $background-color-base;
    transition: left 150ms;

    &__icon {
      display: none;
      // - Important pour éviter la surcharge malencontreuse de certains components
      color: $color-primary !important;
    }
  }

  &__label {
    flex: 1;
  }

  &--active {
    #{$block}__button {
      background-color: $color-primary;
      border-color: $color-primary;

      &:hover,
      &:focus,
      &:active {
        background-color: $color-primary-hover;
        border-color: $color-primary-hover;

        #{$block}__toggle__icon {
          // - Important pour éviter la surcharge malencontreuse de certains components
          color: $color-primary-hover !important;
        }
      }
    }

    #{$block}__toggle {
      left: auto;
      right: 0;

      &__icon {
        display: block;
      }
    }
  }
}
