@import 'src/style/variables';

.HomeMyActionsItem {
  &__name {
    font-size: $font-size-light;
    font-weight: $font-weight-bolder;
    line-height: 1.45;
    margin-left: $spacing-base;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__pay-request {
    font-weight: $font-weight-medium;
    font-size: $font-size-small;

    &__link,
    &__grouped-action {
      color: $color-scorpion;
    }

    &__client {
      margin-left: $spacing-xs;
      color: $color-primary;
    }
  }
}
