@import 'src/style/variables';

.ReportItemStatus {
  &__title {
    font-weight: $font-weight-bolder;
    font-size: $font-size-light;
    margin-bottom: 0px;
  }

  &__subtitle {
    color: $color-danger;
    font-size: $font-size-medium;
    margin-bottom: 0px;
  }

  &__count {
    color: $color-scorpion;
    font-size: $font-size-medium;
    margin-bottom: 0px;
  }

  &--warning {
    .ReportItemStatus__subtitle {
      color: $color-danger;
    }
  }

  &--error {
    .ReportItemStatus__title {
      color: $color-danger;
    }

    .ReportItemStatus__count {
      color: $color-danger;
    }
  }

  &--in-progress {
    .ReportItemStatus__title {
      color: $color-primary;
    }
  }
}
