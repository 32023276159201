@import 'src/style/variables';

.StripeElement {
  display: block;
  width: 100%;
  height: $input-height-base;
  margin: 0;
  padding: $spacing-base $spacing-medium;
  border: 1px solid $color-silver;
  border-radius: $input-border-radius;
  background-color: $background-color-base;
  background-clip: padding-box;
  color: $input-text-color;
  font-size: $font-size-light;
  font-weight: $font-weight-medium;
  line-height: 1.5;

  &:hover {
    border-color: $input-border-hover-color;
  }

  &--focus {
    // Important, car l'état "focus" est ajouté en JS et ne supplante donc pas le hover ci-dessus
    border-color: $input-border-focus-color !important;
    box-shadow: none;
    color: $input-text-focus-color;
    outline: 0;
  }

  &--invalid {
    // Important, car l'état "focus" est ajouté en JS et ne supplante donc pas le hover ci-dessus
    border-color: $color-danger !important;
  }

  &--webkit-autofill {
    background-color: $background-color-base !important;
  }
}
