@import 'src/style/variables';

.SelectorsDefaults {
  &__row {
    display: flex;

    &__box {
      display: flex;
      flex-direction: column;
      flex: 1;

      &__subtitle {
        font-weight: $font-weight-medium;
      }

      &__description {
        font-size: $font-size-light;
        color: $color-scorpion;
      }
    }
  }

  .FormRadios {
    flex-direction: column;
    align-items: flex-start;
    margin-top: $spacing-small;
    margin-left: $spacing-large;
    color: $color-scorpion;

    .FormRadiosItem {
      margin-bottom: $spacing-base;
    }
  }

  .SelectorsDefaults__row__box + .SelectorsDefaults__row__box {
    margin-left: $spacing-base;
  }
}
