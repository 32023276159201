@import 'src/style/variables';

.StripeModalForm {
  &__bank-name {
    margin: 5px 0;
    color: $color-scorpion;
    font-weight: 400;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__notice {
    font-size: $font-size-medium;
    color: $color-scorpion;
    font-weight: 300;
    margin-top: $spacing-base;
  }
}
