@import 'src/style/variables';

.SelectGroupItem {
  display: flex;
  margin-top: $spacing-base;

  &__name {
    margin-left: $spacing-medium;
  }
}
