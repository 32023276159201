@import 'src/style/variables';

.SendingActionsView {
  &__pdf,
  &__text {
    &__content {
      width:100%;
      height:300px;
      border: none;
    }
  }

  &__img__content {
    width: 100%;
  }
}
