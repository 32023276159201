@import 'src/style/variables';

.ImportsFileWizardStepChoiceFiles {
  width: 100%;

  &__box {
    margin-top: $spacing-base;
    margin-bottom: $spacing-page-vertical + $header-height;
    padding: $spacing-base 0;

    &__title {
      font-size: $font-size-subtitle;
    }
  }

  &__columns {
    &__name {
      font-size: $font-size-medium;
      color: $color-mine-shaft;
      margin-bottom: 0;
    }
  }

  .DataTableBody__cell {
    .Icon {
      color: $color-primary;
    }
  }
}
