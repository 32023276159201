@import 'src/style/variables';

.FormFieldset {
  display: flex;

  .FormGroup {
    flex: 1;
  }

  .FormGroup + .FormGroup,
  .FormAmountCurrency + .FormGroup,
  .FormGroup + .FormAmountCurrency {
    margin-left: $spacing-large;
  }
}
