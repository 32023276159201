@import 'src/style/variables';

.BackOffice {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    display: flex;
    align-items: stretch;
  }

  &__side-view {
    flex: 3;
    background-color: $background-color-secondary;
    padding: $spacing-page-vertical $spacing-large $spacing-page-vertical $spacing-page-horizontal;
    border-top-right-radius: $border-radius-large;

    .BackOfficeNav {
      margin-bottom: $spacing-page-vertical;
    }
  }

  &__main-view {
    flex: 7;
    padding: 0 $spacing-page-horizontal 0 $spacing-page-vertical;
  }
}
