@import 'src/style/variables';

.LoginForm {
  &__new-user {
    font-size: $font-size-base-plus;
    color: $color-scorpion;
    margin-bottom: $spacing-xxl;

    &__link {
      margin-left: $spacing-small;
    }
  }

  &__forgot-password {
    font-size: $font-size-medium;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-medium;
  }

  .Button {
    width: 100%;
    padding-top: $spacing-base;
    padding-bottom: $spacing-base;
  }
}
