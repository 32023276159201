@import 'src/style/variables';

.ImportsReportFileHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $spacing-large $spacing-medium;

  &__infos {
    display: flex;
    align-items: center;

    &__title {
      margin: 0 $spacing-base;
      max-width: 500px;
      word-break: break-all;
    }

    &__text {
      color: $color-scorpion;
      margin: 0 $spacing-base;
    }
  }

  &__tabs {
    flex: 1;
    display: flex;
    align-items: center;

    &__tab {
      margin-left: $spacing-small;
      padding: $spacing-mini $spacing-small;
      border: 1px solid;
      border-radius: $border-radius-base;

      .Icon {
        display: none;
        margin-left: $spacing-small;
      }

      &--error,
      &--error:hover {
        color: $color-danger;
        background-color: rgba($color-danger, 0.1);

        &:hover,
        &:focus {
          background-color: rgba($color-danger, 0.2);
          text-decoration: none;
        }
      }

      &--created,
      &--created:hover {
        color: $color-success;
        background-color: rgba($color-success, 0.1);

        &:hover,
        &:focus {
          background-color: rgba($color-success, 0.2);
          text-decoration: none;
        }
      }

      &--updated,
      &--updated:hover {
        color: $color-primary;
        background-color: rgba($color-primary, 0.1);

        &:hover,
        &:focus {
          background-color: rgba($color-primary, 0.2);
          text-decoration: none;
        }
      }

      &--skipped,
      &--skipped:hover {
        color: $color-scorpion;
        background-color: $color-white;
        border-color: $color-silver;

        &:hover,
        &:focus {
          background-color: $color-alabaster;
          text-decoration: none;
        }
      }

      &--active,
      &--active:hover {
        outline: 0;

        .Icon {
          display: inline-block;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__complete {
      margin-right: $spacing-base;

      &:hover {
        color: $color-white;
      }
    }

    &__web {
      padding: $spacing-small;
      margin: $spacing-xs;
      border-radius: $border-radius-base;
      background-color: $color-alabaster;
      color: $color-primary;
      text-transform: uppercase;

      &__text {
        margin: 0;
        font-size: $font-size-mini;
        font-weight: $font-weight-bold;
      }
    }

    &__menu {
      .Dropdown__toggle {
        padding: $spacing-base $spacing-small;
        margin-right: $spacing-xs;
        border-color: $color-mercury;
        color: $color-grey;

        .Dropdown__toggle__content {
          align-items: center;
          margin: 0px;
          display: flex;
        }
      }
    }
  }
}
