@import 'src/style/variables';

.PaymentRequestSide {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    padding-top: $spacing-page-vertical;
    flex: 1;
    display: flex;
    align-items: stretch;

    &__main-view {
      flex: 7;
    }

    &__side-view {
      flex: 3;
      background-color: $color-alabaster;
      padding: $spacing-large $spacing-page-horizontal $spacing-page-vertical $spacing-large;
      border-radius: 8px 0px 0px 0px;
    }
  }

  .ListContacts,
  .ListNotes {
    flex: 0;
    margin-bottom: $spacing-page-vertical;
  }

  &__link {
    margin-bottom: 2px;
    background-color: $color-white;

    .Icon {
      margin-right: $spacing-base;
    }

    .ActionButton {
      width: 100%;
    }
  }

  .RowActionsToggle,
  .RowActionAddPayment,
  .RowActionPromiseToPay,
  .RowActionNote,
  .RowActionCreditNote,
  .PaymentRequestSide__link,
  .ActionButton {
    .Icon {
      width: $row-action-icon-size;
      height: $row-action-icon-size;
    }
  }
}
