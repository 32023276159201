@import 'src/style/variables';

.SelectorsModalEditYAML {
  &__description {
    color: $color-scorpion;
    margin-bottom: $spacing-large;
  }

  &__download {
    background-color: $color-success;

    &:hover {
      background-color: darken($color-success, 5%);
    }
  }

  &__tooltip {
    padding: $spacing-base;
  }

  &__title {
    margin: $spacing-large 0 $spacing-small 0;
    color: $color-scorpion;
    text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: $font-weight-bolder;
  }

  .Dropzone__content {
    height: 100px;
  }

  .ErrorValidationMessage {
    margin-top: $spacing-small;
    color: $color-danger;
    background-color: rgba($color: $color-danger, $alpha: 0.1);
    padding: $spacing-base $spacing-large;
    border-radius: $border-radius-base;
    border: 1px solid $color-danger;
  }

  .Loading {
    margin-top: $spacing-small;
  }

  .FormFieldset {
    font-weight: $font-weight-bold;
    align-items: center;
    margin-top: $spacing-large;
  }

  &__check {
    color: $color-scorpion;
    margin-bottom: 0;
  }

  &__valid {
    color: $color-success;
    background-color: rgba($color: $color-success, $alpha: 0.1);
    padding: $spacing-base $spacing-large;
    margin: $spacing-base 0;
    border-radius: $border-radius-base;
    border: 1px solid $color-success;
  }

  .Icon {
    margin-left: $spacing-base;
  }
}
