@import 'src/style/variables';

.Tooltip {
  position: fixed;
  max-width: $tooltip-max-width;
  z-index: 9999;
  border-radius: $border-radius-base;
  box-shadow: $dropdown-box-shadow;
  background-color: $background-color-base;
  color: $color-mine-shaft;

  &--centered {
    transform: translateX(-50%) translateY($spacing-small);
  }
}
