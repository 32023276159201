@import 'src/style/variables';

.PlanModalAddAction {
  .Modal__header__actions {
    .Button--link.Button--disabled {
      background-color: transparent;
      color: $color-grey;
    }
  }

  &__dispatch-description {
    margin-bottom: $spacing-xl;
  }
}
