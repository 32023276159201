@import 'src/style/variables';

.ReportInfosSecondary {
  flex: 4;
  display: flex;
  flex-direction: row;

  &__datafix {
    color: $color-primary;
  }

  &__section {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__box {
      display: flex;
      flex: 1;

      &__title {
        font-size: $font-size-medium;
        flex: 1;
      }

      &__subtitle {
        font-size: $font-size-medium;
        color: $color-scorpion;
        flex: 2;
      }
    }
  }
}
