@import 'src/style/variables';

.PlansModalEditPlan {
  .FormFieldset {
    align-items: center;
  }

  .FormCheck {
    margin-bottom: $spacing-large;

    &__label {
      font-size: $font-size-light;
    }
  }

  &__tooltip-icon {
    display: flex;
    justify-content: flex-end;
    margin-top: -$spacing-large;
    margin-bottom: $spacing-base;
  }

  &__tooltip {
    padding: $spacing-base;
  }

  &__group-check {
    margin: $spacing-base;
  }
}
