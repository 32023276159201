@import 'src/style/variables';

.CustomerAccountDetailsSubjectCell {
  &__text {
    margin-bottom: $spacing-xs;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    color: $color-scorpion;
    text-transform: uppercase;
  }

  &__imputation {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    white-space: nowrap;

    &__link {
      color: $color-scorpion;
    }
  }

  &__tooltip {
    padding: $spacing-xs $spacing-small;
    font-size: $font-size-medium;
    overflow: auto;
    overflow-wrap: break-word;
  }
}
