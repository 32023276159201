@import 'src/style/variables';

.OrganizationCreate {
  display: flex;
  height: 100%;
  width: 100%;

  &__intro {
    position: fixed;
    top: 0;
    height: 100%;
    width: $width-logged-out-layout;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $background-color-dark;
    background-image: url('../../style/images/BG-login.jpg');
    background-size: cover;
    background-position: center;
  }

  &__content {
    flex: 1;
    padding: 112px 0;
    min-height: 400px;
    margin-left: $width-logged-out-layout;
    display: flex;
    align-items: center;
    justify-content: center;

    &__title {
      font-size: $font-size-title;
      margin-bottom: $spacing-base;
    }

    &__subtitle {
      color: $color-scorpion;
      margin-bottom: $spacing-xxl;
    }

    &__form {
      width: $width-create-orga-form;
      margin-right: 5%;
    }
  }
}
