@import 'src/style/variables';

.PayerInvoicesRowActions {
  display: flex;
  justify-content: flex-end;

  .Button {
    border-radius: $border-radius-base;

    .Loading {
      padding: 0;
      align-items: center;
      justify-content: center;
    }
  }

  &__download-link {
    display: flex;
    flex-grow: 1;
  }
}

