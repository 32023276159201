@import 'src/style/variables';

.ImportsFileWizardStep {
  &__content {
    display: flex;
    padding: $spacing-page-vertical $spacing-page-horizontal;
  }

  &__side-view {
    flex: 2;
    border-top-right-radius: $border-radius-large;
  }

  &__main-view {
    display: flex;
    flex: 8;
  }

  &__details {
    display: flex;

    &__step-number {
      background: $color-primary;
      padding: $spacing-small $spacing-base;
      color: $color-white;
      border-radius: $border-radius-base;
    }

    &__title {
      margin-top: $spacing-xs;
      margin-left: $spacing-base;
      font-size: $font-size-subtitle;
      font-weight: $font-weight-bolder;
      display: flex;

      &__link {
        display: flex;
        align-items: flex-start;

        .Icon {
          margin-left: $spacing-small;
        }
      }
    }
  }

  &__description {
    margin-top: $spacing-xl;
    color: $color-scorpion;
    line-height: 180%;
    width: 245px;
    padding-right: $spacing-base;
  }

  &__options {
    display: flex;
    align-content: center;
    flex-direction: column;
    padding: $spacing-large 0;

    &__check {
      display: flex;

      &__label {
        margin-bottom: 0;
        align-self: center;
        text-transform: uppercase;
      }

      &__tooltip {
        display: flex;
        align-items: center;
        padding-left: $spacing-base;
      }
    }
  }

  &__tooltip {
    padding: $spacing-base;
  }
}
