@use "sass:math";
@import 'src/style/variables';

.Pagination {
  $block: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: math.div($spacing-base, 2.143) $spacing-large;
  background-color: $color-mercury;
  border-top: 1px solid $color-silver;

  &__lines,
  &__pages,
  &__summary {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }

  &__button {
    flex: 0 0 auto;
    width: 1.8rem;
    height: 1.8rem;
    padding: 0;
    background-color: $background-color-base;
    border-radius: 2px;
    border: none;
    color: $color-scorpion;

    &.disabled,
    &:disabled {
      background-color: $color-alabaster;
      color: $color-silver;
    }

    &--first {
      border-radius: 2px 0 0 2px;
      margin-left: 2px;
    }

    &--last {
      border-radius: 0 2px 2px 0;
      margin-right: 2px;
    }

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active {
      background-color: $background-color-base;
      color: $color-mine-shaft;
    }
  }

  &__lines {
    #{$block}__button--first {
      margin-left: $spacing-small;
    }

    #{$block}__button--last {
      margin-right: $spacing-small;
    }
  }

  &__value {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.8rem;
    background-color: $background-color-base;
    padding: 0 $spacing-xs;
    color: $color-mine-shaft;
    user-select: none;
    cursor: default;
  }

  &__summary {
    color: $color-mine-shaft;
  }
}
