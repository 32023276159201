@import 'src/style/variables';

.NotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 0;

  &__title {
    font-size: $font-size-large;
    margin-bottom: $spacing-large;
  }

  &__text {
    margin-bottom: $spacing-large;
    color: $color-scorpion;
  }
}
