@import 'src/style/variables';

.AttributesListing {
  &__description,
  &__help {
    color: $color-scorpion;
    font-size: $font-size-light;
  }

  &__no-data {
    margin: $spacing-medium 0;
    color: $color-grey;
    font-size: $font-size-subtitle;
    font-style: italic;
    text-align: center;
  }

  &__row {
    &--overview-displayed {
      background-color: rgba($color: $color-primary, $alpha: 0.05) !important;
    }
  }

  &__help {
    margin: $spacing-medium 0;
  }
}
