@import 'src/style/variables';

.SignUpForm {
  &__terms {
    color: $color-scorpion;
    text-align: center;
    font-weight: $font-weight-regular;
    font-size: $font-size-small;

    &__link {
      margin-left: $spacing-xs;
    }
  }

  &__submit {
    width: 100%;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-medium;
  }
}
