@import 'src/style/variables';

.Alert {
  position: relative;
  margin-bottom: $spacing-base;
  padding: $spacing-base $spacing-large;
  border-radius: $border-radius-small;
  font-size: $font-size-light;
  font-weight: $font-weight-bolder;

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 $spacing-large;
    border: none !important;

    > .Icon {
      width: 10px;
    }
  }

  &--neutral {
    background-color: rgba($color-scorpion, .05);
    color: $color-mine-shaft;
  }

  &--info {
    background-color: rgba($color-primary, .05);
    color: $color-mine-shaft;
  }

  &--success {
    background-color: rgba($color-success, .05);
    color: $color-mine-shaft;
  }

  &--warning {
    background-color: rgba($color-warning, .05);
    color: $color-mine-shaft;
  }

  &--error {
    background-color: rgba($color-danger, .05);
    color: $color-mine-shaft;
  }
}
