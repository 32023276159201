@import 'src/style/variables';

.ReservedAccess {
  padding: 3rem;

  &__back {
    left: $spacing-page-horizontal;
    top: $spacing-page-vertical;

    .Button {
      width: $link-back-btn-size;
      height: $link-back-btn-size;
      padding: 0;
    }
  }

  &__content {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: $font-size-large;
      margin-bottom: $spacing-base;
    }

    &__text {
      margin-bottom: $spacing-large;
      color: $color-grey;
    }

    &__image {
      width: 400px;
      transform: scale(1.4);
    }
  }
}
