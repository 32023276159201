@import 'src/style/variables';

.StepIcon {
  display: flex;
  flex: 0 0 35px;
  color: $color-white;
  border-radius: $border-radius-xs;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;

  &--reminder {
    background: $color-black;
  }

  &--task {
    background: $color-primary;
  }

  &--bill {
    color: $color-scorpion;
  }

  &--debit {
    background-color: $color-info;
    color: $color-white;
  }

  &--credit {
    background-color: $color-success;
    color: $color-white;
  }

  &--registered-letter {
    background: rgba($color-danger, 0.1);
  }

  &__icon {
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
