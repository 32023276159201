@import 'src/style/variables';
@import 'src/style/mixins';

.CustomerHeaderActions {
  .CustomerActionMute,
  .CustomerActionCreateNote,
  .CustomerActionNewContact,
  .CustomerActionNewPayRequest,
  .CustomerActionNewPayment,
  .CustomerActionNewDelete {
    @extend %dropdown-item;
  }

  .CustomerActionMute,
  .CustomerActionCreateNote {
    display: flex;
  }

  &__grouped-actions {
    display: flex;
    flex-direction: column;
  }

  &__tooltip-content {
    margin: 0;
    padding: $spacing-small;
    font-size: 0.9rem;
    color: $color-scorpion;
    text-align: center;
  }

  &__separator {
    width: 100%;
    margin: $spacing-small 0;
    border-top: 1px solid $color-mercury;
    border-right: none;
    border-bottom: none;
    border-left: none;
  }
}
