@import 'src/style/variables';

.FormGroupWithActions {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-large;
  width: 100%;

  .ErrorValidationMessage {
    margin: 0 $spacing-small 0 0;
  }

  &--with-error-under-input {
    margin-bottom: $spacing-base;
  }


  &__titles {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .Button {
        margin: 0 0 0 $spacing-small;
      }

      .Button--disabled {
        background-color: transparent;
        color: $color-grey;
      }
    }

    &__label {
      display: block;
      margin-bottom: $spacing-small;
      color: $form-label-color;
      font-size: $font-size-mini;
      font-weight: $font-weight-bolder;
      text-transform: uppercase;
    }
  }

  &--mandatory {
    .FormGroupWithActions__titles__label::after {
      content: '*';
      color: $color-danger;
      font-size: $font-size-base;
      line-height: .1;
      font-weight: $font-weight-bolder;
      margin-left: $spacing-small;
    }
  }
}
