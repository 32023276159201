@import 'src/style/variables';

.OrganizationSelectorItemTitle {
  $block: &;

  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border-radius: 0;
  color: $color-scorpion;
  padding: $spacing-base $spacing-xl $spacing-base $spacing-medium-plus;

  &__logo {
    flex: 0 0 auto;
    height: $font-size-base * 1.5;
    margin-right: $spacing-small;
  }

  &__name {
    flex: 1;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-transform: capitalize;
  }

  &__not-prod {
    flex: 0 0 auto;
    margin: 0 $spacing-base;
    color: $color-secondary;
    font-size: $font-size-micro;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  &__open-icon {
    // - Important, pour supplanter la définition de  Button--list
    color: $color-scorpion !important;
  }
}

