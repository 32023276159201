@import 'src/style/variables';

.AddActionForm {
  &__need-validation {
    margin-bottom: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;

    .FormGroup__header__label {
      margin-bottom: 0;
    }
  }

  &__divider {
    border-bottom: 1px solid $color-mercury;
    width: 100%;
    height: $spacing-small;
    margin-bottom: $spacing-xl;
  }

  &__days-group {
    .FormGroup {
      flex-direction: row;
      align-items: center;

      &__header__label {
        color: $color-mine-shaft;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        text-transform: initial;
        margin-bottom: 0;
      }
    }

    &__days-input {
      width: 100px;
    }

    &__label {
      margin: 0 $spacing-base;
    }
  }
}
