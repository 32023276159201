@import 'src/style/variables';

.SplitButtonDropdown {
  display: flex;

  &__main-link {
    margin-right: -2px;

    &:hover {
      z-index: 1;
      text-decoration: none;
    }

    &__button {
      height: $input-height-small;
      padding: 0 $spacing-medium;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        background-color: $color-alabaster;
      }
    }
  }

  .Dropdown {
    &__toggle {
      display: flex;
      align-items: center;
      height: $input-height-small;
      padding: 0 $spacing-base;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &__content > .Icon {
        margin-bottom: 2px;
      }
    }

    &:not(.Dropdown--open) {
      .Dropdown__toggle {
        &__content > .Icon {
          color: $color-grey;
        }

        &:hover,
        &:focus {
          background: $color-alabaster;

          .Dropdown__toggle__content > .Icon {
            color: $color-mine-shaft;
          }
        }
      }
    }
  }
}
