@import 'src/style/variables';

.ImportsReportLineItem {
  $block: &;

  padding: $spacing-xs $spacing-large;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: rgba($color: $color-mercury, $alpha: 0.5) 1px solid;

  &__line-text {
    flex: 1;
    display: flex;
    margin-bottom: 0;
    font-size: $font-size-mini;
    font-weight: $font-weight-bold;
  }

  &__status {
    flex: 1;
    display: flex;

    &__text {
      padding: $spacing-xs $spacing-small;
      margin-right: $spacing-medium;
      margin-bottom: 0;
      font-size: $font-size-micro;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      border: rgba($color: $color-mercury, $alpha: 0.5) 1px solid;
      border-radius: $border-radius-base;
    }
  }

  &__date {
    flex: 2;
    display: flex;
    margin-bottom: 0;
    font-size: $font-size-mini;
    font-weight: $font-weight-medium;
  }

  &__reference {
    flex: 2;
    display: flex;
    margin-bottom: 0;
    font-size: $font-size-mini;
    font-weight: $font-weight-medium;
  }

  &__client-info {
    flex: 4;
    display: flex;
    margin-bottom: 0;
    margin-left: $spacing-small;
    font-size: $font-size-mini;
    font-weight: $font-weight-medium;

    &__reference {
      margin-bottom: 0;
      margin-left: $spacing-xs;
      font-size: $font-size-micro;
      font-weight: $font-weight-bold;
      color: rgba($color: $color-grey, $alpha: 0.5);
    }
  }

  &__error {
    flex: 4;
    display: flex;
    flex-direction: column;
    margin-left: $spacing-small;
  }

  &--error {
    background-color: rgba($color: $color-danger, $alpha: 0.1);

    #{$block}__status__text {
      border: $color-danger 1px solid;
      color: $color-danger;
    }
  }

  &--created {
    background-color: rgba($color: $color-success, $alpha: 0.1);

    #{$block}__status__text {
      border: $color-scorpion 1px solid;
      color: $color-success;
    }
  }

  &--updated {
    background-color: rgba($color: $color-primary, $alpha: 0.1);

    #{$block}__status__text {
      border: $color-primary 1px solid;
      color: $color-primary;
    }

    #{$block}__client-info {
      color: $color-primary;
    }
  }
}
