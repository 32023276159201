@import 'src/style/variables';

.ModalWarningIcon {
  color: $color-warning;
  font-size: 1.2rem;
  cursor: help;

  &__tooltip {
    background-color: $color-warning;
    padding: $spacing-small $spacing-base;
    border-radius: $border-radius-base;
    color: $color-white;
  }
}
