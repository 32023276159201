@import 'src/style/variables';

.PublicLayout {
  min-width: 100%;
  min-height: 100%;
  display: flex;

  &__container {
    margin-left: $width-logged-out-layout;
    display: flex;
    flex: 1;
    padding: 112px 0;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }

  &__intro {
    position: fixed;
    height: 100%;
    width: $width-logged-out-layout;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $background-color-dark;
    background-image: url('../../style/images/BG-login.jpg');
    background-size: cover;
    background-position: center;

    .BrandLogo {
      margin: $spacing-medium-plus $spacing-large;
    }

    .LoginLinks {
      margin-left: $spacing-page-horizontal;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: $spacing-page-vertical;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;

      &__version,
      &__copyright {
        flex: 0 0 auto;
        margin: 0;
        color: $color-grey;
      }

      &__version {
        margin: 0 $spacing-base;
      }

      .select-search__select {
        top: -$input-height-base * 3 - $spacing-base * 2;
      }

      .select-search__input {
        border: none;
        background-color: transparent;
        color: $color-white;
        height: min-content;
      }

      .FormSelect--has-value {
        .select-search__value::after {
          display: block;
          right: 100px;
        }
      }
    }
  }
}
