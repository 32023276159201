@import 'src/style/variables';
@import 'src/style/mixins';

.DropdownActions {
  .Dropdown {
    &__toggle {
      height: $row-action-btn-size;
      padding: 0 8px;
      transition: none;

      &__content > .Icon {
        margin-bottom: 1px;
        width: 20px;
        height: 6px;
      }

      .Loading .Icon {
        width: calc(#{$row-action-icon-size} - 0.3rem);
        color: $color-scorpion;
      }
    }
  }

  &:not(.Dropdown--open) {
    .Dropdown {
      &__toggle {
        &__content > .Icon {
          color: $color-grey;
        }

        &:hover,
        &:focus {
          border-color: $color-grey;
          color: $color-scorpion;

          .Dropdown__toggle__content > .Icon {
            color: $color-scorpion;
          }
        }
      }
    }
  }

  &__item {
    @extend %dropdown-item;

    display: flex;
    padding: 0;

    .Button,
    .ButtonWithUserRights {
      flex: 1;

      .Icon {
        margin-right: $spacing-base;
        color: $color-silver;
      }

      &:hover {
        .Icon {
          color: $color-grey;
        }
      }
    }

    &:hover {
      background: none;
    }
  }
}
