@import 'src/style/variables';
@import 'src/style/mixins';

.CustomerMainLayout {
  display: flex;
  align-items: stretch;

  &__side-view {
    flex: 2;
    min-width: 350px;
    background-color: $background-color-secondary;
    padding: $spacing-page-vertical $spacing-large $spacing-page-vertical $spacing-page-vertical;
    border-right: 1px solid $color-mercury;
  }

  &__main-view {
    flex: 7;
    padding: $spacing-page-vertical $spacing-page-vertical;
  }

  @include media-min(desktop) {
    &__side-view {
      padding: $spacing-page-vertical $spacing-large $spacing-page-vertical $spacing-page-horizontal;
    }
  }
}
