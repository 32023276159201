@import 'src/style/variables';

.SettingsList {
  &__item {
    &__delimiter {
      display: flex;
      margin: 0 0 $spacing-large;
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 13rem;
      font-weight: bold;
      font-size: $font-size-medium;
      margin-right: $spacing-large;
    }

    &__value {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      font-weight: $font-weight-medium;
      font-size: $font-size-medium;
      color: $color-scorpion;
      margin: 0;
    }
  }
}
