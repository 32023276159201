@import 'src/style/variables';

.ListStatsByPhase {
  &__phases-percent-bar {
    border-left: 1px solid $color-silver;
    transition: border 200ms ease;
  }

  &__content {
    width: 260px;
    padding: 0;
    background-color: $color-mine-shaft;
    color: $color-white;
    border-radius: $border-radius-large;
    border-top-left-radius: 0;
    font-size: $font-size-small;
    position: absolute;

    &__count {
      margin: $spacing-base 0 0;
    }

    &__title {
      font-size: $font-size-mini;
      text-transform: uppercase;
      font-weight: $font-weight-bolder;
      margin-bottom: $spacing-small;
    }

    &__phases-percent-bar {
      padding: ($spacing-medium + $spacing-xs) $spacing-large $spacing-medium;
      background-color: $color-black;
      border-top-right-radius: $border-radius-large;
    }

    &__phases-percent {
      padding: ($spacing-medium + $spacing-xs) $spacing-large $spacing-medium;
    }
  }
}
