@import 'src/style/variables';

.SelectPayRequestForCustomer {
  position: relative;

  &__clear {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    z-index: auto;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: $background-color-base;
    }
  }

  &--is-loading {
    .FormSelect__clear {
      display: none;
    }

    .select-search__value::after,
    .select-search__value:not(.is-loading):not(.select-search--multiple):after {
      top: calc(50% - 7.5px);
      width: 15px;
      height: 15px;
      background-image: url('../../style/global-icons/loading-spin.svg');
      background-size: 15px;
      border: none !important;
      margin: 0;
    }
  }

  &--has-value {
    .select-search__value::after {
      display: none;
    }
  }

  &__flyingLabel {
    position: absolute;
    top: .8rem;
    left: .4rem;
    opacity: 0;
    padding: 0 .4rem;
    background-color: $background-color-base;
    color: $color-grey;
    font-size: $font-size-mini;
    line-height: 1;
    transition: all 200ms;
  }

  &__hidden-field {
    display: none;
  }

  &--with-flying-label-opened {
    .FormSelect__flyingLabel {
      opacity: 1;
      transform: translate(0, -1.05rem);
      z-index: 1;
    }
  }

  &--is-invalid {
    .select-search__input {
      border-color: $color-danger;
    }
  }
}
