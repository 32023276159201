@import 'src/style/variables';

.Dropzone {
  width: 100%;

  &__content {
    background-color: $color-alabaster;
    display: flex;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect"
      + " width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23CCCCCCFF' stroke-width='3' stroke-dasharray='18 10'"
      + " stroke-dashoffset='6' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: $border-radius-base;
    width: 100%;
    height: $dropzone-height;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    &__text {
      text-align: center;
      color: $color-scorpion;
      width: 230px;
      margin: 0 auto;
      padding-bottom: $spacing-medium-plus;
    }

    &__filename {
      color: $color-scorpion;
      margin: 0 auto;
    }

    &__icon {
      max-width: 49px;
      max-height: 49px;
    }

    &--has-added-files {
      height: $dropzone-height-small;
    }
  }
}
