@import 'src/style/variables';

.MainDebtors {
  &__columns {
    &__name > a {
      font-size: $font-size-medium;
      font-weight: $font-weight-bolder;
      color: $color-mine-shaft;
    }

    &__ref,
    &__count {
      margin-top: $spacing-xs;
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      color: $color-scorpion;
    }

    &__in-progress {
      font-weight: $font-weight-bolder;
      color: $color-mine-shaft;
    }
  }

  .ListHistorySummary {
    display: flex;
    justify-content: flex-end;
  }

  .ListHistorySummary__content {
    text-align: left;
  }
}
