@import 'src/style/variables';

.ImportsHistory {
  .ErrorMessage {
    margin-bottom: $spacing-base;
  }

  &__columns {
    &__name {
      max-width: 400px;
      word-break: break-all;
    }

    &__ref {
      margin-top: $spacing-xs;
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      color: $color-primary;
    }
  }
}
