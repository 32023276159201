@import 'src/style/variables';

.PaymentRequestsToComplete {
  &__columns {
    &__unknown-data {
      padding: $spacing-xs $spacing-small;
      border: solid 1px $color-silver;
      color: $color-scorpion;
      border-radius: $border-radius-base;
    }

    &__client {
      font-weight: $font-weight-bold;

      > a {
        color: $color-mine-shaft;
      }
    }

    &__commands {
      display: flex;
      justify-content: flex-end;

      .Button + .Button {
        margin-left: $spacing-small;
      }
    }
  }
}
