@import 'src/style/variables';

.FormCheck {
  position: relative;
  font-weight: $font-weight-regular;
  line-height: 1;

  &__label {
    height: $checkbox-size;
    min-width: $checkbox-size;
    margin: 0;
    padding-left: calc(#{$radiobox-size} + #{$spacing-base});
    padding-top: .3rem;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      display: none;
      position: absolute;
      top: 1px;
      left: 0;
      width: $checkbox-size;
      height: $checkbox-size;
      border-radius: 3px;
      line-height: $checkbox-size;
    }

    &::before {
      background-color: $background-color-base;
      border: 1px solid $color-silver;
    }

    &::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 9'%3E%3Cpath fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M10 1L3.8125 8L1 4.81818'/%3E%3C/svg%3E");
      background-size: 45%;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $color-primary;
      border-color: $color-primary;
    }

    &:hover {
      &::before {
        border-color: $color-grey;
      }

      &::after {
        background-color: darken($color-primary, 5%);
        border-color: darken($color-primary, 5%);
      }
    }
  }

  &__input {
    width: 0;
    margin: 0;
    opacity: 0;

    + .FormCheck__label::before {
      display: block;
    }

    &:checked {
      & + .FormCheck__label::after {
        display: block;
      }
    }
  }

  &--disabled {
    .FormCheck__label {
      cursor: not-allowed;
    }

    .FormCheck__label,
    .FormCheck__label::before {
      border-color: $color-silver;
      color: $color-grey;
    }

    .FormCheck__label::after {
      opacity: .5;
    }
  }
}
