@import 'src/style/variables';

.PaymentRequestActionsCollapse {
  .Collapse {
    background: none;
    overflow: visible;

    &__header {
      padding: 0;

      &:hover {
        .SuggestCreateAction__warning {
          .Icon {
            color: $color-danger;
          }
        }
      }
    }

    &__content {
      border-top: none;
    }

    .BlockSection {
      display: flex;
      align-items: center;

      &__header {
        margin-bottom: 0;
      }
    }
  }

  &__list {
    position: relative;
    margin-bottom: $spacing-page-vertical;
    padding: 0;
    list-style: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 105px;
      width: 1px;
      background-color: $color-silver;
      z-index: -1;
    }

    &__step {
      display: flex;
      background-color: $color-white;
      margin: $spacing-small 0;

      &__badge {
        flex: 0 0 55px;
        display: block;
        margin-top: $spacing-large;
        margin-right: $spacing-base;
      }

      &__content {
        flex: 1;

        .HistoryStep {
          flex-direction: column;
          align-content: center;
          display: flex;

          &__infos {
            align-content: center;
            display: flex;
          }
        }
      }
    }
  }

  &__bottom-phrase {
    text-align: center;
    color: $color-grey;
    font-size: $font-size-mini;
    font-weight: $font-weight-bolder;
    text-transform: uppercase;
  }
}
