@import 'src/style/variables';

.FreshDesk {
  .Icon {
    margin-right: $spacing-base;
    color: $color-silver;
  }
}

#freshworks-container {
  display: none;
}
