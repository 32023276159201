@import 'src/style/variables';

.FormEavItem {
  &__option {
    text-transform: capitalize;
  }

  &--mandatory {
    .form-label::after {
      content: '*';
      color: $color-danger;
      font-size: $font-size-base;
      line-height: .1;
      font-weight: $font-weight-bolder;
      margin-left: $spacing-small;
    }
  }
}
