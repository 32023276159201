@import 'src/style/variables';

.PaymentRequests {
  position: relative;
  margin-bottom: $spacing-xxl;

  .ErrorMessage {
    margin-bottom: $spacing-base;
  }

  .DataTableHead__cell:last-child,
  .DataTableBody__cell:last-child {
    width: 1%;
    white-space: nowrap;
  }

  &__draft-link {
    position: absolute;
    height: $input-height-base;
    right: $spacing-page-horizontal;
    z-index: 1;

    .Icon {
      margin-right: $spacing-small;
    }
  }

  &__columns {
    &__reference {
      font-size: $font-size-base;
      font-weight: $font-weight-bolder;
      color: $color-mine-shaft;
      overflow-wrap: break-word;

      &__plan {
        margin-top: $spacing-xs;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        color: $color-primary;

        .Badge--group {
          margin-right: $spacing-small;
        }
      }
    }

    &__link,
    &__link:hover {
      color: $color-mine-shaft;
    }

    &__client {
      font-weight: $font-weight-bolder;

      > a {
        color: $color-mine-shaft;
      }

      &__ref {
        margin-top: $spacing-xs;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        color: $color-scorpion;
      }
    }

    &__note {
      text-align: right;
      padding: 0;
      margin-right: -$spacing-small;

      .NoteIcon {
        display: flex;

        .Icon {
          width: 19px;
          height: 19px;
        }
      }
    }
  }
}
