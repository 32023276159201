@import 'src/style/variables';

.BrandLogo {
  display: flex;

  .DunforceLogoSymbol,
  .DunforceLogoText {
    height: 30px;
  }

  .DunforceLogoSymbol__img,
  .DunforceLogoText__content {
    fill: $color-white;
  }
}
