@import 'src/style/variables';

.ScenarioDetails {
  .Loading {
    margin: $spacing-page-vertical $spacing-xxl;
  }

  .Modal {
    &__header {
      margin: 0 $spacing-xxl;
      padding: $spacing-xxl 0  $spacing-medium-plus;

      &__close {
        right: -$spacing-xxl;
      }
    }

    &__content {
      padding: 0;
    }

    &__title {
      margin-bottom: $spacing-small;
    }

    &__subtitle {
      margin-bottom: 0;
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      color: $color-scorpion;
    }
  }
}
