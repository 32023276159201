@import 'src/style/variables';
@import 'src/style/mixins';

.ReportHeader {
  display: flex;
  padding-bottom: $spacing-page-vertical;
  border-bottom: 1px solid $color-mercury;

  &__links {
    flex: 0 0 auto;
    align-self: flex-start;

    .Button {
      width: $link-back-btn-size;
      height: $link-back-btn-size;
      padding: 0;
    }
  }

  &__infos {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: $spacing-medium-plus;

    .Loading__content {
      justify-content: flex-start;
    }

    &__name {
      margin-bottom: $spacing-xs;
      font-size: $font-size-title;
      line-height: 1;
    }

    .Badge {
      margin-left: $spacing-base;
    }
  }
}
