@import 'src/style/variables';

.FormControl {
  display: block;
  width: 100%;
  height: $input-height-base;
  margin: 0;
  padding: $spacing-base $spacing-medium;
  border: 1px solid $color-silver;
  border-radius: $input-border-radius;
  background-color: $background-color-base;
  background-clip: padding-box;
  color: $input-text-color;
  font-size: $font-size-light;
  font-weight: $font-weight-medium;
  line-height: 1.5;

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:hover {
    border-color: $input-border-hover-color;

    &::placeholder {
      color: $input-placeholder-hover-color;
    }
  }

  &:focus {
    border-color: $input-border-focus-color;
    box-shadow: none;
    color: $input-text-focus-color;
    outline: 0;
  }

  &--textarea {
    height: auto;
  }

  &--not-valid {
    border-color: $color-danger;
  }

  &--warning {
    border-color: $color-warning;
  }

  &--disabled {
    background-color: $color-alabaster;
    color: $color-scorpion;
    cursor: not-allowed;

    &::placeholder {
      color: $color-silver;
    }

    &:hover {
      border-color: $color-silver;

      &::placeholder {
        color: $color-silver;
      }
    }
  }

  &--hidden {
    display: none;
  }
}
