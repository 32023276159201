@import 'src/style/variables';

.ImportsReportItemShowError {
  &__field {
    text-transform: capitalize;
    display: flex;
    font-size: $font-size-medium;
    margin-bottom: 0px;
    color: $color-danger;
  }
}
