@import 'src/style/variables';

.Layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  .Header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: 300ms;
    z-index: 2
  }

  &__main {
    position: relative;
    flex: 1;
    padding: $header-height 0 0;
  }

  &--no-header {
    .Layout__main {
      padding: 0;
    }
  }

  &--scrolling-down {
    .Header {
      top: -$header-height;
    }

    .CustomerHeader {
      top: 0;
    }
  }
}
