@import 'src/style/variables';

.PaymentRequestForm {
  &__helper {
    color: $color-grey;
    margin: $spacing-xs 0 0 $spacing-base;
    font-size: $font-size-medium;
  }

  &__check-help {
    color: $color-grey;
    margin: $spacing-base 0;
    margin-bottom: $spacing-large;
    font-size: $font-size-medium;

    &__information {
      margin-right: $spacing-xs;
    }

    &__link {
      display: inline-flex;
    }
  }

  &__check {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;

    & + & {
      margin-left: $spacing-base;
    }

    .ErrorValidationMessage {
      width: 100%;
    }

    .FormGroup__header__label {
      margin-bottom: 0;
    }
  }
}
