@import 'src/style/variables';
@import 'src/style/mixins';

.CustomerMainMenu {
  display: flex;
  justify-content: flex-start;
  background-color: $background-color-secondary;
  padding-left: $spacing-page-vertical;
  border-bottom: 1px solid $color-mercury;

  &__item {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 65px;
    padding: $spacing-small $spacing-medium-plus;
    color: $color-scorpion;
    font-size: $font-size-medium;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;

    &:hover {
      color: $color-black;
      text-decoration: none;
    }

    &__count {
      display: inline-block;
      width: 23px;
      height: 23px;
      margin-left: $spacing-small;
      border-radius: $border-radius-base;
      border: 1px solid $color-mercury;
      text-align: center;
      font-size: $font-size-medium;
      font-weight: $font-weight-bolder;
      color: $color-primary;
    }

    &.active {
      color: $color-black;
      border-color: $color-primary;

      .CustomerMainMenu__item__count {
        border-color: $color-primary;
      }
    }
  }

  @include media-min(desktop) {
    padding-left: $spacing-page-horizontal;
  }
}
