@import 'src/style/variables';

.FormRadios {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $input-height-base;

  .FormRadiosItem {
    margin-right: $spacing-medium-plus;
  }
}
