@import 'src/style/variables';

.PaymentRequestContentGrouped {
  padding: 0 $spacing-page-vertical $spacing-page-horizontal $spacing-page-horizontal;

  &__actions {
    display: flex;
    align-items: center;
    padding: $spacing-large $spacing-base;
    border-top: 1px solid $color-mercury;

    &__title {
      flex: 1;
      font-size: $font-size-subtitle;
    }

    &__links {
      flex: 0 0 auto;

      .Icon {
        height: 8px;
        margin-left: $spacing-small;
      }
    }
  }

  .PaymentRequestInfos,
  .PaymentRequestActionsCollapse {
    border-top: 1px solid $color-mercury;
  }

  .BlockSection {
    margin: $spacing-large 0;
  }
}
