@import 'src/style/variables';

.PossibleValuesItem {
  margin: -$spacing-base 0;

  &__wrapper {
    display: flex;
    flex-direction: column;

    &__form {
      display: flex;
      align-items: center;
    }
  }

  .Button {
    margin: 0 0 0 $spacing-base;
    height: 100%;
  }
}
