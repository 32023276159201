@import 'src/style/variables';
@import 'src/style/mixins';

.ImportsFileWizardNav {
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: $background-color-dark;
  height: $header-height;
  width: 100%;

  .Alert {
    display: none;
    align-items: center;
    min-width: 175px;
    margin: $spacing-base;
    padding: $spacing-xs $spacing-base;
    background-color: $color-warning;

    @include media-min(desktop) {
      display: flex;
    }
  }

  &__button-prev,
  &__button-next {
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    display: flex;

    &__link {
      padding-left: $spacing-medium;
      padding-right: $spacing-medium;
      display: flex;
      justify-content: space-between;
      width: 134px;
    }

    .Button--disabled {
      background-color: $color-scorpion;
      color: $color-silver;
    }
  }

  &__button-next {
    margin-right: $spacing-medium;
  }

  &__button-prev {
    margin-left: $spacing-medium;
    justify-content: flex-start;
  }
}
