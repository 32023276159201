@import 'src/style/variables';

.PaymentsRowActions {
  display: flex;
  justify-content: flex-end;

  .Dropdown__menu {
    font-size: $font-size-light;
  }
}
