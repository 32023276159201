@import 'src/style/variables';

.DataTableActiveFilters {
  display: flex;
  align-items: center;
  margin-top: $spacing-base;

  &__item {
    display: flex;
    align-items: center;
    padding: $spacing-xs $spacing-small;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    background-color: $color-alabaster;
    border-color: $color-alabaster;
    color: $color-mine-shaft;
    line-height: 1;
    border-radius: $border-radius-small;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: $color-mercury;
      border-color: $color-mercury;
    }

    .Icon {
      margin-left: $spacing-small;
      color: $color-grey;
    }

    &--not-removable {
      cursor: default !important;

      &:hover,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active:focus {
        background-color: $color-alabaster !important;
        border-color: $color-alabaster !important;
      }
    }
  }

  &__item + &__item {
    margin-left: $spacing-xs;
  }
}
