@import 'src/style/variables';

.PayRequestBulkActions {
  display: flex;
  align-items: center;

  .Loading {
    color: $color-white;
    font-size: $font-size-base;
  }

  &--loading {
    .BulkActionsToggle,
    .BulkActionPromiseToPay {
      display: none;
    }
  }
}
