@use "sass:math";
@import 'src/style/variables';

.CustomerActions {
  display: flex;
  flex-direction: column;

  .Collapse {
    background: none;
    overflow: visible;
    border-radius: 0;
    border-bottom: 1px solid $color-mercury;

    &__header {
      padding: $spacing-xxl 0;
    }

    &__content {
      border-top: none;
    }

    .BlockSection {
      display: flex;
      align-items: center;
      margin: 0;

      &__header {
        flex: 1;
        margin: 0;
      }

      .Loading {
        flex: 1;
        font-size: 1rem;
      }
    }
  }

  &__link {
    margin-right: $spacing-large;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
  }

  &__list {
    flex: 1;
    padding: 0;
  }

  .HistoryStep + .HistoryStep {
    margin-top: $spacing-base;
  }

  &__no-data {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: $spacing-base;
    color: $color-grey;
    font-size: $font-size-medium;
  }
}
