@import '~react-calendar/dist/Calendar.css';

.react-calendar {
  &__tile {
    color: $color-mine-shaft;

    &:enabled:hover,
    &:enabled:focus {
      background-color: darken($background-color-base, 3%);
    }

    &--now {
      background-color: lighten($color-warning, 12%);

      &:enabled:hover,
      &:enabled:focus {
        background-color: $color-warning;
      }
    }

    &:disabled {
      opacity: .6;
      cursor: not-allowed;
    }

    &--active {
      background-color: $color-primary;
      color: $color-white;

      &:enabled:hover,
      &:enabled:focus {
        background-color: darken($color-primary, 5%);
      }

      &:disabled {
        background-color: $color-primary;
      }
    }
  }

  &__month-view__days__day--weekend {
    color: $color-danger;
  }
}
