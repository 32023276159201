@import 'src/style/variables';

.NoOrganizationSelectedItem {
  $block: &;

  display: flex;
  align-items: center;
  margin-bottom: $spacing-small;
  padding: $spacing-base;
  background-color: $color-alabaster;
  list-style: none;
  cursor: pointer;

  &__logo {
    flex: 0 0 35px;
    height: 35px;
    margin-right: $spacing-base;
  }

  &__name {
    flex: 1;
    font-size: $font-size-light;
    text-transform: uppercase;
  }

  &__demo {
    font-weight: $font-weight-bolder;
    color: $color-primary;
    text-transform: uppercase;
    margin-left: $spacing-base;
  }

  &__button {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding: 0 $spacing-base;
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: $color-silver;
  }

  &--no-logo {
    #{$block}__logo {
      background-color: $color-primary;
      border-radius: 50%;
    }
  }
}
