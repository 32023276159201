@import 'src/style/variables';

.SelectorsFormCondition {
  &__attribute-type {
    width: 50%;
    padding-right: $spacing-base;

    &--selected {
      width: 100%;
      padding-right: 0;
    }
  }

  &__sliding-date {
    $sliding-date-form-height: 30px;
    margin-top: $spacing-small;
    flex: 1;
    margin-left: $spacing-large;
    display: flex;
    flex-wrap: wrap;

    &__between {
      padding: $spacing-small;
      font-size: $font-size-small;
      color: $color-grey;
      height: $sliding-date-form-height;
      display: flex;
      align-items: center;
    }

    .FormControl,
    .FormSelect {
      display: flex;
      width: 80px;
      height: $sliding-date-form-height;

      .select-search__input {
        height: $sliding-date-form-height;
      }
    }

    .InputGroup {
      align-items: flex-start;

      &__helper {
        height: $sliding-date-form-height;
        display: flex;
        padding: 0;
        width: 45px;
        font-size: $font-size-small;
        color: $color-grey;
      }

      .FormControl {
        width: 46px;
      }
    }

    .FormControl {
      padding: $spacing-xs;
    }

    .FormSelect {
      margin-right: $spacing-base;
    }
  }
}
