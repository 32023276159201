@import 'src/style/variables';

.ImportsReportInfos {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  padding: $spacing-large $spacing-xl $spacing-large 0;
  border-bottom: 1px solid $color-mercury;

  &__section {
    display: flex;
    flex: 2;
    flex-direction: column;

    &__header {
      font-size: $font-size-medium;
    }

    &__title {
      font-size: $font-size-subtitle;
    }

    &__status {
      font-size: $font-size-subtitle;
      color: $color-primary;
    }
  }

  &--warning {
    .ImportsReportInfos {
      &__section {
        &__status {
          color: $color-warning;
        }
      }
    }
  }

  &--danger {
    .ImportsReportInfos {
      &__section {
        &__status {
          color: $color-danger;
        }
      }
    }
  }
}
