@import 'src/style/variables';

.Wysiwyg {
  &__toolbar {
    align-items: center;
    margin-bottom: 0 !important;
    padding: $spacing-mini $spacing-xs !important;
    background: $color-alabaster !important;
    border: 1px solid $color-silver !important;
    border-radius: $border-radius-base $border-radius-base 0 0 !important;
    border-bottom: none !important;
    font-size: 1rem !important;

    &__dropdown {
      min-width: 50px !important;
      min-height: 34px;

      .rdw-dropdown-optionwrapper {
        overflow-y: auto !important;
      }

      &:hover {
        box-shadow: none !important;
        background: $color-mercury !important;
      }
    }

    &__button,
    .rdw-option-wrapper {
      margin: 0 !important;
      min-width: 34px !important;
      min-height: 34px;
      border-radius: $btn-border-radius;
    }

    .rdw-option-wrapper:hover {
      box-shadow: none;
      background: $color-mercury;
    }

    .rdw-option-active {
      box-shadow: none;
      background: $background-color-active-item !important;
      border: 1px solid $color-primary;
    }

    .rdw-history-wrapper,
    .rdw-fontsize-wrapper,
    .rdw-inline-wrapper,
    .rdw-link-wrapper,
    .rdw-colorpicker-wrapper,
    .rdw-remove-wrapper,
    .rdw-dropdown-wrapper {
      margin-right: $spacing-small !important;
      margin-bottom: 0;
      border-radius: $btn-border-radius;
    }

    .rdw-colorpicker-modal {
      height: auto;
    }

    .rdw-colorpicker-modal-options {
      overflow: auto;
    }

    .rdw-colorpicker-option {
      box-shadow: none;
      border: none;
      border-radius: $btn-border-radius;
    }
  }

  &__editor {
    min-height: 210px;
    resize: vertical;
    padding: $spacing-medium;
    background: $color-white;
    border: 1px solid $color-silver;
    border-radius: 0 0 $input-border-radius $input-border-radius;

    .DraftEditor-editorContainer,
    .DraftEditor-root,
    .public-DraftEditor-content {
      height: auto;
    }

    .public-DraftStyleDefault {
      &-block {
        margin: 0;
        padding-bottom: $spacing-base;
      }

      &-ul,
      &-ol {
        margin-top: 0;
        margin-bottom: $spacing-base;
      }

      &-unorderedListItem,
      &-orderedListItem {
        .public-DraftStyleDefault-block {
          padding-bottom: 0;
        }
      }
    }
  }

  &__hidden-field {
    display: none;
  }

  &__tags {
    max-height: 300px;
  }
}
