@import 'src/style/variables';
@import 'src/style/mixins';

.HeaderNavItem {
  display: flex;
  position: relative;

  &__link {
    flex: 1;
    display: flex;
    align-items: center;
    padding: $spacing-base;
    color: $color-header-link;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    transition: all .2s ease-in-out;
    white-space: nowrap;

    &__tooltip {
      padding: $spacing-base;
      display: flex;
      max-width: 350px;
    }

    &:hover {
      color: $color-header-link-hover;
      text-decoration: none;
    }
  }

  &__notification {
    position: absolute;
    right: $spacing-xs;
    top: $spacing-large;
    color: $color-secondary;
    height: 5px;
    width: 5px;
  }

  &__active-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: $header-active-item-indicator-width;
    height: 100%;
    background: linear-gradient(180deg, $color-secondary 0%, $color-primary 100%);
  }

  &--active {
    .HeaderNavItem__link {
      color: $color-header-link-active;

      &:hover {
        color: $color-header-link-hover;
      }
    }
  }

  &--disabled {
    .HeaderNavItem__link {
      color: $color-grey;
      cursor: not-allowed;
    }
  }

  @include media-min(desktop) {
    &__link {
      padding: 0 $spacing-small;
    }

    &__active-indicator {
      width: 100%;
      height: $header-active-item-indicator-width;
      background: linear-gradient(90deg, $color-secondary 0%, $color-primary 100%);
    }
  }

  @include media-min(medium-desktop) {
    &__link {
      padding: 0 $spacing-base;
    }
  }
}
