@import 'src/style/variables';

.FormPerimeterItem {
  position: relative;
  width: 100%;

  .FormFieldset {
    width: 100%;
  }

  &__remove {
    position: absolute;
    top: 39px;
    left: -$spacing-large;
    color: $color-scorpion;

    &:hover {
      color: $color-mine-shaft;
    }
  }
}
