@import 'src/style/variables';
@import 'src/style/mixins';

.CustomerHeader {
  position: sticky;
  top: $header-height;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: $spacing-page-vertical $spacing-page-horizontal $spacing-xl;
  background-color: $color-white;
  border-bottom: 1px solid $color-mercury;
  transition: 300ms;

  &__links {
    flex: 0 0 auto;
    align-self: flex-start;

    .Button {
      width: $link-back-btn-size;
      height: $link-back-btn-size;
      padding: 0;
    }
  }

  &__infos {
    margin-left: $spacing-medium-plus;

    .Loading__content {
      justify-content: flex-start;
    }

    &__name {
      margin-bottom: $spacing-xs;
      font-size: $font-size-title;
      line-height: 1;
    }

    &__reference {
      color: $color-scorpion;
      font-weight: $font-weight-bold;
    }

    &__scenario {
      display: inline;
      margin-left: $spacing-base;
      font-weight: $font-weight-bold;
    }
  }

  &__mute {
    flex: 1;
    align-self: flex-start;

    .CustomerMuteButtonUnMute {
      margin: 0 $spacing-medium;
      padding: $spacing-xs;

      .Icon {
        width: 26px;
        height: 26px;
        max-width: 26px;
        max-height: 26px;
      }
    }
  }

  &__totals {
    margin-right: $spacing-page-vertical;
    text-align: right;

    &__total {
      font-size: $font-size-large;
      margin: 0;
    }

    &__balance {
      color: $color-scorpion;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
    }

    &__amount {
      color: $color-primary;
      margin-left: $spacing-small;
    }
  }
}
