@import 'src/style/variables';

.HistoryStepContactMissingRoles {
  &__missing-roles {
    &__item {
      text-transform: lowercase;
      display: inline-block;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}
