@import 'src/style/variables';

.InputGroup {
  display: flex;
  align-items: center;

  &__helper {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: $input-height-base;
    margin: 0;
    border: 1px solid $color-silver;
  }

  .FormControl {
    flex: 1;
    width: 100%;
    height: $input-height-base;
  }

  &:hover {
    .InputGroup__helper,
    .FormControl {
      border-color: $input-border-hover-color;
    }
  }

  &:focus-within {
    .InputGroup__helper,
    .FormControl {
      border-color: $input-border-focus-color;
    }
  }

  &--helper-left {
    .InputGroup__helper {
      padding: 0 $spacing-small 0 $spacing-medium;
      border-right: none;
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .FormControl {
      padding-left: $spacing-xs;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }

  &--helper-right {
    .InputGroup__helper {
      padding: 0 $spacing-medium 0 $spacing-small;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }

    .FormControl {
      padding-right: $spacing-xs;
      border-right: none;
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &--not-valid {
    .InputGroup__helper {
      border-color: $color-danger;
    }
  }
}
