@import 'src/style/variables';

.DataTable {
  position: relative;

  &__header {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: $spacing-xl;

    &__status-selector {
      flex: 0 1 197px;
      margin-right: $spacing-small;

      .select-search__input {
        height: $input-height-small;
      }
    }

    &__search {
      flex: 0 1 305px;
    }

    &__actions {
      display: flex;
      align-items: center;
      padding: 0 $spacing-base * 2;
      height: $input-height-small;
      font-size: $font-size-small + 0.072rem;
      font-weight: $font-weight-bold;

      .Icon {
        margin-right: $spacing-xs;
      }
    }

    .DataTableActiveFilters {
      flex: 0 0 100%;
    }
  }

  &__table-wrapper {
    position: relative;
    margin-bottom: $spacing-medium + $spacing-xs;

    .Loading {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
    }
  }

  &__table {
    width: 100%;
    margin-bottom: $spacing-base;
    border-collapse: collapse;
    color: $color-mine-shaft;
  }

  &__no-data {
    margin: $spacing-xl 0;
    color: $color-grey;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    text-align: center;
  }

  &--no-header {
    margin-top: $spacing-medium-plus;
    border-top: 1px solid $color-mercury;
  }

  &--no-actions {
    .DataTableHead__cell:first-child,
    .DataTableBody__cell:first-child {
      width: auto;
      padding-left: $spacing-base;
    }
  }
}
