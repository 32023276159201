@import 'src/style/variables';

.CustomerMuteButtonMute {
  $block: &;

  &--with-text {
    #{$block}__icon {
      margin-right: $spacing-small;
    }
  }
}
