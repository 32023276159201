@import 'src/style/variables';

.ReportsDetailsCounts {
  display: flex;
  align-items: center;

  .ReportsCount + .ReportsCount {
    margin-left: $spacing-xs;
  }
}
