@import 'src/style/variables';

.SelectGroupCheckAll {
  margin-bottom: $spacing-small;

  &--is-alone {
    margin-bottom: $spacing-large;
  }

  &__title {
    text-transform: uppercase;
    color: $color-grey;
    font-family: $font-family-base;
    font-weight: $font-weight-bolder;
    font-size: $font-size-mini;
    line-height: 1.19;
    margin-bottom: 10px;
  }

  &__checkbox {
    padding-top: $spacing-medium;
    border-radius: 6px;
    background-color: $background-color-base;

    &__check {
      display: flex;
      padding: 0 $spacing-large $spacing-medium $spacing-large;
      align-items: center;
      border-bottom: 2px solid $color-alabaster;

      &__title {
        margin-bottom: 0;
      }

      &__subtitle {
        margin-bottom: 0;
        color: $color-grey;
        font-size: $font-size-medium
      }

      .Button {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        padding-right: 0;

        &:hover,
        &:focus {
          background: none;
        }
      }
    }

    &__name {
      font-size: $font-size-medium;
      font-weight: bold;
      color: $color-grey;
      padding: $spacing-large $spacing-large 0 $spacing-large;
      text-transform: uppercase;
    }

    &__items {
      padding: 0 $spacing-large $spacing-medium-plus $spacing-large;
    }
  }
}
