@use 'sass:math';
@import 'src/style/variables';

.CustomerLastActivities {
  &__header {
    display: flex;
    align-items: baseline;
    margin-bottom: $spacing-medium-plus;

    .Loading {
      align-self: center;
      margin-left: $spacing-small;
    }
  }

  &__title {
    flex: 0 0 auto;
    font-size: $font-size-subtitle;
    font-weight: $font-weight-bolder;
    margin: 0;
  }

  &__count {
    flex: 0 0 auto;
    color: $color-primary;
    font-size: $font-size-base;
    margin-left: $spacing-small;
  }

  &__link {
    flex: 1;
    text-align: right;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;

    &__icon {
      margin-left: math.div($spacing-base, 2);
    }
  }

  &__no-data {
    color: $color-grey;
    font-size: $font-size-medium;
  }

  &__list {
    margin: 0;
    padding: 0;
  }
}
