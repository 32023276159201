@import 'src/style/variables';

.HomeStats {
  &__group {
    display: flex;
    margin-bottom: $spacing-medium;

    .StatBlock {
      flex: 1;
    }

    .StatBlock + .StatBlock {
      margin-left: $spacing-base;
    }

    &--with-tooltip {
      .StatBlock:hover {
        .StatBlock__content {
          border-color: $color-grey;
        }
      }
    }
  }

  &__recovered.StatBlock {
    flex: 2;
    min-height: 160px;
  }

  &__value {
    font-size: $font-size-important;
    margin: 0;
    line-height: 1;

    &--positive,
    &--negative {
      font-size: $font-size-base;
      margin-left: $spacing-base;
    }

    &--positive {
      color: $color-success;
    }

    &--negative {
      color: $color-danger;
    }
  }

  &__subTitle {
    margin-top: $spacing-base;
    font-size: $font-size-light;
    font-weight: $font-weight-bold;
    color: $color-grey;

    &__value {
      color: $color-black;
      font-size: $font-size-base;
      margin: 0 5px;
    }
  }
}
