@import 'src/style/variables';

.CustomerEditForm {
  &__tooltip {
    padding: $spacing-base;
  }

  &__civility {
    max-width: 70px;
  }

  &__address3 {
    max-width: calc(50% - #{$spacing-base});
  }

  &__postal-code {
    max-width: 100px;
  }

  &__help {
    margin-top: .2rem;
    color: $color-grey;
    font-size: $font-size-small;
  }

  &__attachment,
  &__grouped-reminder {
    align-items: center;
    color: $color-grey;
    flex-direction: row;
    margin-left: $spacing-xxl;

    .FormCheck {
      font-weight: $font-weight-bold;
    }

    .Icon {
      margin: $spacing-base;
    }
  }
}
