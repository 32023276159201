@import 'src/style/variables';
@import '../index.scss';

.PayerSummary {
  width: $side-width;
  position: fixed;
  height: 100%;
  right: 0;
  background-color: $background-color-dark;
  background-image: url('../../../style/images/BG-login.jpg');
  background-size: cover;
  background-position: center;
  padding-top: $spacing-large;
  justify-content: flex-end;

  &__content {
    overflow-x: auto;
    margin-top: $spacing-large;
    height: 80%;
    background: linear-gradient(180deg, $color-black 0%, rgba($color-black, 0) 100%);
    padding: $spacing-page-vertical;
    color: $color-white;

    &__title {
      font-size: $font-size-subtitle;
      margin-bottom: $spacing-medium-plus;
    }

    &__separator {
      width: 100%;
      height: 1px;
      background-color: $color-mine-shaft;
      border: 0;
      margin: $spacing-base 0;
    }

    &__invoice {
      display: flex;
      justify-content: space-between;

      &__reference {
        font-size: $font-size-medium;
        color: $color-silver;
      }

      &__amount {
        font-weight: $font-weight-bolder;
      }
    }

    &__total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: $spacing-medium-plus;

      &__text {
        margin: 0;
        color: $color-silver;
        font-size: $font-size-medium;
      }

      &__amount {
        font-weight: $font-weight-bolder;
        font-size: $font-size-title;
        margin: 0;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-top: $spacing-xl;

      .Button {
        margin-bottom: $spacing-base;
        padding: $spacing-base $spacing-xl;

        &--outline-light {
          color: $color-silver;
          border-color: $color-silver;

          &:hover {
            color: $color-white;
            border-color: $color-white;
          }
        }
      }
    }

    &__language {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 20px;

      .select-search__select {
        top: -$input-height-base * 3 - $spacing-base * 2;
      }

      .select-search__input {
        border: none;
        background-color: transparent;
        color: $color-white;
        height: min-content;
      }

      .FormSelect--has-value {
        .select-search__value::after {
          display: block;
          right: 100px;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__text {
      margin: 0 $spacing-base;
      color: $color-white;
      font-weight: $font-weight-bolder;
      font-size: $font-size-mini;
      text-transform: uppercase;
    }

    .BrandLogo {
      margin-right: $spacing-large;
    }
  }
}
