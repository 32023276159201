@import 'src/style/variables';

.LinkSwitch {
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 0;
  background-color: $color-mercury;
  border: 2px solid $color-mercury;
  border-radius: $border-radius-base;

  &__button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-small $spacing-medium;
    color: $color-grey;
    line-height: 1.2;
    cursor: pointer;
  }

  .Button--disabled {
    background-color: $color-mercury;
  }

  &__active-button {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-small $spacing-medium;
    background-color: $background-color-base;
    font-weight: $font-weight-bold;
    color: $color-scorpion;
    border-radius: $border-radius-base;
    line-height: 1.2;
    transition: 150ms;
    cursor: pointer;
  }
}
