@import 'src/style/variables';

.PaymentRequestDiffDays {
  &--due {
    color: $color-warning;
  }

  &--not-due {
    color: $color-primary;
  }

  &--inactive {
    color: $color-silver;
  }
}
