@import 'src/style/variables';

.HistoryStepDetails {
  padding-top: $spacing-xl;

  &__infos {
    border-top: 1px solid $color-mercury;
    padding-top: $spacing-large;
  }

  &__infos + &__infos,
  &__history {
    margin-top: $spacing-large;
  }

  &__actions {
    display: flex;
    margin-left: 180px;
    padding-top: $spacing-large;

    .Button + .Button {
      margin-left: $spacing-base;
    }
  }
}
