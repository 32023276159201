@import 'src/style/variables';

.PlanDetailsInfo {
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid $color-mercury;
  padding: $spacing-base $spacing-large;

  &__group {
    display: flex;

    &__title {
      margin: $spacing-base;
      font-size: $font-size-light;
      flex: 1;
      align-items: center;
      display: flex;
    }

    &__container {
      display: flex;
      flex-direction: column;
      flex: 4;
      margin: $spacing-base 0;
      color: $color-scorpion;

      &__plan-change {
        display: flex;
        justify-content: space-between;

        &__no-data {
          margin: 0;
          color: $color-scorpion;
        }

        &__badge {
          margin: 0;
          background-color: $color-alabaster;
          border-radius: $border-radius-base;
          justify-self: flex-end;
          flex-shrink: 0;
          display: flex;
          align-content: center;
          padding: $spacing-xs $spacing-small;
        }

        &__new-plan {
          margin-bottom: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
