@import 'src/style/variables';

.CustomerStats {
  display: flex;

  .CustomerStatsDebt,
  .CustomerStatsTodos  {
    flex: 1;
    max-width: 50%;
  }

  .CustomerStatsTodos {
    margin-left: $spacing-base;
  }
}
