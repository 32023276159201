@import 'src/style/variables';

.Home {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .HomeHeader {
    flex: 0 0 auto;
  }

  .Loading {
    margin: $spacing-large 0 0;
    font-size: $font-size-subtitle;
    text-align: center;
    text-transform: uppercase;
  }

  .HomeContent {
    flex: 1;
  }
}
