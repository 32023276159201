@import 'src/style/variables';

.HomeHistoryItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacing-medium;
  list-style: none;

  &__date {
    min-width: 80px;
    padding-right: $spacing-base;
    color: $color-black;
    font-size: $font-size-light;
    font-weight: $font-weight-bolder;
  }

  &__content {
    &__title {
      font-size: $font-size-light;
      font-weight: $font-weight-bolder;
    }

    &__link {
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
    }
  }
}
