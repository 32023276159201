@use 'sass:math';
@import 'src/style/variables';

.CustomerAttributes {
  &__header {
    display: flex;
    align-items: baseline;
    margin-bottom: $spacing-medium-plus;
  }

  &__title {
    flex: 0 0 auto;
    font-size: $font-size-subtitle;
    font-weight: $font-weight-bolder;
    margin: 0;
  }

  &__link {
    flex: 1;
    text-align: right;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;

    &__icon {
      margin-left: math.div($spacing-base, 2);
    }
  }

  &__item {
    display: flex;
    margin-bottom: $spacing-base;
  }

  &__label {
    flex: 0 0 96px;
    line-height: 1.8;
    font-size: $font-size-medium;
    font-weight: $font-weight-bolder;
    color: $color-mine-shaft;
    margin-right: $spacing-xs;

    &--capitalize {
      text-transform: capitalize;
    }
  }

  &__value {
    flex: 1;
    margin: 0;
    line-height: 1.8;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    color: $color-scorpion;
  }
}
