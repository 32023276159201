@import 'src/style/variables';

.DataTableFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: $font-size-medium;
  color: $color-scorpion;
  z-index: 1;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-medium $spacing-large;
    background-color: $color-primary;
  }

  &__selected-count {
    flex: 0 0 auto;
    color: $color-white;
  }
}
