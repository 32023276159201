@import 'src/style/variables';

.CustomerAccountDetailsStatusPaymentCell {
  display: inline-block;
  font-size: $font-size-medium;
  font-weight: $font-weight-medium;
  color: $color-scorpion;

  &__tooltip {
    padding: $spacing-xs $spacing-base;
    font-size: $font-size-small;
  }

  &__imputations-title {
    margin-bottom: $spacing-base;
  }

  &__imputations-list {
    padding: 0;
    list-style: none;
  }

  &--imputed {
    cursor: pointer;
    padding: $spacing-small 0;

    &:hover {
      color: $color-primary;
      text-decoration: underline;
    }
  }
}
