@import 'src/style/variables';

.ImportsHistoryRowActions {
  display: flex;
  justify-content: flex-end;

  .Dropdown__toggle {
    padding: $spacing-xs $spacing-small;
    height: 35px;
  }

  .Dropdown__menu {
    font-size: $font-size-light;
  }

  &__download-link {
    flex: 1 1;
    align-self: center;
  }
}
