@import 'src/style/variables';

.ListItemButton {
  display: flex;
  overflow: auto;
  margin: 0 0 $spacing-small;
  background-color: $background-color-base;
  border-radius: $border-radius-small;
  font-size: $font-size-small;
  cursor: pointer;

  &__body {
    flex: 1;
    padding: $spacing-medium 0 $spacing-medium $spacing-medium-plus;
  }

  &__button {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding: 0 $spacing-base;
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: $color-silver;
  }

  &:hover {
    .ListItemButton__button {
      color: $color-grey;
    }
  }
}
