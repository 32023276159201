@import 'src/style/variables';

.ModalRolesForm {
  .FormFieldset {
    display: flex;
    flex-wrap: wrap;

    .FormGroup {
      flex: 0 0 200px;
      margin-right: $spacing-base;
    }

    .FormGroup + .FormGroup {
      margin-left: 0;
    }
  }
}
