@import 'src/style/variables';

.ImportsFileWizardStepChoiceType {
  display: flex;
  flex-flow: wrap;
  align-content: space-between;
  justify-content: space-between;
  margin-bottom: $spacing-page-horizontal;

  .Button--link {
    color: $color-black;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .Button {
    align-items: normal;
    justify-content: normal;
    text-align: left;
  }
}
