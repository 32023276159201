@import 'src/style/variables';

.DataTableBody {
  $max-width-cell: 250px;
  $color-background-selected: #fcfcfc;

  &__row {
    background-color: rgba($background-color-base, .5);

    &--selected {
      background-color: $color-background-selected;
    }
  }

  &__cell {
    padding: ($spacing-medium + $spacing-xs) 0;
    vertical-align: middle;
    border-top-width: 0;
    border-bottom: 1px solid $color-mercury;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    line-height: 1.4;
    color: $color-scorpion;
    max-width: $max-width-cell;

    &--selected {
      background-color: $color-background-selected;
      border-bottom: 1px solid $color-mercury;
    }

    &:first-child::before,
    &:last-child::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -1px;
      width: $spacing-page-horizontal;
      display: block;
      background-color: inherit;
      border: 0;
    }

    &:first-child {
      width: 50px;
      position: relative;

      &::before {
        right: 100%;
      }
    }

    &:last-child {
      text-align: right;
      position: relative;

      &::after {
        left: 100%;
      }
    }

    &:not(:first-child):not(:last-child) {
      padding-right: $spacing-small;
    }
  }
}
