@import 'src/style/variables';
@import 'src/style/mixins';

.DataTableFilters {
  &__open-button {
    font-size: $font-size-small + 0.072rem;
    height: $input-height-small;

    .Icon {
      margin-right: $spacing-xs;
      color: $color-primary;
    }
  }

  &__modal {
    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &__filter {
        display: flex;
        width: calc(50% - (#{$spacing-xs * 2}));

        &--full-width {
          width: calc(100% - (#{$spacing-small * 2}));
        }
      }

      .DataTableFiltersItem {
        width: 100%;
        margin: $spacing-small;
      }
    }

    &__footer {
      @extend %modal-footer;
    }
  }
}
