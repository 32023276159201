@import 'src/style/variables';

.DataTableFiltersItem {
  .form-control,
  .form-group,
  .react-date-picker__wrapper,
  .select-search__input {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    color: $color-mine-shaft;

    &::placeholder {
      color: $color-mine-shaft;
      font-weight: $font-weight-bold;
    }
  }

  .select-search__select {
    top: calc(#{$input-height-base} + #{$spacing-xs});
  }

  .react-date-picker {
    background-color: $background-color-base;
  }

  &__children {
    width: 283px;
    margin-top: $spacing-base;
    padding-left: $spacing-medium;

    .DataTableFiltersItem  {
      margin-bottom: $spacing-base;
    }
  }

  &:hover {
    .react-date-picker__inputGroup__input {
      color: $color-mine-shaft;
    }

    .react-date-picker__wrapper {
      border-color: $color-scorpion;
    }

    .Icon {
      color: $color-scorpion;
    }
  }

  .react-date-picker__inputGroup__input {
    color: $color-scorpion;
  }

  .Icon {
    color: $color-grey;
  }
}

