@import 'src/style/variables';

.NoteIcon {
  color: $color-primary;
  cursor: pointer;

  &__tooltip {
    width: 200px;
    padding: $spacing-small $spacing-base;
    font-size: $font-size-small;

    &__header {
      margin-bottom: $spacing-xs;
      font-weight: $font-weight-bold;
      border-radius: $border-radius-base $border-radius-base 0 0;
      color: $color-scorpion;
    }

    &__content {
      font-weight: $font-weight-medium;
      color: $color-grey;
    }
  }
}
