@use "sass:math";
@import 'src/style/variables';

.FormEavs {
  &__title {
    font-size: $font-size-subtitle;
    font-weight: $font-weight-medium;
    margin: $spacing-medium 0;
    padding-bottom: math.div($spacing-medium, 2);
    border-bottom: 1px solid $color-silver;
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;

    .FormEavItem {
      flex: 1;
      min-width: 300px;
      max-width: calc(50% - #{math.div($spacing-large, 2)});
      margin-right: $spacing-large;
    }
  }
}
