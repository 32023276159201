@import 'src/style/variables';
@import 'src/style/mixins';

.Confirm {
  $modal-confirm-width: 726px;

  .Modal {
    &__wrapper {
      padding-top: calc(#{$header-height} + #{$spacing-page-vertical});
    }

    &__page {
      max-width: $modal-confirm-width;
    }
  }

  &__text {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    margin-bottom: $spacing-base;
    white-space: pre-wrap;
  }

  &__entity-name {
    font-size: $font-size-subtitle;
    font-weight: $font-weight-bolder;
    line-height: 1.4;
    margin: 0;
    color: $color-mine-shaft;
  }

  &__footer {
    @extend %modal-footer;

    .Alert {
      width: 100%;
      background-color: $color-warning;
    }
  }
}
