@use "sass:math";
@import 'src/style/variables';

.SearchResultPayRequests {
  padding: 0;
  margin: 0;

  &__item,
  &__link-all {
    list-style: none;
    font-size: $font-size-light;
    font-weight: $font-weight-bold;
    line-height: 1.22;

    &__link {
      display: block;
      padding: math.div($spacing-base, math.div(1 + 1, 3)) $spacing-xl;
    }
  }

  &__item {
    &__link {
      font-weight: $font-weight-medium;
      color: $color-scorpion;

      &:hover {
        text-decoration: none;
        color: $color-scorpion;
        background-color: $color-alabaster;
      }
    }

    &:last-child {
      .SearchResultPayRequests__item__link {
        padding-bottom: $spacing-base;
      }
    }
  }
}
