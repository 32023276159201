@import 'src/style/variables';

.Customer {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .CustomerMain {
    flex: 1;
  }
}
