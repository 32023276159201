@import 'components/FormControl';

.select-search {
  position: relative;

  &__value {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: $spacing-medium;
      transition: transform 300ms;
    }
  }

  &__input {
    @extend .FormControl;

    text-align: left;
    padding: 0 $spacing-medium;
    font-weight: $font-weight-bold;

    &[readonly] {
      background-color: $background-color-base;
    }

    &:not([readonly]):focus {
      cursor: initial;

      &::placeholder {
        color: transparent;
        opacity: 0;
      }
    }
  }

  &:not(.is-disabled) {
    .select-search__input {
      cursor: pointer;
    }
  }

  &__select {
    position: absolute;
    top: calc(#{$input-height-small} + #{$spacing-xs});
    width: 100%;
    min-width: 280px;
    max-height: 280px;
    overflow: auto;
    padding: $spacing-base 0;
    background-color: $background-color-base;
    font-size: $font-size-light;
    border: none;
    border-radius: $border-radius-large;
    box-shadow: $dropdown-box-shadow;
    z-index: 1;
  }

  &__options {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__row:not(:first-child) {
    border: none;
  }

  &__option {
    display: flex;
    align-items: center;
    min-height: $input-height-base;
    width: 100%;
    padding: 0 $spacing-medium;
    background-color: $background-color-base;
    color: $color-scorpion;
    font-weight: $font-weight-bold;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: $color-alabaster;
      color: $color-mine-shaft;
      text-decoration: none;
    }

    &.is-selected {
      background-color: $background-color-active-item;
      color: $color-mine-shaft;
    }

    &.is-highlighted,
    &:not(.is-selected):not([disabled]):hover {
      background-color: $color-alabaster;
    }

    &.is-highlighted.is-selected,
    &.is-selected:hover {
      background-color: $background-color-active-item;
      color: $color-mine-shaft;
    }

    &[disabled] {
      cursor: default;
    }
  }

  &__group-header {
    font-size: $font-size-base;
    text-transform: uppercase;
    background: $input-border-color;
    padding: $spacing-small $spacing-base;
  }

  /**
  * States
  */
  &.is-disabled {
    opacity: .5;
  }

  &.is-loading {
    .select-search__value::after {
      top: calc(50% - 7.5px);
      width: 15px;
      height: 15px;
      background-image: url('../global-icons/loading-spin.svg');
      background-size: 15px;
    }
  }

  &:not(.is-loading):not(.select-search--multiple) {
    .select-search__value::after {
      top: calc(50% - 2.5px);
      width: 8px;
      height: 5px;
      background-image: url('../global-icons/select-chevron.svg');
      background-size: 8px;
    }
  }

  &:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }

  &.has-focus {
    .select-search__value::after {
      transform: rotate(180deg);
    }
  }

  /**
  * Modifiers
  */
  &--multiple {
      border-radius: 3px;
      overflow: hidden;
  }

  &--multiple {
    .select-search__input {
      cursor: initial;
      border-radius: 3px 3px 0 0;
    }

    .select-search__select {
      position: relative;
    }

    &:not(.select-search--search) {
      .select-search__input {
        cursor: default;

        &:hover {
          border-color: $color-secondary;
        }
      }

      .select-search__select {
        position: absolute;
        right: 0;
        left: 0;
        max-height: 360px;
      }
    }
  }
}
