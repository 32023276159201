@import 'src/style/variables';

.Badge {
  display: inline-block;
  padding: $spacing-xs $spacing-small;
  border-radius: $border-radius-small;
  font-size: $font-size-small;
  font-weight: $font-weight-medium;
  line-height: 1.3;
  color: $color-mine-shaft;
  cursor: default;

  &--upcoming,
  &--file-processed,
  &--billing-paid {
    background-color: $color-upcoming;
  }

  &--almost-due,
  &--file-processing {
    background-color: $color-almost-due;
  }

  &--due,
  &--file-skipped {
    background-color: $color-due;
  }

  &--overdue {
    background-color: $color-overdue;
  }

  &--late,
  &--file-error,
  &--billing-litigated {
    background-color: $color-late;
  }

  &--default,
  &--file-created {
    background-color: $color-default;
  }

  &--inactive,
  &--billing-cancelled{
    background-color: $color-mercury;
  }

  &--group {
    padding: $spacing-mini $spacing-xs;
    border: 1px solid $color-primary;
    font-size: $font-size-micro;
    background-color: $color-group;
    color: $color-primary;
    text-transform: uppercase;
  }

  &--note {
    background-color: $color-alabaster;
  }

  &--recent,
  &--note {
    padding: 3px 5px;
    font-size: $font-size-mini;
    font-weight: $font-weight-bolder;
    text-transform: uppercase;
  }

  &--pending,
  &--recent {
    background-color: $color-pending;
  }

  & + & {
    margin-left: $spacing-small;
  }
}
