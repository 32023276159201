@font-face {
  font-family: "Object Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/ObjectSans-Bold/ObjectSans-Bold.eot");
  src:
    url("./fonts/ObjectSans-Bold/ObjectSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ObjectSans-Bold/ObjectSans-Bold.woff2") format("woff2"),
    url("./fonts/ObjectSans-Bold/ObjectSans-Bold.woff") format("woff"),
    url("./fonts/ObjectSans-Bold/ObjectSans-Bold.ttf") format("truetype");
}
