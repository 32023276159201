@import 'src/style/variables';

.FormGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-large;

  &--with-error-under-input {
    margin-bottom: $spacing-base;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
      display: block;
      margin-right: $spacing-base;
      color: $form-label-color;
      font-size: $font-size-mini;
      font-weight: $font-weight-bolder;
      text-transform: uppercase;
    }

    .ErrorValidationMessage {
      margin-bottom: $spacing-xs;
    }
  }

  &--mandatory {
    .FormGroup__header__label::after {
      content: '*';
      color: $color-danger;
      font-size: $font-size-base;
      line-height: .1;
      font-weight: $font-weight-bolder;
      margin-left: $spacing-small;
    }
  }
}
