@import 'src/style/variables';

.DataTableHead {
  &__cell {
    padding: $spacing-base 0;
    user-select: none;
    white-space: nowrap;
    vertical-align: middle;
    border-bottom: 1px solid $color-mercury;
    border-top-width: 0;
    font-size: $font-size-mini;
    font-weight: $font-weight-bolder;
    color: $color-grey;
    letter-spacing: $letter-spacing-base;
    text-transform: uppercase;

    &:first-child {
      width: 50px;
    }

    &:last-child {
      text-align: right;
    }

    &--sortable {
      color: $color-grey;

      .DataTableHeadSortLabel {
        color: $color-silver;
      }
    }

    &--sorted {
      color: $color-scorpion;
    }
  }
}
