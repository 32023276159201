@import 'src/style/variables';

.FormAmountCurrency {
  margin-bottom: $spacing-large;

  .ErrorValidationMessage {
    position: absolute;
    right: 0;
  }

  &--with-error-under-input {
    margin-bottom: $spacing-base;

    .ErrorValidationMessage {
      position: inherit;
    }
  }

  .FormGroup {
    margin-left: 0;
    margin-bottom: $spacing-xs;
    flex: initial;
  }

  .FormGroup + .FormGroup {
    // - Important, sinon écrasé par les autres règles sur les FormGroup + FormGroup
    margin-left: 0 !important;
  }

  .FormSelect--has-value .select-search__value::after {
    display: block;
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    $input-height: 48.5px;

    &__warning {
      font-size: $font-size-small;
      font-weight: $font-weight-regular;
      color: $color-warning;
    }

    &__forms {
      display: flex;
      align-items: flex-end;

      &__amount {
        display: flex;

        .InputGroup--helper-right .InputGroup__helper {
          border-radius: 0;
        }

        .FormControl {
          border-radius: $border-radius-base 0 0 $border-radius-base;
        }

        .InputGroup--helper-left {
          .FormControl {
            border-radius: 0;
          }
        }
      }

      &__currency {
        // - Important, sinon écrasé par les autres règles sur les FormGroup
        flex-grow: 0 !important;

        &__field {
          width: 90px;

          .FormControl,
          .select-search__input {
            border-left: none;
            background-color: $color-alabaster;
            border-radius: 0 $border-radius-base $border-radius-base 0;
          }
        }
      }
    }
  }
}
