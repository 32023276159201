@import 'src/style/variables';

.DataTableHeadSortLabel {
  vertical-align: middle;
  margin-left: $spacing-small;
  color: $color-silver;

  .Icon {
    width: $font-size-small;
  }

  &--sorted {
    color: $color-scorpion;
  }
}
