@import 'src/style/variables';

.LoginLinks {
  margin: $spacing-large 0 0;
  padding: 0;

  &__item {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  &__item + &__item {
    margin-top: $spacing-base;
  }

  &__link {
    color: $color-white;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;

    &:hover,
    &:focus {
      color: $color-white;
    }
  }
}
