// - Tailles d'écrans

$screen-breakpoints: (
  phone: 0,
  tablet: 540px,
  small-desktop: 720px,
  desktop: 960px,
  medium-desktop: 1200px,
  large-desktop: 1800px
);

// - Header

$header-height: 82px;
$header-active-item-indicator-width: 3px;

// - Espacements

$spacing-mini: 3px;
$spacing-xs: 5px;
$spacing-small: 10px;
$spacing-base: 15px;
$spacing-medium: 20px;
$spacing-medium-plus: 25px;
$spacing-large: 30px;
$spacing-xl: 35px;
$spacing-xxl: 40px;
$spacing-page-vertical: 50px;
$spacing-page-horizontal: 80px;

// - Divers

$floating-content-width: 220px;
$tooltip-max-width: 400px;

$dropzone-height: 377px;
$dropzone-height-small: 149px;

$chart-height-base: 350px;
$chart-width-base: 880px;

$no-result-drawing-height: 346px;

$width-login-form: 380px;
$width-create-orga-form: 440px;

$width-side-filter: 473px;

$width-logged-out-layout: 584px;

$padding-datatable-right: 95px;
