@import 'src/style/variables';

.CustomerHistoryFilters {
  &__title {
    margin-bottom: $spacing-base;
    text-transform: uppercase;
    color: $color-grey;
    font-weight: $font-weight-bolder;
    font-size: $font-size-mini;
  }

  &__data-type {
    margin-bottom: $spacing-xl;

    .SelectGroupCheckAll {
      margin-bottom: $spacing-medium-plus;
    }
  }
}
