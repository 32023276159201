@use "sass:math";
@import 'src/style/variables';

.Loading {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Icon {
    width: $row-action-icon-size;
    height: $row-action-icon-size;
    margin-right: math.div($spacing-base, 2);
    color: inherit;
  }

  &--no-text {
    .Icon {
      margin: 0;
    }
  }

  &--overlayed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $spacing-xs 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($background-color-base, .6);
    color: $color-mine-shaft;
    font-size: $font-size-subtitle;
  }
}
