@import 'src/style/variables';

.HistoryStepContentPreview {
  &__warning {
    font-size: $font-size-small;
    color: $color-mine-shaft;
    padding: $spacing-base $spacing-large;
    background-color: rgba($color: $color-warning, $alpha: 0.1);
    border-radius: $border-radius-base;
  }

  &__email-sms {
    border: 1px solid $color-mercury;
    border-radius: $border-radius-base;
    padding: $spacing-xl $spacing-large;
  }
}
