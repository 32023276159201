@import 'src/style/variables';

.ReminderSentChart {
  display: flex;
  flex-direction: row;
  margin-top: $spacing-base;
  height: $chart-height-base;

  &__chart {
    flex: 1;
  }
}
