@import 'src/style/variables';

.FormPerimeter {
  border-bottom: 1px solid $color-mercury;

  &__header {
    display: flex;
    margin-bottom: $spacing-base;
  }

  &__title {
    flex: 1;
    text-transform: uppercase;
    font-weight: $font-weight-bolder;
    font-size: $font-size-mini;
    color: $color-scorpion;
  }

  &__error {
    margin-right: $spacing-base;
    margin-bottom: 0;
    font-size: $font-size-small;
    font-weight: $font-weight-bolder;
    color: $color-danger;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    gap: $spacing-large;
  }

  &__action:nth-child(2){
    margin-left: auto;
  }
}
