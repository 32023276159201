@use "sass:math";
@import 'src/style/variables';

.ImportsFileWizardStepChoiceTypeButton {
  $font-size-item: #{1 + math.div(1, 5)}rem; // 18px

  &__item {
    flex: 1;
    border: 1px solid $color-silver;
    border-radius: $border-radius-base;
    padding: $spacing-medium-plus $spacing-medium-plus;
    width: 283px;
    height: 120px;
    margin-left: $spacing-page-vertical;
    font-size: $font-size-item;
    font-weight: $font-weight-bolder;

    &:hover {
      border: 1px solid $color-primary;
    }

    &--active {
      border: 1px solid $color-primary;
    }
  }

  &__break {
    width: 100%;
    height: $spacing-page-vertical;
  }
}
