@import 'src/style/variables';

.PayRequestRowActions {
  display: flex;

  .Button {
    border-radius: $border-radius-base;

    .Loading {
      padding: 0;
      align-items: center;
      justify-content: center;
    }

    &--disabled {
      padding: $spacing-base $spacing-large;
    }
  }

  &__separated {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: $spacing-small 0;
    padding: $spacing-small 0;
    border-top: 1px solid $color-silver;
    border-bottom: 1px solid $color-silver;
  }

  > .RowActionsToggle {
    width: $row-action-btn-size;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:focus,
    &:hover {
      background: none;
    }
  }

  > .RowActionsToggle + .RowActionsToggle {
    margin-left: $spacing-xs;
  }

  .DropdownActions {
    margin-left: $spacing-small;

    .Loading {
      padding: 0;
      margin-right: $spacing-small;

      .Icon {
        color: $color-scorpion;
      }
    }

    .Button--disabled {
      padding: $spacing-base $spacing-large;
    }
  }

  .RowActionsToggle,
  .RowActionPromiseToPay,
  .RowActionNote,
  .RowActionCreditNote {
    .Icon {
      width: $row-action-icon-size;
    }

    .Loading {
      display: flex;
      width: calc(#{$row-action-icon-size} + 5px);
      font-size: $row-action-icon-size;
      color: $color-grey;
    }
  }

  &__download-link {
    display: flex;
    flex-grow: 1;
  }
}

