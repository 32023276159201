@import 'src/style/variables';

.Login {
  .Alert,
  .ErrorMessage {
    position: absolute;
    top: $spacing-page-vertical;
    width: $width-login-form;
    transform: translate(-7.5%, 0);
    margin: 0;
  }

  &__title {
    font-size: $font-size-title;
    margin-bottom: $spacing-base;
  }

  &__form {
    width: $width-login-form;
    margin-right: 5%;
  }
}
