@import 'src/style/variables';

.SuggestCreateAction {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: $spacing-xxl;
  cursor: default;

  &__warning {
    display: flex;
    color: $color-danger;
    align-items: center;
    margin-right: $spacing-xxl;

    .Icon {
      color: $color-danger;
      margin-right: $spacing-small;
    }

    &__text {
      font-weight: $font-weight-regular;
      margin: 0;
    }
  }

  .Dropdown__toggle {
    padding-left: $spacing-base;
    padding-right: $spacing-base;

    &__icon {
      margin-left: $spacing-large;
    }
  }
}
