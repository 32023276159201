@import 'src/style/variables';

.ActionsFuture {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    display: flex;
    align-items: stretch;
  }

  .ActionsFutureSide {
    width: $width-side-filter;
  }

  .ActionsFutureContent {
    flex: 7;
    padding: $spacing-page-vertical;
    padding-right: $padding-datatable-right;
  }

  &__sorting {
    position: absolute;
    right: $padding-datatable-right;
    top: $spacing-page-vertical + 104px + $spacing-xl;
    z-index: 1;
  }

  .DataTable__table-wrapper tbody td:last-child,
  .DataTable__table-wrapper thead th:last-child  {
    text-align: left;
  }

  .DataTableHead__cell {
    .FormCheck {
      margin-left: $spacing-small;
    }
  }

  .DataTableBody__cell {
    padding: 0;
    vertical-align: baseline;

    .FormCheck {
      z-index: 1;
      margin-left: $spacing-small;
      top: $spacing-xs;
    }

    .HistoryStep {
      margin-left: -$spacing-page-vertical;
      border: none;
      padding: $spacing-xl $spacing-xs $spacing-xl $spacing-page-vertical;
      border-radius: 0;
    }

    &--no-check {
      .HistoryStep {
        padding: $spacing-xl $spacing-xs $spacing-xl $spacing-page-vertical;
      }
    }
  }

  .DataTableBody__cell:first-child::before,
  .DataTableBody__cell:last-child::after {
    display: none;
  }


  &--sorted-by-total-amount-due {
    .HistoryStepInfosHeader__details__total-amount {
      display: none;
    }

    .HistoryStepInfosHeader__details__total-amount-due {
      display: inline;
    }
  }

  .PageList__header {
    margin-bottom: 0;
  }

  .Export {
    padding-left: 0;
  }
}
