@import 'src/style/variables';

.ListStatsByPhaseItemBar {
  display: flex;
  align-items: center;

  &__item-index {
    margin-right: $spacing-base;
    font-size: $font-size-small;
  }
}
