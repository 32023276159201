@import 'src/style/variables';

.Roles {
  &__list {
    display: flex;

    &__items {
      flex: 1;
    }
  }
}
