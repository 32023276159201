@import 'src/style/variables';

.ActionsDoneSide {
  background-color: $background-color-secondary;
  padding: $spacing-page-vertical $spacing-large $spacing-page-vertical $spacing-page-horizontal;

  .FiltersSidebar__search {
    margin-bottom: $spacing-xl;
    max-width: 283px;
  }

  .FormSelect,
  .FormSelectCustomer,
  .select-search__input,
  .react-date-picker__wrapper {
    width: 100%;
    border: none;
  }

  .FormSelect--has-value {
    .select-search__value::after {
      display: block;
    }
  }

  .FormGroup__header__label {
    line-height: 13.1px;
    font-size: $font-size-mini;
    color: $color-grey;
  }

  .ActionsCollapse {
    border: none;

    .Collapse__content {
      border-top: 2px solid $background-color-secondary;
    }

    .ActionCategory {
      border-bottom: 2px solid $background-color-secondary;
    }
  }
}
