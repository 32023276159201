@import 'src/style/variables';

.CustomerAccountDetailsAmountCell {
  font-size: $font-size-medium;
  font-weight: $font-weight-medium;
  color: $color-scorpion;
  cursor: default;

  &__tooltip {
    padding: $spacing-xs $spacing-small;
    font-size: $font-size-medium;

    &__title {
      margin-bottom: $spacing-mini;
    }

    &__amount {
      font-weight: $font-weight-bold;
      text-align: center;
    }
  }

  &--payment-positive {
    color: $color-success;
  }

  &--payment-error,
  &--payment-negative,
  &--reject-unpaid {
    color: $color-danger;
  }
}
