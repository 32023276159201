@import 'src/style/variables';

.ScenarioDetailsActions {
  display: flex;
  flex-direction: column;
  padding: $spacing-medium-plus $spacing-xxl;

  &__section {
    display: flex;
    flex-direction: row;

    & + & {
      margin-top: $spacing-medium;
    }

    &__title {
      margin: $spacing-base $spacing-base $spacing-base 0;
      font-size: $font-size-medium;
      flex: 1;
      display: flex;
    }

    &__container {
      display: flex;
      flex-direction: column;
      flex: 4;

      &__no-data {
        margin: $spacing-base 0 0 0;
        color: $color-scorpion;
      }
    }
  }
}
