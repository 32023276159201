@import 'src/style/variables';

.ModalModelReminderTask {
  border: 1px solid $color-silver;
  padding: $spacing-small;
  border-radius: $border-radius-base;
  margin-bottom: $spacing-base;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  &:hover,
  &:focus {
    .Icon {
      color: $color-white;
    }
  }

  &__main {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: $spacing-base;
      background-color: $color-primary;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-base;
      height: $input-height-small;
      width: $input-height-small;
      min-width: $input-height-small;
    }

    &__description {
      display: flex;
      flex-direction: column;

      &__title {
        margin-bottom: 0;
      }

      &__channel {
        margin-bottom: 0;
        font-size: $font-size-small;
        color: $color-scorpion;
      }
    }
  }
}
