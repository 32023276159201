@import 'src/style/variables';

.SettingsNav {
  font-size: $font-size-medium;
  color: $color-scorpion;

  &__item {
    margin: 0 0 $spacing-base $spacing-base;

    &__title {
      font-weight: bold;
      font-size: $font-size-subtitle;
      color: $color-mine-shaft;
      margin-bottom: $spacing-large;
    }

    &__list {
      list-style: none;
    }
  }

  &__label {
    flex: 0 0 110px;
    font-weight: $font-weight-bolder;
    color: $color-mine-shaft;
    margin-right: $spacing-xs;
  }

  &__value {
    flex: 1;
    margin: 0;
    line-break: none;
  }
}
