@import 'src/style/variables';

.AttributesColumn {
  &__title {
    font-weight: $font-weight-bolder;
    margin-bottom: 0;
  }

  &__values-title {
    margin-bottom: 0;
  }

  &__values {
    margin-bottom: 0;
    word-wrap: normal;
    color: $color-primary;
  }
}
