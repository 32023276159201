@import 'src/style/variables';

.HeaderSearchResults {
  background-color: $background-color-base;
  border-radius: $border-radius-large;
  padding: $spacing-xs 0;

  &__no-result {
    margin: 0;
    padding: $spacing-base $spacing-large;
    color: $color-grey;
    text-align: center;
  }

  &__title {
    font-size: $font-size-mini;
    font-weight: $font-weight-bolder;
    text-transform: uppercase;
    margin: 0;
    padding: 0 $spacing-xl $spacing-base;
  }

  &__count {
    font-weight: $font-weight-regular;
    color: $color-scorpion;
    margin-left: $spacing-xs;
  }

  &__pay-requests {
    padding: $spacing-medium 0;
    border-bottom: 1px solid $color-silver;
  }

  &__clients {
    padding: ($spacing-medium + $spacing-xs) 0 $spacing-medium;
  }
}
