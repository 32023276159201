@import 'src/style/variables';

.PaymentRequestContent {
  padding: 0 $spacing-page-vertical $spacing-page-horizontal $spacing-page-horizontal;

  .PaymentRequestInfos,
  .PaymentRequestActionsCollapse {
    border-top: 1px solid $color-mercury;
  }

  .BlockSection {
    margin: $spacing-large 0;
  }
}
