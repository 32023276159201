@function breakpoint-next($name) {
    $breakpoint-names: map-keys($screen-breakpoints);
    $index: index($breakpoint-names, $name);
    @return if($index < length($breakpoint-names), nth($breakpoint-names, $index + 1), null);
}

@function breakpoint-min($name) {
    $min: map-get($screen-breakpoints, $name);
    @return if($min != 0, $min, null);
}

@function breakpoint-max($name) {
    $next: breakpoint-next($name);
    @return if($next, breakpoint-min($next) - 1px, null);
}

@mixin media-min($name) {
    $min: breakpoint-min($name);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-max($name) {
    $max: breakpoint-max($name);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}
