@import 'src/style/variables';

.SmallSelect {
  .select-search {
    &__input,
    &__select {
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      color: $color-scorpion;
    }

    &__input {
      height: auto;
      padding: 5px;
      border: none;
    }

    &__select {
      top: 100%;
      min-width: auto;
    }
  }
}
