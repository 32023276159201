@import 'src/style/variables';

.NoData {
  &__text {
    margin: $spacing-medium 0;
    color: $color-grey;
    font-size: $font-size-subtitle;
    font-style: italic;
    text-align: center;
  }
}
