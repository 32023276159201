@import 'src/style/variables';

.FiltersSidebar  {
  margin-bottom: $spacing-xl;

  &__search {
    margin-bottom: $spacing-large;
  }

  &__filters {
    .FormGroup__header__label {
      margin-bottom: 10px;
    }
  }
}
