@import 'src/style/variables';

.SearchInput {
  position: relative;

  .Icon,
  .Loading {
    color: $color-scorpion;
  }

  &.InputGroup {
    .FormControl,
    .InputGroup__helper {
      height: $input-height-small;
      font-weight: $font-weight-medium;
      background-color: $color-white;
    }
  }

  &__tooltip {
    position: absolute;
    top: 100%;
    width: 100%;
    padding: $spacing-xs $spacing-small;
    background-color: $background-color-dark;
    color:$color-white;
    text-align: center;
    font-size: $font-size-medium;
  }
}
