@import 'src/style/variables';

.CreditNotes {
  &__columns {
    &__reference {
      font-size: $font-size-base;
      font-weight: $font-weight-bolder;
      color: $color-mine-shaft;
    }

    &__pay-request {
      font-size: $font-size-base;
    }

    &__link,
    &__link:hover {
      color: $color-mine-shaft;
    }

    &__client {
      &__ref {
        margin-top: $spacing-xs;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        color: $color-scorpion;
      }
    }
  }

  &__page-select {
    .select-search__value::after {
      display: block;
    }
  }
}
