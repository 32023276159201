@import 'src/style/variables';

.FormSMSContent {
  .CountCharactersHelper {
    display: flex;
    justify-content: flex-end;
    color: $color-grey;
    font-size: $font-size-medium;
  }
}
