@import 'src/style/variables';
@import 'src/style/mixins';

.PageList {
  padding: $spacing-base * 2;

  &__header {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: $spacing-medium + $spacing-xs;
  }

  &__title {
    margin: 0;
    font-size: $font-size-title;
    font-weight: $font-weight-bolder;
    color: $color-mine-shaft;
  }

  &__nav {
    flex: 0 0 auto;
    margin-left: $spacing-medium + $spacing-xs;
  }

  &__count {
    flex: 0 0 auto;
    margin-left: $spacing-medium + $spacing-xs;
    font-size: $font-size-subtitle;
    font-weight: $font-weight-bold;
    color: $color-scorpion;
  }

  &__actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  @include media-min(desktop) {
    padding: $spacing-page-vertical $spacing-page-horizontal;
  }
}
