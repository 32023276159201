// - Base colors

$color-white: #fff;
$color-alabaster: #f7f7f7;
$color-mercury: #e5e5e5;
$color-silver: #ccc;
$color-grey: #8c8c8c;
$color-scorpion: #585858;
$color-mine-shaft: #212121;
$color-black: #000;

$color-primary: #0076ff;
$color-secondary: #00ffde;
$color-info:  #7027e9;
$color-success: #00cd5a;
$color-warning: #e9a927;
$color-danger: #d60f0f;
$color-group: #cfffff;

$color-upcoming: #d0ffcf;
$color-pending: #cfffe8;
$color-almost-due: #cfffff;
$color-due: #ffefcf;
$color-overdue: #ffddcf;
$color-late: #ffcfcf;
$color-default: #e1cfff;

$color-primary-hover: #0057ff;
$color-danger-hover: #be1010;

// - Background colors

$background-color-base: $color-white;
$background-color-secondary: $color-alabaster;
$background-color-dark: $color-mine-shaft;
$background-color-active-item: #e6fffc;

$background-color-warning: #fdf6e9;
$background-color-error: #fdf3f3;

$background-color-header: linear-gradient(90deg, $color-black 0%, $background-color-dark 100%);
$background-color-sidemenu: $background-color-dark;

$color-link: $color-primary;
$color-link-hover: $color-primary-hover;

$color-header-link: $color-silver;
$color-header-link-hover: $color-white;
$color-header-link-active: $color-secondary;
