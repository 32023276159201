@use "sass:math";
@import 'src/style/variables';

.MyOrganizationEditForm {
  &__fieldset {
    display: flex;

    .form-group {
      flex: 1;
      max-width: calc(50% - #{math.div($spacing-large, 2)});
    }

    .form-group + .form-group {
      margin-left: $spacing-large;
    }
  }
}
