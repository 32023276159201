@import 'src/style/variables';

.Payments {
  .ErrorMessage {
    margin-bottom: $spacing-base;
  }

  .DataTableHead__cell:last-child,
  .DataTableBody__cell:last-child {
    width: 1%;
    white-space: nowrap;
  }

  &__page-select {
    .select-search__value::after {
      display: block;
    }
  }

  &__columns {
    &__reference {
      font-size: $font-size-base;
      font-weight: $font-weight-bolder;
      color: $color-mine-shaft;
      overflow-wrap: break-word;

      &:hover {
        color: $color-mine-shaft;
      }
    }

    &__pay-requests {
      &__item {
        overflow-wrap: break-word;
      }

      &__failed {
        font-style: italic;
        color: $color-grey;
      }

      .ButtonWithUserRights {
        max-width: 200px;
      }
    }

    &__mean {
      margin-top: $spacing-xs;
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      color: $color-primary;
    }

    &__subject {
      color: $color-grey;
      max-width: 200px;
    }

    &__link,
    &__link:hover {
      color: $color-mine-shaft;
    }

    &__client {
      font-weight: $font-weight-bold;

      > a {
        color: $color-mine-shaft;
      }

      &__ref {
        margin-top: $spacing-xs;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        color: $color-scorpion;
      }
    }
  }
}
