@import 'src/style/variables';

.SelectorColumn {
  &__title {
    text-transform: capitalize;
    margin-bottom: 0;
  }

  &__values-title {
    margin-bottom: 0;
  }

  &__values-operator {
    margin-bottom: 0;
    color: $color-primary;
  }

  &__values {
    margin-bottom: 0;
    word-wrap: normal;
    color: $color-primary;
  }
}
