@import 'src/style/variables';
@import 'src/style/mixins';

.UserMenu {
  .Dropdown {
    &__toggle {
      padding: 0;
      margin: 0 $header-active-item-indicator-width;
      background: none;
      border-width: 0;
      border-radius: 50%;
      transition: none;

      &:hover {
        .Avatar {
          background: $background-color-base;
          border-radius: 50%;

          &__container__image {
            opacity: .5;
          }

          &__container__initials {
            background-color: $color-silver;
          }
        }
      }
    }

    &__menu {
      margin-top: $spacing-small;
    }
  }

  &.Dropdown--open {
    .Dropdown__toggle {
      padding: $header-active-item-indicator-width;
      margin: 0;
      background: linear-gradient(180deg, $color-secondary 0%, $color-primary 100%);

      .Avatar__image {
        opacity: 1;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spacing-small $spacing-large $spacing-base;

    &__username {
      margin-top: $spacing-medium;
      font-size: $font-size-subtitle;
      color: $color-scorpion;
    }

    &__role {
      color: $color-grey;
      font-size: $font-size-medium;
    }
  }

  &__links {
    &__divider {
      margin: $spacing-small 0;
      background-color: $color-mercury;
      height: 0.5px;
      border: none;
    }

    &__item {
      @extend %dropdown-item;
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;

      .Icon {
        margin-right: $spacing-base;
        color: $color-silver;
      }

      .ReportsCount {
        margin: -4px 0 -4px $spacing-base;

        &--in-progress {
          margin-left: -4px;
          margin-right: $spacing-base;
        }
      }

      &__help {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .FormSwitch {
          height: auto;
        }
      }

      &.active {
        &,
        &:hover {
          background-color: rgba($color: $color-silver, $alpha: 0.15);
          color: $color-mine-shaft;

          .Icon {
            color: $color-grey;
          }
        }
      }

      &--danger {
        background-color: rgba($color: $color-danger, $alpha: 0.1);
        color: $color-danger;

        &:hover {
          background-color: rgba($color: $color-danger, $alpha: 0.2);
          color: $color-danger;
        }
      }
    }

    .Button {
      width: 100%;
    }
  }
}
