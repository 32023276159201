@import 'src/style/variables';

.SettingsProfileMyProfile {
  margin-bottom: $spacing-page-horizontal;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-page-vertical;

    &__image {
      flex: 0 0 auto;
      border: none;

      &__text {
        display: none;
      }

      &__form {
        display: none;
      }

      &:hover {
        .SettingsProfileMyProfile__header__image__text {
          display: block;
          position: absolute;
          background: $color-scorpion;
          z-index: 1;
          color: $color-white;
          padding: $spacing-small;
        }

        .Avatar {
          background: $background-color-base;
          border: none;

          &__image {
            opacity: .5;
          }
        }
      }
    }

    &__name {
      flex: 1;
      margin-left: $spacing-page-vertical;
      font-size: $font-size-important;
      font-weight: $font-weight-bolder;
    }
  }

  .Button + .Button {
    margin-left: $spacing-small;
  }
}
