@import 'src/style/variables';

.ContactsBulkActions {
  display: flex;
  align-items: center;

  .Loading {
    padding-top: $spacing-medium-plus;
    color: $color-white;
    font-size: $font-size-base;
  }
}
