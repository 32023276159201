@import 'src/style/variables';

.SelectorsListing {
  &__description {
    color: $color-scorpion;
    font-size: $font-size-light;
  }

  &__no-data {
    margin: $spacing-medium 0;
    color: $color-grey;
    font-size: $font-size-subtitle;
    font-style: italic;
    text-align: center;
  }
}
