@import 'src/style/variables';
@import '../index.scss';

.PayerInvoices {
  width: 100%;
  flex-direction: column;
  margin-right: $side-width;
  padding: $spacing-page-horizontal $spacing-page-horizontal-large $spacing-page-horizontal $spacing-page-horizontal;

  &__title {
    font-size: $font-size-title;
    line-height: 1;
    margin-bottom: $spacing-medium-plus;
  }

  &__subtitle {
    font-size: $font-size-subtitle;
  }

  &__download-all {
    float: right;
    font-size: $font-size-medium;
  }

  .DataTable {
    border-top: none;
  }

  &__columns {
    &__reference {
      display: flex;
      align-items: center;

      .PDFViewer {
        margin-right: $spacing-large;
      }
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: $color-mercury;
    border: 0;
    margin: 0 0 $spacing-medium-plus 0;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-medium-plus;

    .PreviewListElement {
      margin-bottom: $spacing-xs;
    }
  }
}
