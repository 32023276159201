@import 'src/style/variables';

.ModalReminderModelForm {
  &__date {
    max-width: 50%;
    flex: 0;
  }

  &__divider {
    border-bottom: 1px solid $color-mercury;
    width: 100%;
    height: $spacing-small;
    margin-bottom: $spacing-xl;
  }
}
