@import 'src/style/variables';

.CustomerInfos {
  font-size: $font-size-medium;
  color: $color-scorpion;

  &__title {
    flex: 0 0 65px;
    margin: 0 0 $spacing-medium $spacing-base;
    color: $color-mine-shaft;
    font-size: $font-size-subtitle;
    font-weight: $font-weight-bolder;
  }

  &__item {
    display: flex;
    margin: 0 0 $spacing-base $spacing-base;
  }

  &__label {
    flex: 0 0 150px;
    font-weight: $font-weight-bolder;
    color: $color-mine-shaft;
    margin-right: $spacing-xs;

    &--capitalize {
      text-transform: capitalize;
    }
  }

  &__value {
    flex: 1;
    margin: 0;
    line-break: none;
  }
}
