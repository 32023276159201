@import 'src/style/variables';

.CustomerStatsTodos {
  display: flex;
  align-items: center;
  padding: $spacing-medium-plus $spacing-medium;
  background-color: $color-alabaster;
  border-radius: $border-radius-large;

  &__icon {
    width: 40px;
    height: 40px;

    .Icon {
      max-width: 40px;
      max-height: 40px;
    }
  }

  &__details {
    flex: 1;
    margin-left: $spacing-base;
  }

  &__count {
    margin: 0;
    color: $color-primary;
    font-size: $font-size-light;
    font-weight: $font-weight-bolder;
  }

  &__counts {
    margin: 0;
    font-size: $font-size-small;
    color: $color-scorpion;
  }
}
