@import 'src/style/variables';

.HistoryStepPayRequestsGroup {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &__item {
    display: inline-block;
    list-style: none;
    margin-right: $spacing-xs;
    padding: 0;

    &:not(:last-child)::after {
      content: ',';
    }
  }

  &:hover {
    color: $color-black;
  }

  &--unfolded {
    white-space: normal;
  }
}
