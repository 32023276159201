@import 'src/style/variables';

.Dropdown {
  position: relative;

  &__toggle {
    display: flex;
    align-items: center;

    &__content {
      flex: 1;
    }

    &__icon {
      flex: 0 0 auto;
      width: 9px;
      margin: 2px 0 0 $spacing-xs;
    }
  }

  &__menu {
    position: absolute;
    display: none;
    z-index: 1000;
    right: 0;
    overflow-y: auto;
    min-width: 150px;
    flex-direction: column;
    margin-top: $spacing-xs;
    background: $background-color-base;
    padding: $spacing-base 0;
    border-radius: $border-radius-large;
    box-shadow: $dropdown-box-shadow;
    white-space: nowrap;
  }

  &--open {
    .Dropdown__toggle {
      .Icon:not(.Dropdown__toggle__icon) {
        color: $color-primary;
      }
    }

    .Dropdown__menu {
      display: flex;
    }
  }
}
