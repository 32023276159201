@import 'src/style/variables';

.CustomerInfosContacts {
  &__title {
    padding-bottom: $spacing-page-vertical;
    margin-bottom: $spacing-page-vertical;
    border-bottom: 1px solid $color-mercury;
    font-size: $font-size-title;
    color: $color-mine-shaft;
  }
}
