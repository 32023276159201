@import 'src/style/variables';

.ModalSelectorForm {
  &__position {
    // Important sinon écrasé par .FormControl + .FormControl
    margin-left: $spacing-page-horizontal !important;

    .FormControl {
      width: 50%;
    }
  }

  &__default {
    display: flex;
  }

  &__condition-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-large;

    &__title {
      display: block;
      margin: 0;
      color: $color-scorpion;
      font-size: $font-size-mini;
      font-weight: $font-weight-bolder;
      text-transform: uppercase;
    }
  }

  &__and {
    text-transform: uppercase;
    color: $color-primary;
  }

  &__plan {
    margin-top: $spacing-base;
  }
}
