@import 'src/style/variables';

.BackOfficeNav {
  font-size: $font-size-medium;
  color: $color-scorpion;

  &__item {
    margin: 0 0 $spacing-base $spacing-base;

    &__title {
      font-weight: bold;
      font-size: $font-size-subtitle;
      color: $color-mine-shaft;
      margin-bottom: $spacing-large;
    }

    &__list {
      list-style: none;

      &__item {
        margin-bottom: $spacing-large;

        &__link {
          font-weight: $font-weight-medium;
          font-size: $font-size-root;
          color: $color-grey;

          &:hover {
            color: $color-scorpion;
            text-decoration: none;
          }
        }

        &--active {
          .BackOfficeNav__item__list__item__link {
            color: $color-scorpion;
          }
        }
      }
    }
  }

  &__label {
    flex: 0 0 110px;
    font-weight: $font-weight-bolder;
    color: $color-mine-shaft;
    margin-right: $spacing-xs;
  }

  &__value {
    flex: 1;
    margin: 0;
    line-break: none;
  }
}
