@import 'src/style/variables';

.PerimetersTable {
  &__name {
    display: flex;
    flex-direction: column;

    &__name {
      color: $color-scorpion;
    }

    &__identifier {
      color: $color-grey;
    }
  }

  &__values {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-xs $spacing-xs;

    &__value {
      color: $color-primary;
      cursor: default;

      &::after {
        color: $color-grey;
        content: ',';
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  .DataTable {
    border-top: none;
  }
}
