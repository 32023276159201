@import 'src/style/variables';
@import 'src/style/mixins';

.Header {
  $sidebar-width: 300px;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: $header-height;
  background: $background-color-header;
  z-index: 1;

  &__left {
    flex:  0 0 auto;
    display: flex;
    align-items: center;
    width: auto;
    padding: 0 $spacing-base;

    .BrandLogo {
      display: none;
    }

    .MenuToggle {
      flex: 1;
    }
  }

  &__navigation {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    width: $sidebar-width;
    padding: 0;
    background: $background-color-sidemenu;
    overflow-y: auto;
    z-index: 9999;
    transform: translateX(-$sidebar-width);
    transition: all 350ms ease;

    &--displayed {
      border-right: 1px solid $color-mercury;
      box-shadow: 0 0 30px $color-mercury;
      transform: translateX(0);
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 $spacing-base 0 0;
  }

  @include media-min(desktop) {
    height: $header-height;

    &__left {
      .BrandLogo {
        display: flex;
      }

      .MenuToggle {
        display: none;
      }
    }

    &__navigation {
      flex: 1;
      position: static;
      background: none;
      border-right: 0;
      width: auto;
      box-shadow: none;
      overflow-y: visible;
      transform: none;
      z-index: auto;
    }
  }

  @include media-min(medium-desktop) {
    &__left {
      padding: 0 $spacing-large;
    }

    &__navigation {
      padding: 0 $spacing-xs;
    }
  }
}
