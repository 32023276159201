@import 'src/style/variables';

.FormDatePicker {
  &--is-invalid {
    .react-date-picker__wrapper {
      border-color: $color-danger;
    }
  }

  .react-date-picker__inputGroup__input {
    color: $color-scorpion;
  }

  .Icon {
    color: $color-grey;
  }

  &:hover {
    .react-date-picker__inputGroup__input {
      color: $color-mine-shaft;
    }

    .react-date-picker__wrapper {
      border-color: $color-scorpion;
    }

    .Icon {
      color: $color-scorpion;
    }
  }
}
