@import 'src/style/variables';

.DailyReportSwitch {
  flex-direction: row;
  display: flex;
  align-items: center;

  &__indication {
    margin: 0px;
    padding: $spacing-base;
  }
}
