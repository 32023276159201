@import 'src/style/variables';

.ListStatsByPeriodItemPercent {
  list-style: none;

  &__bullet {
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  &__name {
    font-size: $font-size-small;
    color: $color-white;
    margin-left: $spacing-small;
  }

  &__percent {
    font-size: $font-size-small;
    color: $color-grey;
    margin-left: $spacing-small;
  }

  & + & {
    margin-top: $spacing-small;
  }
}
