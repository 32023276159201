$side-width: 413px;
$spacing-page-horizontal-large: 110px;

.PayerPage {
  display: flex;
  min-width: 100%;
  min-height: 100%;

  .NoResultsFoundDrawing {
    align-self: center;
    margin-left: $spacing-page-horizontal-large;
  }
}
