@import 'src/style/variables';

.ReminderPreview {
  $block: &;

  &__subject {
    border: solid 1px $color-mercury;
    margin-bottom: $spacing-small;
    padding: ($spacing-small) $spacing-base;
  }

  &__content {
    padding: $spacing-medium;
    border: solid 1px $color-mercury;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    word-wrap: break-word;
  }

  &__helper {
    margin-top: $spacing-base;
  }

  &__wrapper {
    &--sms {
      #{$block}__content {
        padding: $spacing-base;
      }
    }

    &--letter {
      #{$block}__content {
        width: 100%;
        height: 1000px;
        margin: 0;
        padding: 0;
      }
    }
  }
}
