@import 'src/style/variables';

.ImportReports {
  padding: $spacing-page-vertical $spacing-page-horizontal;

  &__section {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-medium;
  }

  &__header {
    display: flex;
    flex-direction: row;
    padding-bottom: $spacing-page-vertical;
    align-items: center;

    &__title {
      margin-bottom: 0;
      margin-left: $spacing-medium-plus;
      font-size: $font-size-title
    }

    &__links {
      .Button {
        width: $link-back-btn-size;
        height: $link-back-btn-size;
        padding: 0;
      }
    }
  }

  &__filters {
    flex-direction: row;
    display: flex;
    width: 100%;

    &__select-format {
      width: 30%;
      height: $input-height-base;
    }

    &__input {
      width: 25%;
      margin-right: $spacing-base;

      .Button {
        justify-content: space-between;
        height: $input-height-base;
      }

      &__label {
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
      }

      &__count {
        margin-left: $spacing-small;
        line-height: 1.1;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        color: $color-scorpion;
      }
    }
  }

  &__no-data {
    margin: $spacing-medium 0;
    color: $color-grey;
    font-size: $font-size-subtitle;
    font-style: italic;
    text-align: center;
  }
}
