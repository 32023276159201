@import 'src/style/variables';

.ImportsFileWizardStepHeader {
  padding: $spacing-page-vertical $spacing-page-horizontal $spacing-xl;

  &__wrapper {
    display: flex;
    align-items: center;
    padding-bottom: $spacing-xl;
    border-bottom: 1px solid $color-mercury;
    width: 100%;
  }

  &__links {
    flex: 0 0 auto;
    align-self: flex-start;

    .Button {
      width: $link-back-btn-size;
      height: $link-back-btn-size;
      padding: 0;
    }
  }

  &__infos {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: $spacing-medium-plus;

    .Loading__content {
      justify-content: flex-start;
    }

    &__name {
      flex: 1;
      margin-bottom: $spacing-xs;
      font-size: $font-size-title;
      line-height: 1;
      margin-top: .5rem;
    }

    &__subtitles {
      flex: 2;
      display: flex;
      flex-direction: row;
      font-size: $font-size-subtitle;
      color: $color-scorpion;

      &__item {
        margin-bottom: 0;
        margin-right: $spacing-xl;
      }
    }
  }
}
