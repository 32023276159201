@import 'src/style/variables';

.OrganizationItemWalletItem {
  display: block;
  width: 100%;
  font-size: $font-size-light;
  font-weight: $font-weight-medium;
  padding-left: $spacing-xxl;

  &:focus {
    background: none;
  }

  &--selected,
  &--selected:focus {
    background-color: rgba($color-secondary, 0.1);
  }

  &--selected:hover,
  &--selected:focus:hover {
    background-color: rgba($color-secondary, 0.15);
  }
}
