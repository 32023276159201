@import 'src/style/variables';

.Analysis {
  .FormSelect {
    width: 20%;
  }

  .FormSelect--has-value .select-search__value::after {
    display: block;
  }
}
