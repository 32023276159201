@import 'src/style/variables';

.ListHistorySummary {
  .Dropdown {
    &__toggle {
      padding: 0;
      background-color: $color-alabaster;
      border-radius: $border-radius-small;

      &__content {
        display: flex;
        align-items: center;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        color: $color-mine-shaft;
        line-height: 1.3;
        border-radius: $border-radius-small;
      }

      &:hover,
      &:focus {
        background-color: $color-mercury;
        border-color: $color-mercury;

        .ListHistorySummary__other {
          border-left-color: $color-grey;
        }
      }
    }

    &__menu {
      left: 0;
      right: auto;
      margin: 0;
      padding: 0;
      white-space: initial;
    }

    &--open {
      .Dropdown__toggle {
        background-color: $color-mercury;
      }

      .ListHistorySummary__other {
        border-left-color: $color-grey;

        > .Icon {
          color: $color-mine-shaft;
        }
      }
    }
  }

  &__next-send-pr,
  &__other {
    padding: $spacing-xs $spacing-small;

    > .Icon {
      vertical-align: middle;
      color: $color-grey;
    }
  }

  &__next-send-pr + &__other {
    border-left: 1px solid $color-silver;
    transition: border 200ms ease;
  }

  &__content {
    width: $floating-content-width;
    margin-top: $spacing-xs;
    padding: 0;
    background-color: $color-mine-shaft;
    color: $color-white;
    border-radius: $border-radius-large;
    border-top-left-radius: 0;
    font-size: $font-size-small;

    &__title {
      font-size: $font-size-mini;
      text-transform: uppercase;
      font-weight: $font-weight-bolder;
      margin-bottom: $spacing-small;
    }

    &__item-index {
      color: $color-grey;
      margin-right: $spacing-base;
    }

    &__important-value {
      color: $color-secondary;
    }

    &__next-send-pr {
      padding: ($spacing-medium + $spacing-xs) $spacing-large $spacing-medium;
      background-color: $color-black;
      border-top-right-radius: $border-radius-large;
    }

    &__promise {
      padding: ($spacing-medium + $spacing-xs) $spacing-large $spacing-medium;
    }

    &__past {
      padding: $spacing-medium $spacing-large $spacing-xl;
    }

    &__no-next-send-pr {
      padding: ($spacing-medium + $spacing-xs) $spacing-large;
      background-color: $color-primary;
    }
  }
}
