@import 'src/style/variables';

.PlanModalTaskForm {
  &__days-group {
    .FormGroup {
      flex-direction: row;
      align-items: center;

      &__label {
        flex-shrink: 0;
        align-self: center;
        margin-right: $spacing-base;
        margin-bottom: 0;
      }
    }

    &__label {
      margin: 0 $spacing-base;
    }
  }
}
