@import 'src/style/variables';

.PaymentRequestStatusSelect {
  &__count {
    margin-left: $spacing-small;
    line-height: 1.1;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    color: $color-scorpion;
  }
}
