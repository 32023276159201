@import 'src/style/variables';

.PlanDetailsActions {
  display: flex;
  flex-direction: column;
  padding: $spacing-base $spacing-large;
  border-top: 0.5px solid $color-mercury;

  &__section {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-medium;

    &__title {
      margin: $spacing-base;
      font-size: $font-size-light;
      flex: 1;
      display: flex;
    }

    &__container {
      display: flex;
      flex-direction: column;
      flex: 4;

      &__no-data {
        margin: $spacing-base 0 0 0;
        color: $color-scorpion;
      }
    }
  }
}
