@import 'src/style/variables';

.ImportsFileNav {
  &__title {
    font-size: $font-size-subtitle;
    margin-bottom: $spacing-medium-plus;
  }

  &__list {
    font-size: $font-size-light;
    margin-left: $spacing-base;
    color: $color-scorpion;

    &__link {
      display: block;
      margin-bottom: $spacing-base;
      color: $color-grey;

      &.active,
      &:hover {
        color: $color-scorpion;
        text-decoration: none;
      }
    }
  }

  &__modal {
    &__content {
      color: $color-scorpion;
      margin-bottom: $spacing-xs;

      &__email {
        color: $color-primary;
      }
    }

    &__info {
      margin-top: $spacing-large;
      color: $color-grey;
      font-size: $font-size-light;
    }
  }
}
