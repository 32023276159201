@use "sass:math";
@import 'src/style/variables';

.BackOfficeSection {
  margin-bottom: $spacing-page-horizontal;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-large;
    padding-bottom: $spacing-base;
    border-bottom: 1px solid $color-mercury;

    &__title {
      flex: 1;
      margin: 0;
      font-size: $font-size-important;
    }

    &__link-manage {
      flex: 0 0 auto;
      justify-items: flex-end;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
      margin-left: $spacing-base;

      &__icon {
        margin-left: math.div($spacing-base, 2);
      }
    }
  }

}
