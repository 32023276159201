@import 'src/style/variables';

.ErrorMessage {
  .Alert {
    margin-bottom: 0;
  }

  &__details {
    padding: $spacing-small $spacing-small $spacing-base;
    color: $color-danger;
  }

  &__toggle-details {
    padding: $spacing-small 0 0;
    color: $color-danger;
    text-decoration: underline;

    &:hover,
    &:active {
      color: $color-danger;
    }
  }
}
