@import 'src/style/variables';

.Export {
  display: flex;
  align-items: center;
  padding: 0 $spacing-base * 2;
  height: $input-height-small;
  font-size: $font-size-small + 0.072rem;
  font-weight: $font-weight-bold;

  .Icon {
    margin-right: $spacing-xs;
  }
}
