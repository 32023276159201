@import 'src/style/variables';

.ImportsReportsItem {
  border-radius: $border-radius-small;
  border: 0.5px solid $color-grey;
  color: $color-mine-shaft;
  padding: $spacing-base $spacing-large;
  margin-bottom: $spacing-base;
  display: flex;
  flex-direction: row;

  &__infos {
    flex-direction: column;
    padding: 0px $spacing-base;
    flex: 1;
    justify-content: center;
    display: flex;

    &__type {
      display: flex;
      margin-bottom: 0px;
      font-weight: $font-weight-bolder;
      font-size: $font-size-light;
    }

    &__subtitle {
      display: flex;
      color: $color-scorpion;
      font-size: $font-size-medium;
      margin-bottom: 0px;
    }
  }

  &__filename {
    flex: 2;
    display: flex;
    align-items: center;
    font-size: $font-size-light;
    font-weight: $font-weight-bolder;
    flex-wrap: wrap;
    margin-right: $spacing-base;

    &__text {
      margin-bottom: 0px;
      word-break: break-all;
    }
  }

  &__status {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__action {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__date {
      font-size: $font-size-small;
      margin-right: $spacing-base;
      margin-bottom: 0px;
    }

    &__link {
      margin-left: $spacing-base;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
      }
    }

    .Button--primary {
      &:hover {
        color: $color-white;
      }
    }
  }

  &__icon {
    padding: $spacing-xs;
    color: $color-white;
    background-color: $color-primary;
    border-radius: $border-radius-small;
    height: 100%;
    display: flex;
    align-self: center;
  }

  &--warning {
    background-color: $background-color-warning;
    border-color: $background-color-warning;
    color: $color-mine-shaft;

    .ImportsReportsItem__icon {
      background-color: $color-warning;
    }
  }

  &--error {
    background-color: $background-color-error;
    border-color: $background-color-error;
    color: $color-mine-shaft;

    .ImportsReportsItem__icon {
      background-color: $color-danger;
    }
  }

  &--in-progress {
    border-color: $color-primary;
    color: $color-mine-shaft;
  }
}
