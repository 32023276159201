@import 'src/style/variables';

.PaymentRequestPreviewContact {
  display: flex;
  font-weight: $font-weight-bolder;
  border: $color-silver solid 1px;
  width: calc(100% - 3 * ($spacing-small));
  justify-content: space-between;
  padding: $spacing-xs $spacing-small;
  border-radius: $border-radius-base;
  margin-bottom: $spacing-small;

  &__content {
    display: flex;
    flex-direction: column;

    &__description {
      display: flex;
      color: $color-mine-shaft;

      &__name {
        color: $color-primary;
        margin: 0 0 0 $spacing-small;
        font-weight: $font-weight-medium;
        max-width: 350px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .Button {
    margin-left: $spacing-small;
  }

  .ContactChannelsIcons {
    margin-top: $spacing-xs;

    .Icon {
      margin: 0 $spacing-xs 0 0;
    }
  }
}
