// - Boutons

$btn-border-radius: $border-radius-base;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

// - Champs de formulaires

$input-height-small: 42px;
$input-height-base: 49px;

$input-text-color: $color-mine-shaft;
$input-text-focus-color: $color-mine-shaft;
$input-placeholder-color: $color-grey;
$input-placeholder-hover-color: $color-scorpion;

$input-border-radius: $border-radius-base;
$input-border-color: $color-silver;
$input-border-hover-color: $color-grey;
$input-border-focus-color: $color-primary;

$form-label-color: $color-scorpion;

$checkbox-size: 25px;
$radiobox-size: 21px;

// - Divers

$row-action-icon-size: 1.6rem;
$row-action-btn-size: 35px;

$link-back-btn-size: 45px;
