// - Bordures

$border-radius-xs: 3px;
$border-radius-small: 4px;
$border-radius-base: 6px;
$border-radius-large: 8px;

// - Ombres

$dropdown-box-shadow: 0px 5px 25px rgba(0, 0, 0, .1);
$modal-box-shadow: 0px 4px 100px rgba(0, 0, 0, .1);
