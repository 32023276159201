@import 'src/style/variables';

.CustomerAnalysisStats {
  &__group {
    display: flex;
    margin-bottom: $spacing-medium;

    .StatBlock {
      flex: 1;
    }

    .StatBlock + .StatBlock {
      margin-left: $spacing-base;
    }
  }

  &__recovered.StatBlock {
    flex: 2;
    min-height: 160px;
  }

  &__value {
    font-size: $font-size-important;
    margin: 0;
  }
}
