@import 'src/style/variables';

.ScenarioDetailsInfos {
  display: flex;
  flex-direction: column;
  padding: $spacing-medium-plus $spacing-page-vertical $spacing-xxl;
  background-color: $color-alabaster;
  border-radius: 0 0 $border-radius-small $border-radius-small;

  &__group {
    display: flex;
    font-size: $font-size-medium;

    &__title {
      flex: 1;
      display: flex;
      align-items: center;
      margin: 0;
      color: $color-scorpion;
      font-weight: $font-weight-bolder;
    }

    &__container {
      flex: 2;
      display: flex;
      flex-direction: column;
      margin-left: $spacing-base;
      color: $color-scorpion;
      font-weight: $font-weight-medium;

      &__plan-change {
        display: flex;
        justify-content: space-between;

        &__no-data {
          margin: 0;
          color: $color-scorpion;
        }

        &__badge {
          margin: 0;
          background-color: $color-alabaster;
          border-radius: $border-radius-base;
          justify-self: flex-end;
          flex-shrink: 0;
          display: flex;
          align-content: center;
          padding: $spacing-xs $spacing-small;
        }

        &__new-plan {
          margin-bottom: 0;
          display: flex;
          align-items: center;
        }
      }
    }

    & + & {
      margin-top: $spacing-medium-plus;
    }
  }
}
