@import 'src/style/variables';

.PaymentRequestInfos {
  &__content {
    margin: $spacing-large 0;
    width: 90%;
    display: flex;
    flex-wrap: wrap;

    .PaymentRequestInfosCard {
      flex: 1 0 30%;
    }

    .Badge--group {
      margin-right: $spacing-small;
    }
  }

  &__subject {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__muted-group {
    color: $color-grey;
    font-size: $font-size-subtitle;
  }
}
