@import 'src/style/variables';

.AccountDetailsFilters {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    margin-right: $spacing-small;
    padding: $spacing-xs $spacing-small;
    border: 1px solid $color-silver;
    border-radius: $border-radius-base;

    .FormCheck {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      color: $color-mine-shaft;

      &__label {
        display: inline-flex;
        align-items: center;
        height: 20px;
        padding: 0 0 2px 30px;

        &::before,
        &::after {
          top: 0;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
