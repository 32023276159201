.CustomerMain {
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .CustomerMainLayout {
      flex: 1;
    }
  }
}
