@import 'src/style/variables';

.MiddleNavButtons {
  margin-top: $header-active-item-indicator-width;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    color: $color-scorpion;
  }

  &__button {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__underline {
      display: flex;
      height: $header-active-item-indicator-width;
      margin: 0 $spacing-small;

      &--active {
        background: linear-gradient(180deg, $color-secondary 0%, $color-primary 100%);
      }
    }

    .Button--link {
      flex: 1;
      color: $color-white;
      padding: $spacing-small $spacing-xl;
    }

    .Button--disabled {
      background-color: transparent;
      color: $color-scorpion;
    }
  }
}
