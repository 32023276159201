@import 'src/style/variables';

.CustomerInformations {
  border-top: 1px solid $color-silver;
  padding-top: $spacing-large;

  &__item {
    display: flex;
    margin-bottom: $spacing-base;
  }

  &__label {
    flex: 0 0 96px;
    line-height: 1.8;
    font-size: $font-size-medium;
    font-weight: $font-weight-bolder;
    color: $color-mine-shaft;
    margin-right: $spacing-xs;

    &--capitalize {
      text-transform: capitalize;
    }
  }

  &__value {
    flex: 1;
    margin: 0;
    line-height: 1.8;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    color: $color-scorpion;
  }
}
