@import 'src/style/variables';

.ScenarioDetailsActionsItem {
  display: flex;
  align-items: center;
  justify-items: flex-start;
  border: 1px solid $color-silver;
  padding: $spacing-small $spacing-base;
  border-radius: $border-radius-base;
  margin-bottom: $spacing-base;
  background-color: transparent;

  &__icon {
    margin-right: $spacing-base;
  }

  &__details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;

    &__title {
      display: flex;
      margin-bottom: 0;
      font-size: $font-size-light;

      &__solo-badge {
        display: flex;
        font-weight: $font-weight-bold;
        height: fit-content;
        margin-left: $spacing-small;
        white-space: nowrap;
        color: $color-success;
        border-radius: $border-radius-small;
        padding: 0 $spacing-xs;
        font-size: $font-size-small;
        background-color: rgba($color-success, .1);
      }
    }

    &__days {
      font-size: $font-size-small;
      margin-bottom: 0;
      color: $color-primary;

      &__way {
        color: $color-scorpion;
      }
    }
  }

  &__to-validate {
    border-radius: $border-radius-base;
    padding: $spacing-xs $spacing-base;
    background-color: $color-alabaster;
    font-size: $font-size-medium;
    justify-self: flex-end;
    flex-shrink: 0;
  }
}
