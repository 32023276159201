@import 'src/style/variables';

.ListContacts {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    margin-left: $spacing-base;
    margin-bottom: $spacing-medium-plus;
  }

  &__title {
    align-self: self-start;
    font-size: $font-size-subtitle;
    font-weight: $font-weight-bolder;
  }

  &__contacts-count {
    flex: 1;
    color: $color-primary;
    font-size: $font-size-base;
    margin-left: $spacing-small;
  }

  &__link-manage {
    flex: 0 0 auto;
    margin-left: $spacing-medium-plus;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
  }

  &__list {
    flex: 1;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    .Button {
      color: $color-silver;
    }
  }

  .Loading {
    flex: 0 0 auto;
    padding: $spacing-base;
    margin-bottom: 1.5rem;
    color: $color-scorpion;
    font-size: $font-size-subtitle;
    text-align: center;
  }

  &__add-contact {
    margin-bottom: $spacing-large;
    margin-left: $spacing-medium;
    font-size: $font-size-medium;
    color: $color-grey;

    &__text {
      margin-bottom: $spacing-large;
    }

    &__button {
      display: block;
      padding: $spacing-small $spacing-medium-plus;
      background-color: $background-color-base;
      border-radius: $border-radius-small;
      color: $color-scorpion;
      font-size: $font-size-light;
      line-height: 1.8;

      .Icon {
        vertical-align: middle;
        margin-right: $spacing-base;
        margin-bottom: 2px;
        color: $color-silver;
      }
    }
  }
}
