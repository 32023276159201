@import 'src/style/variables';

.SettingsNavItemLink {
  $block: &;

  margin-bottom: $spacing-large;

  &__link {
    font-weight: $font-weight-medium;
    font-size: $font-size-root;
    color: $color-grey;

    &:hover {
      color: $color-scorpion;
      text-decoration: none;
    }
  }

  &--active {
    #{$block}__link {
      color: $color-scorpion;
    }
  }

  &--disabled {
    #{$block}__link {
      color: $color-silver;
    }
  }

  &__tooltip {
    padding: $spacing-base;
    display: flex;
    max-width: 350px;
  }
}
