@import 'src/style/variables';

.Sending {
    &__list {
        display: flex;

        &__items {
            flex: 1;
        }
    }
}
