@import 'src/style/variables';

.StatBlock {
  position: relative;
  display: flex;
  flex-direction: column;

  &__content {
    position: relative;
    flex: 1;
    padding: $spacing-large $spacing-large $spacing-medium-plus;
    border: 1px solid $color-mercury;
    border-bottom-width: 3px;
    border-radius: $border-radius-large;

    &__chart {
      position: absolute;
      top: $spacing-small;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: $spacing-base;

      &__title {
        flex: 1;
        font-size: $font-size-light;
        font-weight: $font-weight-bold;
        color: $color-grey;
      }

      &__filter {
        flex: 0 0 80px;
      }
    }

    > *:not(.StatBlock__content__chart) {
      position: relative;
    }
  }

  &__labels {
    position: absolute;
    bottom: 10px;
    left: -4%;
    right: -4%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__item {
      position: relative;
      flex: 1;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-size: $font-size-micro;
      color: $color-grey;

      &::after {
        content: '|';
        position: absolute;
        bottom: -5px;
        left: 50%;
        font-size: 5px;
        font-weight: 400;
      }

      &:first-child {
        margin-left: 1.5%;
        margin-right: -1.5%;

        &::after {
          left: 35%;
        }
      }

      &:last-child {
        margin-left: -1.5%;
        margin-right: 1.5%;

        &::after {
          left: 65%;
        }
      }
    }
  }

  &__colors {
    flex: 0 0 auto;
    display: flex;
    border-top: 1px solid $color-mercury;

    &__item {
      flex: 1;
      height: $spacing-xs;

      &:first-child {
        border-bottom-left-radius: $border-radius-large;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius-large;
      }
    }
  }

  &--with-colors {
    .StatBlock__content {
      border-radius: $border-radius-large $border-radius-large 0 0;
      border-bottom-width: 1px;
    }
  }
}
