@import 'src/style/variables';
@import 'src/style/mixins';

.ModalForm {
  &__form {
    .Alert {
      margin-bottom: $spacing-large;
    }
  }

  &__footer {
    @extend %modal-footer;

    .Alert {
      width: 100%;
      background-color: $color-warning;
    }

    .Loading {
      margin-right: $spacing-small;
    }

    .Button--disabled {
      background-color: $color-scorpion;
      color: $color-grey;
    }
  }
}
