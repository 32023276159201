@import 'src/style/variables';

.HistoryStepInfosHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 $spacing-base;
  min-width: 0;

  &__titles {
    justify-content: flex-start;
    margin-right: $spacing-small;
    overflow-wrap: break-word;

    &__text {
      color: $color-mine-shaft;
      font-size: $font-size-light;
      font-weight: $font-weight-bolder;
      margin-right: $spacing-xs;
    }

    &__scenario {
      display: inline;
      color: $color-scorpion;
      font-size: $font-size-light;
      font-weight: $font-weight-bolder;
      white-space: pre-line;
      text-align: left;
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacing-xs;

    .Badge {
      align-self: baseline;
    }

    &__link {
      overflow-wrap: anywhere;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;

      &:hover,
      &:focus {
        color: $color-scorpion;
      }
    }

    &__dunning-group,
    &__pay-request,
    &__total-amount,
    &__total-amount-due {
      color: $color-scorpion;
      font-size: $font-size-medium;
      font-weight: 500;
    }

    &__total-amount,
    &__total-amount-due {
      margin-left: $spacing-xs;
    }

    &__total-amount-due {
      display: none;
    }

    .Badge + .Badge,
    .Badge + &__link,
    .Badge + &__dunning-group,
    .Badge + &__pay-request,
    &__dunning-group + &__link,
    &__pay-request + &__link {
      margin-left: $spacing-xs;
    }

    &__note-content {
      display: inline;
      vertical-align: top;
      margin: 0 $spacing-small;
      color: $color-scorpion;
      line-height: 1.4rem;
      white-space: pre-wrap;
      font-size: $font-size-medium;
    }

    &--note {
      display: block;
      max-width: 600px;

      .Badge {
        float: left;
      }

      &::after {
        clear: both;
      }
    }
  }
}
