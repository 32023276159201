@import 'src/style/variables';

.Perimeter {
  display: flex;
  border: 2px solid $color-silver;
  padding: $spacing-base;
  align-self: center;

  &__categories {
    flex: 1;
  }

  &__category {
    display: flex;

    &__operator {
      color: $color-primary;
      margin-right: $spacing-xs;
      min-width: $spacing-medium-plus;
      text-transform: uppercase;
    }

    &__title {
      color: $color-black;
    }

    &__values {
      margin-left: 2rem;
      color: $color-scorpion;
    }
  }
}
