@import 'src/style/variables';

.ContactChannelsIcons {
  display: flex;
  align-items: center;

  .Icon {
    margin-left: $spacing-small;
    width: 1.1rem;
    height: 1.1rem;
    color: $color-grey;
  }
}
