@import 'src/style/variables';

.CollaboratorsInvitedTable {
  &__created-at {
    font-weight: $font-weight-bolder;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  .DataTable {
    border-top: none;
  }
}
