@import 'src/style/variables';

.PaymentRequest {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__content {
    padding-top: $spacing-page-vertical;
    flex: 1;
    display: flex;
    align-items: stretch;

    &__main-view {
      flex: 7;
      min-width: 680px;
    }

    .PaymentRequestSide {
      flex: 3;
      background-color: $color-alabaster;
      padding: $spacing-large $spacing-page-horizontal $spacing-page-vertical $spacing-large;
      border-radius: 8px 0px 0px 0px;
      font-size: $font-size-light;
    }
  }

  .ListContacts,
  .ListNotes {
    margin-bottom: $spacing-large;
  }
}
