@import 'src/style/variables';

.PaymentViewModalContent {
  &__details {
    display: flex;
    flex-wrap: wrap;

    .PreviewListElement__label {
      flex: 0 0 100px;
    }
  }

  &__assignations {
    display: flex;
    align-items: flex-start;
    background-color: $color-alabaster;
    border-radius: 0 0 $border-radius-small $border-radius-small;
    margin: 0 -#{$spacing-page-horizontal} -#{$spacing-page-vertical};
    padding: $spacing-medium-plus $spacing-page-horizontal $spacing-page-vertical;

    &__title {
      flex: 0 0 100px;
      font-size: $font-size-medium;
      font-weight: $font-weight-bolder;
      margin-right: $spacing-small;
    }

    &__no-data,
    &__list {
      flex: 1;
      font-size: $font-size-medium;
    }

    &__no-data {
      font-style: italic;
      color: $color-grey;

      .ButtonWithUserRights {
        width: fit-content;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      color: $color-scorpion;

      &__item {
        list-style: none;

        & + & {
          margin-top: $spacing-xs;
        }
      }
    }
  }
}
