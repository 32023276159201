@import 'src/style/variables';

.ImportsReportDetailFile {
  display: flex;
  border-bottom: rgba($color: $color-mercury, $alpha: 0.5) 1px solid;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__action {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__web {
        padding: $spacing-small;
        margin: $spacing-xs;
        border-radius: $border-radius-base;
        background-color: $color-alabaster;
        color: $color-primary;
        text-transform: uppercase;

        &__text {
          margin: 0;
          font-size: $font-size-mini;
          font-weight: $font-weight-bold;
        }
      }

      &__complete {
        margin-right: $spacing-base;

        &:hover {
          color: $color-white;
        }
      }

      .Dropdown__toggle {
        padding: $spacing-base $spacing-small;
        margin-right: $spacing-xs;
        border-color: $color-mercury;
        color: $color-grey;

        .Dropdown__toggle__content {
          align-items: center;
          margin: 0px;
          display: flex;
        }
      }

      &__download {
        &:hover {
          text-decoration: none;
        }
      }

      &__expand {
        padding: $spacing-small $spacing-small;
        border-color: $color-mercury;
        background-color: $color-white;
        color: $color-grey;
        margin-right: $spacing-xs
      }
    }
  }
}
