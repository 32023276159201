@import '~react-date-picker/dist/DatePicker.css';

.react-date-picker {
  width: 100%;

  &__wrapper {
    width: 100%;
    height: $input-height-base;
    padding: 0 .75rem;
    border: 1px solid $input-border-color;
    border-radius: $border-radius-base;

    &:focus {
      border-color: $color-scorpion;
      outline: 0;
    }
  }

  &__inputGroup {
    font-size: $font-size-light;

    &:hover {
      border-color: $color-scorpion;
    }

    &__input {
      color: $color-scorpion;

      &:focus {
        color: $color-mine-shaft;
        outline: 0;
      }

      &:invalid {
        background-color: rgba($color-danger, .05);
      }
    }

    &__divider {
      color: $color-grey;
    }
  }

  &__button:focus {
    outline: 0;
  }

  &__calendar {
    z-index: 3 !important;
  }
}
