@import 'src/style/variables';

.PlansModalAddPlan {
  .FormFieldset {
    align-items: center;
  }

  .FormCheck {
    margin-bottom: $spacing-large;

    &__label {
      font-size: $font-size-light;
    }
  }
}
