@import 'src/style/variables';

.PayRequestItem {
  margin: -$spacing-base 0;
  display: flex;

  &__wrapper {
    display: flex;
    flex-direction: column;

    &__form {
      display: flex;
      align-items: center;
    }
  }

  &__remove {
    position: relative;
    left: -$spacing-base;
    margin-left: -$spacing-base - 1px;
    color: $color-scorpion;

    &:hover {
      color: $color-mine-shaft;
    }
  }

  .FormFieldset {
    flex: 1;
  }
}
