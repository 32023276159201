@import 'src/style/variables';

.Selectors {
  display: flex;
  flex-direction: column;

  &__subtitle {
    font-weight: $font-weight-medium;
  }

  &__rowActions {
    display: flex;
    justify-content: flex-end;
  }

  .SettingsSection {
    .Button--disabled {
      background-color: $color-white;
      color: $color-scorpion;
    }
  }

  &__section {
    order: 1;

    &--is-second {
      order: 2;
    }
  }
}
