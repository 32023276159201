@import 'src/style/variables';

.HistoryStepActions {
  display: flex;

  &__action {
    height: $row-action-btn-size;
    padding: $spacing-base;
    margin-right: $spacing-xs;

    &--hover {
      color: $color-black;
    }
  }

  .Button--outline {
    color: $color-scorpion;
    border-color: $color-silver;

    &:hover {
      color: $color-mine-shaft;
      border-color: $color-grey;
    }
  }
}
