@import 'src/style/variables';

.ListNotesItem {
  list-style: none;
  margin-bottom: $spacing-medium;

  &__message {
    font-size: $font-size-light;
    margin-bottom: $spacing-xs;

    &__link {
      max-width: 100%;
      text-align: left;
      font-weight: $font-weight-bold;
      white-space: pre-line;
      color: $color-mine-shaft;

      &:hover,
      &:focus,
      &:active {
        color: $color-mine-shaft;
      }
    }
  }

  &__link {
    font-size: $font-size-small;
  }

  &__details {
    margin: 0;
    font-size: $font-size-small;
    color: $color-scorpion;
  }
}
