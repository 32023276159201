@import 'src/style/variables';

.SepaMandateLink {
  .Icon {
    margin-right: $spacing-small;
    color: $color-success;
  }

  &__read-link {
    margin-left: $spacing-large;
  }
}
