@import 'src/style/variables';

.CustomerUnMuteForm {

  &__text {
    font-size: $font-size-base;
    margin-bottom: $spacing-base;
    white-space: initial;
  }

  &__entity-name {
    font-size: $font-size-subtitle;
    font-weight: $font-weight-bolder;
    line-height: 1.4;
    margin-bottom: $spacing-medium;
    color: $color-mine-shaft;
  }

  .FormRadios {
    display: flex;
    flex-direction: column;
    align-items: normal;
    height: 49px;
  }
}
