@use "sass:math";
@import 'src/style/variables';

.CustomerAccountDetails {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-page-vertical;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-large;
    padding-left: $spacing-base;

    &__title {
      flex: 0 0 auto;
      font-size: $font-size-subtitle;
      font-weight: bold;
      margin: 0;
    }

    &__links {
      flex: 1;
      text-align: right;
    }

    &__link-manage {
      flex: 0 0 auto;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;

      &__icon {
        margin-left: math.div($spacing-base, 2);
      }
    }
  }

  &__filters {
    flex: 0 0 auto;
    align-self: flex-start;
  }

  &__list {
    flex: 1;

    &__row {
      &--failed-payment,
      &--litigated {
        background-color: rgba($color: $color-danger, $alpha: 0.05);
      }
    }

    .DataTableBody__cell {
      padding: $spacing-base $spacing-small $spacing-base 0;
    }

    .DataTableHead__cell:last-child,
    .DataTableBody__cell:last-child {
      padding-right: $spacing-base;
    }

    .DataTable__no-data {
      margin-top: $spacing-xl;
      font-size: $font-size-medium;
      font-style: normal;
    }

    .DataTableFooter {
      position: relative;
    }

    .Pagination {
      padding-left: 0;
      padding-right: 0;
      background: none;
      border: none;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
      color: $color-scorpion;
    }
  }

  .Loading,
  &__loading-help {
    margin-bottom: $spacing-large;
    color: $color-scorpion;
    font-size: $font-size-subtitle;
    text-align: center;
  }

  &__loading-help {
    color: $color-grey;
    font-style: italic;
  }

  &__columns {
    &__date {
      margin-right: $spacing-small;
      font-weight: $font-weight-medium;
      white-space: nowrap;
    }
  }
}
