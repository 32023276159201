@import 'src/style/variables';

.OrganizationSelector {
  padding: 0 $spacing-medium;

  .Dropdown {
    &__toggle {
      height: $input-height-small;
      padding: 0;
      border: none;
      color: $color-header-link;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;

      &:hover {
        color: $color-header-link-hover;
      }

      &__content {
        display: flex;

        .Loading {
          margin-right: $spacing-small;
          color: $color-white;
        }
      }
    }

    &__menu {
      right: auto;
      margin-top: $spacing-small;
    }
  }

  &.Dropdown--open {
    .Dropdown__toggle {
      .Icon:not(.Dropdown__toggle__icon) {
        color: $color-white;
      }
    }
  }

  &__selected {
    margin-right: $spacing-xs;

    &__name {
      flex: 1;
      justify-content: stretch;
      height: $font-size-base * 1.5;
      text-transform: capitalize;
      color: $color-header-link-hover;
    }

    &__not-prod {
      flex: 1;
      margin-top: .15rem;
      margin-left: $spacing-small;
      color: $color-secondary;
      font-size: $font-size-micro;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }

  &__menu {
    max-height: 75vh;
  }

  &--nothing-selected {
    .Dropdown__toggle__content,
    .Dropdown__toggle__icon {
      color: $color-warning;
    }
  }
}
