@import 'src/style/variables';

.Attributes {
  .SettingsSection {
    .Button--disabled {
      background-color: $color-white;
      color: $color-scorpion;
    }
  }
}
