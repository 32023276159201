@import 'src/style/variables';

.CustomerAccountDetailsDocumentCell {
  &__reference {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-xs;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;

    .Icon {
      width: 20px;
      height: 20px;
      margin-right: $spacing-xs;
      color: $color-primary;
    }
  }

  &__link,
  &__link:hover {
    max-width: 100%;
    color: $color-mine-shaft;
    font-weight: $font-weight-bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tooltip {
    padding: $spacing-xs $spacing-small;
    font-size: $font-size-medium;
    overflow: auto;
    overflow-wrap: break-word;
  }

  &__pay-request,
  &__payment {
    margin-top: $spacing-mini;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    color: $color-scorpion;

    .Badge--recent,
    .Badge--group {
      margin-right: $spacing-small;
    }
  }

}
