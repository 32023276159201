@import 'src/style/variables';

.OrganizationCreateForm {
  &__submit {
    width: 100%;
    margin-top: $spacing-small;
    padding-top: $spacing-base;
    padding-bottom: $spacing-base;
  }
}
