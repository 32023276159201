@import 'src/style/variables';

.PaymentRequestInfosCard {
  margin: $spacing-base 0;

  &__title {
    font-size: $font-size-medium;
    color: $color-mine-shaft;
  }

  &__value {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: $spacing-base;
    font-size: $font-size-subtitle;
  }
}
