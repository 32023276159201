@import 'src/style/variables';

.NoResultsFoundDrawing {
  margin-top: $spacing-page-vertical;
  justify-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  &__drawing {
    height: $no-result-drawing-height;
    margin-bottom: $spacing-xl;
  }

  &__subtitle {
    color: $color-scorpion;
  }
}
