@import 'src/style/variables';

.ActionsDone {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    display: flex;
    align-items: stretch;
  }

  .ActionsDoneSide {
    width: $width-side-filter;
  }

  .ActionsDoneContent {
    flex: 7;
    padding: $spacing-page-vertical;
    padding-right: $padding-datatable-right;
  }

  .DataTable__table-wrapper tbody td:last-child,
  .DataTable__table-wrapper thead th:last-child  {
    text-align: left;
  }

  .DataTableHead__cell {
    display: none;
  }

  .DataTableBody__cell {
    padding: 0;

    &:first-child {
      padding-left: 0;
    }

    .HistoryStep {
      border: none;
      padding: $spacing-xl $spacing-xs $spacing-xl $spacing-base;
      border-radius: 0;
    }

    &--no-check {
      .HistoryStep {
        padding: $spacing-xl $spacing-xs $spacing-xl $spacing-xs;
      }
    }
  }

  .PageList__header {
    margin-bottom: 0;
  }

  .Export {
    padding-left: 0;
  }
}
