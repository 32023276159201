@import 'src/style/variables';

.CustomersRowActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .NoteIcon {
    height: 20px;
    padding-right: $spacing-base;
  }

  .CustomerMuteButtonMute,
  .CustomerMuteButtonUnMute {
    height: 20px;
    width: $row-action-btn-size;
    margin: 0 $spacing-mini 0 $spacing-xs;
    padding: 0;

    &:focus,
    &:hover {
      background: none;
    }
  }

  .Dropdown__menu {
    font-size: $font-size-light;
  }
}
