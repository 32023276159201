@import 'src/style/variables';

.Report {
  flex-direction: column;
  padding: $spacing-page-vertical $spacing-page-horizontal;
  display: flex;
  align-items: stretch;

  &__banner {
    margin-top: $spacing-large;
    padding: $spacing-large $spacing-xl;
    border-radius: $border-radius-base;
    border: $color-mine-shaft 1px solid;

    &--warning {
      background-color: rgba($color: $color-warning, $alpha: 0.1);
      border: rgba($color: $color-warning, $alpha: 0.1) 1px solid;
    }

    &--danger {
      background-color: rgba($color: $color-danger, $alpha: 0.1);
      border: rgba($color: $color-danger, $alpha: 0.1) 1px solid;
      color: $color-danger;
    }
  }
}
