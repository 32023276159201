@import 'src/style/variables';

.SEPAMandateAcceptance {
  display: flex;
  margin: 0 0 $spacing-large;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 13rem;
    font-weight: bold;
    font-size: $font-size-medium;
    margin-right: $spacing-large;
  }

  &__stripe {
    flex: 1;
    margin: 0;
    font-size: $font-size-medium;
    color: $color-scorpion;

    .Loading {
      &__content {
        justify-content: flex-start;
      }
    }
  }
}
