@import 'src/style/variables';

.FormBadgeSelect {
  font-size: $font-size-base;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $color-mine-shaft;
  border-radius: $border-radius-base;

  &__input-add {
    width: 100%;
    outline: none;
    border: none;
  }

  &__input-error {
    color: $color-danger;
    padding-left: $spacing-base;
  }

  &__values {
    width: 100%;
    padding: $spacing-base $spacing-medium;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-xs;
  }

  &__value {
    &__delete {
      padding: $spacing-xs;
      width: $spacing-medium;
      margin-left: $spacing-base;
      font-size: $font-size-subtitle;
    }

    &__delete:hover {
      font-weight: $font-weight-bolder;
      cursor: pointer;
    }
  }
}
