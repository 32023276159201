@import 'src/style/variables';

.ReminderEditForm {
  &__need-validation {
    flex: 1;
    flex-direction: row;
    align-items: center;

    .FormGroup__header__label {
      margin-bottom: 0;
    }
  }

  &__daysShift {
    margin-top: -$spacing-medium-plus;
    margin-bottom: $spacing-medium-plus;
    color: $color-warning;
    font-size: $font-size-light;

    .Icon {
      margin-right: $spacing-small;
      vertical-align: middle;
    }
  }

  &__divider {
    border-bottom: 1px solid $color-mercury;
    width: 100%;
    height: $spacing-small;
    margin-bottom: $spacing-xl;
  }

  &__content {
    .FormGroupWithActions__titles {
      margin-bottom: $spacing-small;

      .Button {
        margin-left: $spacing-xl;
      }
    }
  }
}
