@import 'src/style/variables';

.ConfirmEmail {
  .Button {
    width: 100%;
  }

  &__title {
    font-size: $font-size-title;
    margin-bottom: $spacing-medium-plus;
    line-height: $font-size-title;
  }

  &__form {
    width: $width-login-form;
    margin-right: 5%;
  }

  &__description {
    padding: $spacing-medium 0 $spacing-base 0;

    &__text {
      color: $color-scorpion;
      font-weight: $font-weight-medium;
      font-size: $font-size-light;
    }
  }
}
