@import 'src/style/variables';

.ModalSelectorConditions {
  &__summary {
    display: flex;
    border: 0.5px solid $color-grey;
    padding: $spacing-base;
    margin: $spacing-base 0;
    border-radius: $border-radius-base;
    align-items: center;
    justify-content: space-between;

    &--disabled {
      background-color: $color-alabaster;
      color: $color-scorpion;
      border-color: $color-silver;
      cursor: not-allowed;
    }
  }

  &__commands {
    display: flex;
    justify-content: flex-end;

    &__validate {
      display: flex;
    }

    &__remove {
      display: flex;
      margin-right: $spacing-large;
    }
  }

  &__payment-status-attribute {
    max-width: calc(50% - #{$spacing-base});
  }
}
