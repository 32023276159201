@import 'src/style/variables';

.DraftInvoiceForm {
  &__check {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0;
    align-items: center;
    height: fit-content;
    margin-top: $spacing-small;

    .FormGroup__header__label {
      text-transform: initial;
      font-size: $font-size-small;
      margin-right: $spacing-large;
    }
  }

  .ErrorValidationMessage {
    color: $color-warning;
  }

  .FormSelectCustomer--is-invalid .select-search__input,
  .FormControl--not-valid,
  .FormSelect--is-invalid .select-search__input,
  .FormDatePicker--is-invalid .react-date-picker__wrapper {
    border-color: $color-warning;
  }

  &__form {
    display: flex;
    justify-content: space-between;

    &__fields {
      width: 100%;

      &__create-client {
        font-size: $font-size-small;
      }
    }
  }

  &__dunning-plan-info {
    background-color: $color-alabaster;
    color: $color-scorpion;
    padding: $spacing-base;
    border-radius: $border-radius-base;
    border: 1px solid $color-silver;
    cursor: not-allowed;
    margin-bottom: 0;
  }

  &__helper {
    font-weight: $font-weight-regular;
    font-size: $font-size-small;
    color: $color-grey;
    margin: $spacing-small $spacing-medium;
  }

  .PDFViewer {
    align-content: center;
    min-width: 50%;
    margin-top: $spacing-large;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
