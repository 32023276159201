@import 'src/style/variables';

.MenuOptions {
  $spacing-options-top: 75px;
  $spacing-options-left: 161px;
  $spacing-title-left: 111px;
  $spacing-title-top: 100px;

  display: flex;
  flex-direction: column;
  min-height: 400px;

  &__menu {
    padding: $spacing-title-top 0 0 $spacing-title-left;

    &__title {
      font-size: $font-size-title;
      margin-bottom: $spacing-base;
      line-height: 36px;
    }

    &__subtitle {
      color: $color-scorpion;
      font-weight: $font-weight-bold;
      margin-bottom: 0;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-top: $spacing-options-top;
    margin-left: $spacing-options-left;
  }
}
