@import 'src/style/variables';

.HomeHeader {
  display: flex;
  align-items: center;
  padding: $spacing-xl $spacing-page-horizontal;

  &__title {
    flex: 1;
    margin: 0;
    font-size: $font-size-title;
    font-weight: $font-weight-bolder;
    color: $color-mine-shaft;
  }

  .BillsInProgress {
    flex: 0 0 auto;
    margin-right: $spacing-page-vertical;
  }
}
