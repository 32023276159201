@import 'src/style/variables';

.CollaboratorsTable {
  &__identifier {
    margin: $spacing-xs $spacing-xs 0px $spacing-xs;
    display: flex;
    padding: $spacing-xs;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background-color: $color-alabaster;
    border-radius: $border-radius-large;
    font-size: $font-size-small;

    &__title {
      font-size: $font-size-mini;
      font-weight: $font-weight-bolder;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__name {
    font-weight: $font-weight-bolder;
  }

  &__roles {
    color: $color-grey;
  }

  &__roles,
  &__perimeters {
    font-weight: $font-weight-medium;
  }

  .DataTable {
    border-top: none;
  }

  &__no-collaborator {
    margin-top: $spacing-small ;
  }
}
