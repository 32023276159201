@import 'src/style/variables';
@import 'src/style/mixins';

.PaymentRequestHeader {
  padding: 0 $spacing-page-horizontal $spacing-base;

  &__main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__links {
    flex: 0 0 auto;
    align-self: flex-start;

    .Button {
      width: $link-back-btn-size;
      height: $link-back-btn-size;
      padding: 0;
    }
  }

  &__infos {
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 0;
    margin-left: $spacing-medium-plus;

    .Loading__content {
      justify-content: flex-start;
    }

    &__name {
      margin-bottom: $spacing-xs;
      font-size: $font-size-title;
      line-height: 1;
      overflow-wrap: anywhere;
    }

    .Badge {
      margin-left: $spacing-large;
    }

    &__extra {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .Icon {
        color: $color-primary;
        margin-left: $spacing-base;
      }
    }
  }

  &__customer {
    margin-left: 70px;

    &__link {
      display: flex;
      align-items: baseline;
    }

    &__name {
      margin-right: $spacing-small;
      font-size: $font-size-subtitle;
      color: $color-primary;
    }

    &__reference {
      font-size: $font-size-base;
      color: $color-grey;
    }
  }
}
