@import 'style/variables';

.HistoryStepReminderAskMoveScenario {
  &__help {
    margin-bottom: $spacing-large;
  }

  .FormRadios {
    flex-direction: column;
    align-items: flex-start;
  }
}
